import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { STATUS } from '../../types/globals.types';
import { commonReducer } from '../../constants';
import { ApplicationStateModel } from './application.types';
import { getApplication, getLoan, onChangeBorrower } from '../../services';
import { ApplicationModel } from '../../../pages/admin/application/Application.types';
import { LoanModel } from '../../../pages/admin/application/Loan.types';
import { mapApiResponseToState } from './utils/mappingUtils';
import { BorrowerViewModel } from './utils/borrowerView.types';

const name = 'application';
const typePrefix = 'application';

const initialState: ApplicationStateModel = {
  ...commonReducer,
  application: undefined,
  loan: undefined,
};

 
export const application = createAsyncThunk<
  any,
  { id: number | null | undefined }
>(`${typePrefix}/application`, async ({ id }, thunkAPI) => {
  if (id) {
    try {
      return await getApplication(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
});

 
export const loan = createAsyncThunk<any, { id: number | null | undefined }>(
  `${typePrefix}/loan`,
  async ({ id }, thunkAPI) => {
    if (id) {
      try {
        return await getLoan(id);
      } catch (error) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

 
export const changeCurrentBorrower = createAsyncThunk<any, { id: string }>(
  `${typePrefix}/changeCurrentBorrower`,
  async ({ id }, thunkAPI) => {
    if (id) {
      try {
        return await onChangeBorrower(id);
      } catch (error) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const applicationSlice = createSlice({
  name,
  initialState,
  reducers: {
    applicationReset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(application.pending, (state: ApplicationStateModel) => {
        state.status = STATUS.LOADING;
        state.application = undefined;
      })
      .addCase(
        application.fulfilled,
        (
          state: ApplicationStateModel,
          action: PayloadAction<ApplicationModel>
        ) => {
          state.status = STATUS.SUCCESS;
          state.isSuccess = true;
          state.application = action.payload;
        }
      )
      .addCase(application.rejected, (state: ApplicationStateModel) => {
        state.status = STATUS.ERROR;
        state.isError = true;
        state.application = undefined;
      })
      .addCase(loan.pending, (state: ApplicationStateModel) => {
        state.status = STATUS.LOADING;
        state.loan = undefined;
      })
      .addCase(
        loan.fulfilled,
        (state: ApplicationStateModel, action: PayloadAction<LoanModel>) => {
          state.status = STATUS.SUCCESS;
          state.isSuccess = true;
          state.loan = action.payload;
        }
      )
      .addCase(loan.rejected, (state: ApplicationStateModel) => {
        state.status = STATUS.ERROR;
        state.isError = true;
        state.loan = undefined;
      })
      .addCase(
        changeCurrentBorrower.pending,
        (state: ApplicationStateModel) => {
          state.status = STATUS.LOADING;
        }
      )
      .addCase(
        changeCurrentBorrower.fulfilled,
        (
          state: ApplicationStateModel,
          action: PayloadAction<BorrowerViewModel>
        ) => {
          state.status = STATUS.SUCCESS;
          state.isSuccess = true;
          const mapped = mapApiResponseToState(state, action.payload);
          state.application = mapped.application;
          state.loan = mapped.loan;
        }
      )
      .addCase(
        changeCurrentBorrower.rejected,
        (state: ApplicationStateModel) => {
          state.status = STATUS.ERROR;
          state.isError = true;
        }
      );
  },
});

export const { applicationReset } = applicationSlice.actions;
export default applicationSlice;
