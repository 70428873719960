import { Col, Row } from 'antd';
import Editor from '../../../../editor';

interface Props {
  value?: boolean;

  [x: string]: any;
}

const Bool = ({ value, ...rest }: Props) => {
  const onChange = ({ value }: { value: boolean }) => {
    rest.onChange(value);
  };

  return (
    <Row justify="center" gutter={8}>
      <Col>
        <Editor type="bool" value={value} onChange={onChange} />
      </Col>
    </Row>
  );
};

export default Bool;
