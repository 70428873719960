class Controller {
  calls: any;
  client: any;

  constructor(client: any) {
    this.calls = {};
    this.client = client;
  }

  cancelPreviousCall = (url: any) => {
    if (!Object.prototype.hasOwnProperty.call(this.calls, url)) return;

    const source = this.calls[url];

    if (typeof source !== typeof undefined)
      source.cancel('Operation canceled due to new request.');
  };

  removeSource = (url: any) => {
    delete this.calls[url];
  };

  createSource = (url: any) => {
    this.calls[url] = this.client.CancelToken.source();
  };

  getSource = (url: any) => {
    return this.calls[url];
  };
}

export default Controller;
