import React, { Suspense } from 'react';
import {
  Navigate,
  Routes,
  Route,
  Outlet,
  useSearchParams,
} from 'react-router-dom';
import { useAuthentication, useSettings } from '@opyn/utils';
import { router } from './App.routes';
import PageNotFound from './components/page_not_found';
import { routeIncludes } from './pages/common/how_to/How.config';
import { useLocationChange } from './utils/hooks/use_Location_Change';
import { storage } from './helpers';
import { azimutMarketPlaceConventionCode } from './pages/borrower/steppers/journey_steps/steps/application_request/ApplicationRequest.component';

interface Props {
  children?: any;
  isAllowed?: any;
  permissionKey?: any;
  path?: any;
  isPublic?: any;
  fallbackPath?: any;
}

function checkIfIsLoanJourneyAndShouldAuthenticate({
  accessToken,
  refreshToken,
  auth,
}: any) {
  return (
    routeIncludes('borrower') &&
    routeIncludes('loan') &&
    accessToken &&
    refreshToken &&
    !auth
  );
}

const RouteGuard = ({ path, children, isPublic }: Props) => {
  const settings = useSettings();
  const [searchParams] = useSearchParams();

  useLocationChange((location: any) => {
    if (
      location.pathname.includes('signin') &&
      location.search.includes('hcoupon')
    ) {
      storage.set('hcoupon', azimutMarketPlaceConventionCode);
    }
  });

  const { authenticated, getInitialRoute, user, onAuthenticate } =
    useAuthentication();

  const fallbackPath = isPublic
    ? getInitialRoute()
    : settings.bindTenantName('/signin');

  let isAllowed;

  if (isPublic) {
    isAllowed = !authenticated;
  } else {
    isAllowed = !!authenticated;

    // Used previously to redirect to fallbackPath when permissionKey.View was not found

    // if (permissionKey) isAllowed = isAllowed && isGranted(permissionKey);

    const isAdminPath = path.includes('admin');

    if (user?.is_system_user) isAllowed = isAllowed && isAdminPath;
    else isAllowed = isAllowed && !isAdminPath;
  }

  // Login user and redirect to loanJourney if he comes from invitation email

  if (
    checkIfIsLoanJourneyAndShouldAuthenticate({
      accessToken: searchParams.get('accessToken'),
      refreshToken: searchParams.get('refreshToken'),
      auth: authenticated,
    })
  ) {
    onAuthenticate({
      accessToken: searchParams.get('accessToken'),
      refreshToken: searchParams.get('refreshToken'),
    });

    isAllowed = true;
  }

  if (!isAllowed) {
    const currentUrl = new URL(location.href);
    currentUrl.searchParams?.delete('redirectUri');
    const redirectUri = encodeURIComponent(
      currentUrl.pathname + currentUrl.search
    );
    const fallbackUrl = settings.bindTenantName(fallbackPath);
    const navigateToUrl =
      redirectUri && !currentUrl.pathname.includes('/signin')
        ? `${fallbackUrl}?redirectUri=${redirectUri}`
        : fallbackUrl;
    return <Navigate to={navigateToUrl} />;
  }
  return children ? children : <Outlet />;
};

const CoreLayout = () => {
  const settings = useSettings();
  const { isGranted } = useAuthentication();

  const renderRoutes = ({ routes, parentPath, shouldBindTenant }: any) =>
    routes.map((route: any, i: number) => {
      let {
        path,
        children = [],
        defaultAllowed,
        component: Component,
        key,
        ...rest
      } = route;

      if (parentPath) path = parentPath + path;

      const currentTenant = settings.tenant;

      if (path && shouldBindTenant && currentTenant) {
        const name = currentTenant.name;
        if (!path.includes(name)) path = settings.bindTenantName(path);
      }

      let element = (
        <Component {...rest} isGranted={isGranted} routes={<Outlet />} />
      );

      if (!defaultAllowed)
        element = <RouteGuard {...rest} path={path} children={element} />;

      element = <Suspense fallback={null}>{element}</Suspense>;

      return (
        <React.Fragment key={i}>
          <Route
            path={path}
            element={element}
            children={renderRoutes({
              parentPath: path,
              shouldBindTenant: shouldBindTenant,
              routes: children.filter((child: any) => child.nested),
            })}
          />
          {renderRoutes({
            parentPath: path,
            shouldBindTenant: shouldBindTenant,
            routes: children.filter((child: any) => !child.nested),
          })}
        </React.Fragment>
      );
    });

  if (!settings?.id) return <PageNotFound />;

  return (
    <Routes>
      {renderRoutes({ routes: router.public, shouldBindTenant: true })}
      {renderRoutes({ routes: router.private, shouldBindTenant: true })}
      {renderRoutes({ routes: router.common, shouldBindTenant: false })}
    </Routes>
  );
};

export default CoreLayout;
