import styled from 'styled-components';
import { colorType, extractColor } from '../../../helpers';

export default styled.div`
  .ant-table-pagination {
    margin: 0;
    padding: 16px;
    background: ${extractColor(colorType.light)};
  }
`;
