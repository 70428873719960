import * as yup from 'yup';
import { currencyFormat } from '../../../../../../helpers';

type TranslateFunction = (key: string) => string;

const getSchema = (
  t: TranslateFunction,
  productsData: any,
  brokerageFee?: any
) => {
  const financingMinAmount = productsData?.map(
    (product: any) => product?.pricing?.financingMinAmount
  );
  const financingMaxAmount = productsData?.map(
    (product: any) => product?.pricing?.financingMaxAmount
  );
  const minMaturityInMonths = productsData?.map(
    (product: any) => product?.maturityRanges?.minMaturityInMonths
  );
  const maxMaturityInMonths = productsData?.map(
    (product: any) => product?.maturityRanges?.maxMaturityInMonths
  );

  const [first, ...rest] = financingMinAmount || [];
  const [second, ...other] = financingMaxAmount || [];

  const min = currencyFormat(first);
  const max = currencyFormat(second);

  const and = t('and.label');
  const months = t('monthsLowercase.label');
  const amount = t('theRequestedAmount.label');
  const tenor = t('theRequestedAmortizingPeriod.label');
  const brokerageAmount = t('theBrokerageAmount.label');

  const key = {
    brokerageFee: 'brokerageFee',
    conventionCode: 'conventionCode',
    requestedTenor: 'requestedTenor',
    requestedAmount: 'requestedAmount',
    financePurposeId: 'financePurposeId',
    financePurposeDetailId: 'financePurposeDetailId',
    financePurposeDescription: 'financePurposeDescription',
    amortizationPeriod: 'amortizationPeriod',
    preAmortizationPeriod: 'preAmortizationPeriod',
    IsFromAzimutMarketplace: 'IsFromAzimutMarketplace',
  };
  const fieldRequiredLabel = t('fieldRequired.label');
  const theRequestedAmortizingPeriodLabel = t(
    `${tenor} ${minMaturityInMonths} ${and} ${maxMaturityInMonths} ${months}`
  );

  const theRequestedAmountLabel = t(`${amount} ${min} ${and} ${max}`);
  const theBrokerageAmountLabel = t(
    `${brokerageAmount} ${brokerageFee?.data?.maxValue.toFixed(2)}% `
  );

  return {
    key,
    shape: yup.object().shape({
      [key.conventionCode]: yup.string().notRequired().nullable(),
      [key.financePurposeDescription]: yup.string().nullable(),
      [key.financePurposeId]: yup
        .number()
        .required(fieldRequiredLabel)
        .nullable(),
      [key.financePurposeDetailId]: yup
        .number()
        .required(fieldRequiredLabel)
        .nullable(),
      [key.brokerageFee]: yup
        .number()
        .min(0, theBrokerageAmountLabel)
        .max(brokerageFee?.data?.maxValue, theBrokerageAmountLabel)
        .nullable(),
      [key.requestedTenor]: yup
        .number()
        .min(minMaturityInMonths, theRequestedAmortizingPeriodLabel)
        .max(maxMaturityInMonths, theRequestedAmortizingPeriodLabel)
        .required(fieldRequiredLabel)
        .nullable(),
      [key.requestedAmount]: yup
        .number()
        .required(fieldRequiredLabel)
        .min(financingMinAmount, theRequestedAmountLabel)
        .max(financingMaxAmount, theRequestedAmountLabel)
        .nullable(),
      [key.IsFromAzimutMarketplace]: yup.boolean(),
    }),
  };
};

export { getSchema };
