import { lazy } from 'react';

export const neosRoutes = [
  {
    path: '/neos/registry/:registryId/callback',
    component: lazy(() => import('./Neos.component')),
  },
  {
    path: '/neos/registry/:registryId/borrower/:borrowerId/application/:applicationId/:requestId/callback',
    component: lazy(() => import('./Neos.component')),
  },
  {
    path: '/neos/registry/:registryId/borrower/:borrowerId/:requestId/callback',
    component: lazy(() => import('./Neos.component')),
  },
];
