import Bin from '../../assets/icons/modal/bin';
import Info from '../../assets/icons/modal/info';
import Success from '../../assets/icons/modal/success';
import Warning from '../../assets/icons/modal/warning';
import Confirmation from '../../assets/icons/modal/confirmation';
import { colorType, extractColor } from '../../helpers';
import Danger from '../../assets/icons/modal/danger';

const modalType = {
  warning: 'warning',
  confirm: 'confirm',
  delete: 'delete',
  successfully: 'successfully',
  information: 'information',
  danger: 'danger',
};

const modalConfiguration = {
  [modalType.warning]: {
    icon: Warning,
    headerColor: '#FFAD0C',
    danger: false,
    cancelButtonProps: {
      className: '',
    },
  },
  [modalType.confirm]: {
    icon: Confirmation,
    headerColor: `${extractColor(colorType.warning, 3)}`,
    danger: false,
    cancelButtonProps: {
      hasCancelButton: true,
    },
  },
  [modalType.delete]: {
    icon: Bin,
    headerColor: `${extractColor(colorType.danger, 3)}`,
    danger: true,
    cancelButtonProps: {
      hasCancelButton: true,
    },
  },
  [modalType.successfully]: {
    icon: Success,
    headerColor: `${extractColor(colorType.secondary, 3)}`,
    danger: false,
    cancelButtonProps: {
      className: '',
    },
  },
  [modalType.information]: {
    icon: Info,
    headerColor: `${extractColor(colorType.primary, 3)}`,
    danger: false,
    cancelButtonProps: {
      hasCancelButton: true,
    },
  },
  [modalType.danger]: {
    icon: Danger,
    headerColor: `${extractColor(colorType.danger, 3)}`,
    danger: false,
    cancelButtonProps: {
      hasCancelButton: true,
    },
  },
};

export { modalType, modalConfiguration };
