import { createBrowserHistory } from 'history';
import axios from 'axios';
import { displaySuccess } from './message';
import { SERVER_BASE_URL } from '../settings';

const history = createBrowserHistory();

const extractParameterFromQuery = (name: string, query: string) => {
  const match = RegExp('[?&]' + name + '=([^&]*)').exec(query);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
};

const location = history.location;

if (location.pathname === '/verify-contact-email') {
  const token = extractParameterFromQuery('token', location.search);

  axios
    .request({
      baseURL: SERVER_BASE_URL,
      url: `/application-contacts/verify-email`,
      method: 'post',
      data: {
        token: token,
      },
    })
    .then(() => {
      displaySuccess('Email has been verified');
    });
}

export { history };
