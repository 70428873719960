import {
  SettingsModel,
  TenantModel,
} from '../reducers/settings/settings.types';
import { callApi, PUBLIC_API_PATHS } from '../../helpers';

export const getSettings = async (): Promise<SettingsModel> => {
  const tenantsPromise = callApi({ url: PUBLIC_API_PATHS.TENANTS });
  const culturesPromise = callApi({ url: PUBLIC_API_PATHS.CULTURES });

   
  let [tenants, cultures] = await Promise.all([
    tenantsPromise,
    culturesPromise,
  ]);
  tenants = tenants.map((t: TenantModel) => {
    return {
      ...t,
      theme: t.theme ? JSON.parse(t.theme) : undefined,
    };
  });
  return { tenants, cultures };
};
