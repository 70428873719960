import { Switch as CustomSwitch } from 'antd';
import { DefaultTheme, useTheme } from 'styled-components';
import { colorType } from '../../../helpers';
import Styles from './style';
import { CustomTheme } from '@opyn/utils';

interface Props {
  value?: boolean;
  onChange?: (value: boolean) => void;
  primaryColor?: string | boolean;
  secondaryColor?: string | boolean;
  [x: string]: any;
}

const Switch = ({
  value,
  onChange,
  primaryColor,
  secondaryColor,
  ...rest
}: Props) => {
  const theme: DefaultTheme = useTheme();

  if (!primaryColor)
    primaryColor = (theme as CustomTheme).extractColor(colorType.secondary, 3);

  if (!secondaryColor)
    secondaryColor = (theme as CustomTheme).extractColor(colorType.warning, 3);

  return (
    <Styles
      $primaryColor={primaryColor}
      $secondaryColor={secondaryColor}
      $checked={value}
    >
      <CustomSwitch
        {...rest}
        size="small"
        checked={value}
        onChange={() => {
          onChange?.(!value);
        }}
      />
    </Styles>
  );
};

export default Switch;
