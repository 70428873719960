import { SettingsModel } from '../redux-store/reducers/settings/settings.types';

export const paginationKey = {
  pageSize: 'PageSize',
  pageIndex: 'PageIndex',
};

export const paginationTypes = {
  internal: 'internal',
  serverSide: 'serverSide',
};

export const sorterKey = {
  orderBy: 'OrderBy',
  sortOrder: 'SortOrder',
};

export const tenantKey = 'x-tenant-id';

export const defaultTableProperties = {
  page: 1,
  size: 15,
};

export const globalSettings: SettingsModel = {
  tenants: [],
  cultures: [],
};
