import { ReactElement } from 'react';
import {
  SaveOutlined,
  DoubleRightOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { CustomTheme, useTranslator } from '@opyn/utils';
import { Col, Row, Button } from 'antd';
import Required from '../../required/Required.component';
import styled, { DefaultTheme, useTheme } from 'styled-components';
import { colorType } from '../../../../../../../helpers';
import { isValsabbina } from '../../../../../../../helpers/tenant';
import { useStepper } from '../../../../../../../components/stepper';

const CustomDiv = styled.div`
  pointer-events: auto !important;
`;

interface Props {
  onSubmit?: () => Promise<void>;
  canGoBack?: boolean;
  showFooter?: boolean;
  disableSubmit?: boolean;
  skipTitleTranslationId?: string;
  backTitleTranslationId?: string;
  confirmTitleTranslateId?: string;
}

export interface ICurrentStep {
  canBeEditable: (params: {
    completed?: number[];
    isCompleted: boolean;
  }) => boolean;
  canSkip?: boolean;
  component?: ReactElement;
  prepareDescription?: (description?: string) => string;
  id?: number;
}

interface UseStepperProps {
  canGoBack?: boolean;
  onBack?: () => void;
  currentStep?: ICurrentStep;
  isCompleted: boolean;
  completed?: number[];
  onSkip?: () => void;
}

const Footer = (props: Props) => {
  const theme: DefaultTheme = useTheme();
  const translator = useTranslator();
  const {
    showFooter = true,
    backTitleTranslationId = 'goBack.label',
    skipTitleTranslationId = 'skip.label',
    onSubmit = () => {},
    disableSubmit = false,
    confirmTitleTranslateId,
  } = props;

  const {
    canGoBack,
    onBack,
    currentStep,
    isCompleted,
    completed,
    onSkip,
  }: UseStepperProps = useStepper();

  const isContentEditable = currentStep?.canBeEditable({
    isCompleted,
    completed,
  });

  const journeyButtonTranslateId =
    confirmTitleTranslateId ||
    (isContentEditable ? 'saveAndContinue.label' : 'next.label');

  const loanButtonTranslateId = isCompleted
    ? 'next.label'
    : confirmTitleTranslateId;

  const confirmButtonTranslateId = isCompleted
    ? translator.translate(loanButtonTranslateId)
    : translator.translate(journeyButtonTranslateId);

  if (!showFooter) return null;

  return (
    <CustomDiv>
      <Row align="middle" gutter={16} justify="start">
        {(Object.prototype.hasOwnProperty.call(props, 'canGoBack')
          ? props.canGoBack
          : canGoBack) && (
          <Col>
            <Button
              type="primary"
              ghost
              onClick={onBack}
              // disabled={disableSubmit}
              icon={<LeftOutlined />}
              style={{
                color: (theme as CustomTheme).extractColor(colorType.blue, 3),
                borderColor: (theme as CustomTheme).extractColor(
                  colorType.blue,
                  3
                ),
              }}
            >
              {translator.translate(backTitleTranslationId)}
            </Button>
          </Col>
        )}
        {currentStep?.canSkip && (
          <Col>
            <Button
              data-testid="skipButton"
              type="primary"
              ghost
              onClick={onSkip}
              icon={<DoubleRightOutlined />}
              style={{
                color: (theme as CustomTheme).extractColor(colorType.blue, 3),
                borderColor: (theme as CustomTheme).extractColor(
                  colorType.blue,
                  3
                ),
              }}
            >
              {translator.translate(skipTitleTranslationId)}
            </Button>
          </Col>
        )}
        <Col>
          <Button
            type="primary"
            htmlType="submit"
            // loading={loading}
            data-testid="submitButton"
            disabled={disableSubmit}
            onClick={onSubmit}
            className={
              isValsabbina
                ? 'ant-button-emp-valsabbina'
                : 'ant-button-emp-other-settings'
            }
            icon={isContentEditable ? <SaveOutlined /> : null}
          >
            {translator.translate(confirmButtonTranslateId)}
          </Button>
        </Col>
      </Row>
      <Required className="mt8 mb28" {...props} />
    </CustomDiv>
  );
};

export default Footer;
