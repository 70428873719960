export const extractElement = (data: any, position: any) => {
  return data[position];
};

export const extractFirstElement = (data: any, defaultValue: any = null) => {
  if (!data || !data.length) return defaultValue;
  return extractElement(data, 0);
};

export const extractFromQueryResponse = (query: any, entity: string) => {
  const response = query?.data;

  if (!response) return null;

  if (Array.isArray(response)) {
    return entity === 'data' ? response : undefined;
  } else if (typeof response === 'object') {
    if (entity !== 'data') return response[entity];

    if (Object.prototype.hasOwnProperty.call(response, entity))
      return response[entity];
    else return response;
  } else {
    return undefined;
  }
};

export const extractLoadingFromQuery = (query: any) => {
  return query?.loading;
};

export const extractCountFromQuery = (query: any) => {
  return extractFromQueryResponse(query, 'totalCount');
};

export const extractResultsFromQuery = (query: any) => {
  if (!query) return null;

  if (Array.isArray(query)) return query;

  if (typeof query === 'object') return extractFromQueryResponse(query, 'data');

  return query;
};

export const extractTablePropertiesFromQuery = (
  query: any,
  options: any = {}
) => {
  const object = {
    count: extractCountFromQuery(query),
    data: extractResultsFromQuery(query),
    loading: extractLoadingFromQuery(query),
    onChange: (params: any) => {
      let finalQuery = { ...options.filters, ...params.query };
      if (options.onPrepareFilters)
        finalQuery = options.onPrepareFilters(finalQuery);
      query.replaceQuery(finalQuery);
    },
    ...options,
  };

  if (Object.prototype.hasOwnProperty.call(options, 'filters'))
    object.filters = JSON.stringify(options.filters);

  return object;
};
