interface BinProps {
  fillColor?: string;
}

const Bin = ({ fillColor = '#FF5050' }: BinProps) => {
  return (
    <svg
      width="27"
      height="29"
      viewBox="0 0 27 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.875 5.0625H21.375V2.25C21.375 1.00898 20.366 0 19.125 0H7.875C6.63398 0 5.625 1.00898 5.625 2.25V5.0625H1.125C0.502734 5.0625 0 5.56523 0 6.1875V7.3125C0 7.46719 0.126562 7.59375 0.28125 7.59375H2.40469L3.27305 25.9805C3.3293 27.1793 4.3207 28.125 5.51953 28.125H21.4805C22.6828 28.125 23.6707 27.1828 23.727 25.9805L24.5953 7.59375H26.7188C26.8734 7.59375 27 7.46719 27 7.3125V6.1875C27 5.56523 26.4973 5.0625 25.875 5.0625ZM8.15625 2.53125H18.8438V5.0625H8.15625V2.53125ZM21.2098 25.5938H5.79023L4.93945 7.59375H22.0605L21.2098 25.5938Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default Bin;
