import { callApi, PRIVATE_API_PATHS } from '../../helpers';
import { ApplicationModel } from '../../pages/admin/application/Application.types';
import { LoanModel } from '../../pages/admin/application/Loan.types';

export const getApplication = async (
  id: number
): Promise<ApplicationModel[]> => {
  return await callApi({ url: PRIVATE_API_PATHS.APPLICATION(id) });
};

export const getLoan = async (id: number): Promise<LoanModel[]> => {
  return await callApi({ url: PRIVATE_API_PATHS.LOAN(id) });
};

export const onChangeBorrower = async (id: string): Promise<LoanModel[]> => {
  return await callApi({ url: PRIVATE_API_PATHS.CHANGE_BORROWER(id) });
};
