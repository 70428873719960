import * as Sentry from '@sentry/react';
import { GET_ENV_NAME, SERVER_BASE_URL } from './src/settings';

export const sentryOptions: Sentry.BrowserOptions = {
  dsn: 'https://6495c945e8ca69767c97191ddfbb29a8@o4506953909338112.ingest.us.sentry.io/4506954130325504',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [SERVER_BASE_URL],
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: GET_ENV_NAME(),
};
