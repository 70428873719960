import { STATUS } from './types/globals.types';

export const commonReducer = {
  status: STATUS.IDLE,
  isSuccess: false,
  isError: false,
};

export const commonDirectReducer = {
  isSuccess: false,
  isError: false,
};
