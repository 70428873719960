import styled from 'styled-components';
import { isValsabbina } from '../../../../../../../helpers/tenant';
import { colorType, extractColor } from '../../../../../../../helpers';

export default styled.div`
  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 110%;
    color: ${() =>
      isValsabbina ? extractColor(colorType.primary, 3) : '#003B5C'};
  }

  .count {
    color: ${extractColor(colorType.primary, 2)};
    margin-top: 10px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
  }

  .description {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: ${() =>
      isValsabbina ? extractColor(colorType.primary, 3) : '#003B5C'};
  }
`;
