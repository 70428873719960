import { Col, Row } from 'antd';
import Styles from './Header.style';
import { useTranslator } from '@opyn/utils';
import { DefaultTheme, useTheme } from 'styled-components';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { ICurrentStep } from '../footer/Footer.component';
import { useStepper } from '../../../../../../../components/stepper';
import triggerModal from '../../../../../../../components/trigger_modal';
import { routeIncludes } from '../../../../../../common/how_to/How.config';

interface IDictionary extends ICurrentStep {
  titleTranslationId?: string;
  descriptionTranslationId?: string;
}

interface Props {
  extra?: boolean;
  translations?: IDictionary;
  extraPosition?: string;
}

const Header = (props: Props) => {
  const { extra, extraPosition = 'top', translations } = props;
  const theme: DefaultTheme = useTheme();
  const translator = useTranslator();
  const { count, currentStep } = useStepper();

  const dictionary: IDictionary | undefined = translations || currentStep;

  let description = translator.translate(dictionary?.descriptionTranslationId);

  if (currentStep?.prepareDescription)
    description = currentStep.prepareDescription(String(description));

  const fatcaStep = currentStep?.id === 3;

  const popoverTrigger = () => {
    triggerModal({
      theme: theme,
      type: 'information',
      content: `${translator.translate('fatcaTooltipContent.label')}`,
    });
  };

  return (
    <Styles>
      <Row gutter={[12, 12]} className="full-width">
        {extra && extraPosition === 'top' && extra}
        <Col xs={24} className="count">
          {translator.translate('step.label')} {count}
        </Col>
        <Col xs={24} className="title" data-testid="title-pageName">
          {translator.translate(dictionary?.titleTranslationId)}
        </Col>
        <Col xs={24} className="description">
          {fatcaStep && routeIncludes('loan') ? (
            <>
              {description}{' '}
              <QuestionCircleOutlined
                className="questionMark"
                onClick={popoverTrigger}
              />
            </>
          ) : (
            description
          )}
        </Col>
        {extra && extraPosition === 'bottom' && extra}
      </Row>
    </Styles>
  );
};

export default Header;
