import React, { ReactNode, useRef } from 'react';
import { assignErrorInterceptor } from '../../../helpers/interceptors';
import {
  AuthStorage,
  client,
  displayWarning,
  parseJwt,
  storage,
  uid,
} from '../../../helpers';
import { useSettings } from '../../hooks/settings';
import { globalSettings } from '../../../constants/settings';
import { useSearchParams } from 'react-router-dom';
import { TenantModel } from '../../../redux-store/reducers/settings/settings.types';
import { PermissionsModel } from '../../../redux-store/reducers/permissions/permissions.types';
import {
  permissions as permissionsSlice,
  permissionsReset,
} from '../../../redux-store/reducers/permissions/permissionsSlice';
import {
  authLogin,
  authLogout,
  authRefreshToken,
  authReset,
} from '../../../redux-store/reducers';
import {
  AuthModel,
  LoginRequestBody,
} from '../../../redux-store/reducers/auth/auth.types';
import { useAppDispatch, useAppSelector } from '../../../redux-store/hooks';
import { STATUS } from '../../../redux-store/types/globals.types';

const AuthenticationContext = React.createContext({});

const AuthenticationProvider = (props: { children: ReactNode }) => {
  const settings = useSettings();
  const { bindTenantName, updateTenant } = useSettings();
  const [permissions, setPermissions] = React.useState<
    PermissionsModel[] | null
  >(null);
  const [initialized, setInitialized] = React.useState<number | null>(null);
  const [authenticating, setAuthenticating] = React.useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const [authenticatedToken, setAuthenticatedToken] = React.useState<
    string | null
  >(() => {
    const initialToken = AuthStorage.getAccessToken();
    return settings.id ? initialToken : null;
  });
  const dispatch = useAppDispatch();
  const permissionsSelector = useAppSelector((state) => state.permissions);

  const loginReference = useRef({
    started: false,
  });

  React.useEffect(() => {
    setInitialized(
      assignErrorInterceptor({
        client,
        onSessionEnd: onLogout,
        refreshSession: onRefreshToken,
        onSessionUpdate: AuthStorage.setAccessToken,
      })
    );
  }, []);

  React.useEffect(() => {
    const handleSessionExpire = (event: StorageEvent) => {
      if (
        event.key === 'accessToken' &&
        authenticatedToken &&
        !AuthStorage.getAccessToken()
      ) {
        onLogout();
        window.close();
      }
    };

    window.addEventListener('storage', handleSessionExpire);

    return () => window.removeEventListener('storage', handleSessionExpire);
  }, []);

  React.useEffect(() => {
    if (authenticatedToken) fetchPermissions().then();
  }, [settings?.id, authenticatedToken]);

  const fetchPermissions = async () => {
    if (permissionsSelector?.status === STATUS.LOADING) {
      return;
    }
    if (permissionsSelector?.permissions?.length !== 0) {
      setPermissions(permissionsSelector?.permissions);
      return;
    }

    const actionResult = await dispatch(permissionsSlice());
    if (permissionsSlice.fulfilled.match(actionResult)) {
      const payload = actionResult.payload?.permissions;
      setPermissions(payload);
      if (!payload) return onLogout();
    }
  };

  const onLogin = async (credentials: LoginRequestBody) => {
    if (loginReference.current.started) return;
    setAuthenticating(true);
    loginReference.current.started = true;
    try {
      // Dispatch the authLogin action with the credentials
      const actionResult = await dispatch(authLogin(credentials));
      if (authLogin.fulfilled.match(actionResult)) {
        const response = actionResult.payload;
        if (response) {
          onAuthenticate(response);
          storage.set('loanSessionId', uid());

          if (response.redirection) updateTenant(response.redirection.tenantId);
          await fetchPermissions().finally(() => {
            setAuthenticating(false);
            loginReference.current.started = false;
          });
        } else {
          loginReference.current.started = false;
        }
      }
    } catch (error) {
      loginReference.current.started = false;
    }
  };

  const onRefreshToken = async (): Promise<{ refreshToken: string } | void> => {
    const actionResult = await dispatch(authRefreshToken());
    if (authRefreshToken.fulfilled.match(actionResult)) {
      return actionResult.payload;
    } else {
      displayWarning('Session expired. Please log in again !');
      setTimeout(() => {
        window.localStorage.clear();
        window.location.reload();
      });
    }
  };

  const getInitialRoute = (token = authenticatedToken) => {
    if (!token) return bindTenantName('/signin');
    const user = parseJwt(token);
    const redirectUri = searchParams?.get('redirectUri');

    if (user.is_system_user) {
      if (redirectUri?.includes('/admin')) {
        return redirectUri;
      } else {
        return bindTenantName('/admin');
      }
    } else {
      if (redirectUri?.includes('/borrower')) {
        return redirectUri;
      } else {
        return bindTenantName('/borrower');
      }
    }
  };

  const getAllowedTenants = () => {
    if (!authenticatedToken) return globalSettings.tenants;

    const user = parseJwt(authenticatedToken);
    return globalSettings.tenants?.filter((t: TenantModel) =>
      user?.tenant_ids?.includes(t.id)
    );
  };

  const onLogout = async () => {
    setAuthenticatedToken(null);
    dispatch(authReset());
    dispatch(permissionsReset());
    await dispatch(authLogout());
    settings.navigate('/signin');
  };

  const onAuthenticate = (payload: AuthModel) => {
    AuthStorage.setAccessToken(payload.accessToken as string);
    AuthStorage.setRefreshToken(payload.refreshToken as string);
    setAuthenticatedToken(payload.accessToken as string);
  };

  const isGranted = (key: string): boolean => {
    if (
      !authenticatedToken ||
      !parseJwt(authenticatedToken)['Permission'] ||
      !permissions
    )
      return false;
    return !!permissions?.find((p) => p.code === key);
  };

  const contextValue = React.useMemo(
    () => ({
      onLogin,
      onLogout,
      isGranted,
      refreshToken: onRefreshToken,
      authenticating,
      getInitialRoute,
      onAuthenticate,
      getAllowedTenants,
      user: authenticatedToken ? parseJwt(authenticatedToken) : null,
      authenticated: !!authenticatedToken,
      authenticatedToken: authenticatedToken,
    }),
    [authenticatedToken, permissions, settings.id]
  );

  if (!initialized || (authenticatedToken && !permissions)) return null;
  return (
    <AuthenticationContext.Provider value={contextValue}>
      {props.children}
    </AuthenticationContext.Provider>
  );
};

export { AuthenticationProvider, AuthenticationContext };
