import { lazy } from 'react';
import { permissionKey } from './constants/auth';
import PublicLayout from './pages/public/components/layout/Layout.component';
import { useAuthentication } from '@opyn/utils';
import { Navigate } from 'react-router-dom';
import PageNotFound from './components/page_not_found';
import { neosRoutes } from './pages/neos/Neos.routes';
import { borrowerRoutes } from './pages/borrower/Borrower.routes';
import { AdminRoutes } from './pages/admin/Admin.routes';
import { AdminApplicationRoutes } from './pages/admin/application/Application.routes';

interface RouteParams {
  nested?: boolean;
  isPublic?: boolean;
}

const RedirectToDefaultPath = () => {
  const { authenticated, getInitialRoute } = useAuthentication();
  if (!authenticated) return <PageNotFound />;
  return <Navigate to={getInitialRoute()} />;
};

export const getHowToRoute = (routeParams?: RouteParams) => ({
  ...routeParams,
  children: [],
  path: '/how/:pkId',
  component: lazy(() => import('./pages/common/how_to/How.components')),
});

const router = {
  public: [
    getHowToRoute({
      nested: true,
      isPublic: true,
    }),
    {
      nested: false,
      isPublic: true,
      children: [],
      defaultAllowed: true,
      path: '/UserInvitation',
      permissionKey: permissionKey['UserInvitation.View'],
      component: lazy(
        () => import('./pages/public/user_invitation/UserInvitation.component')
      ),
    },
    {
      nested: false,
      isPublic: true,
      defaultAllowed: true,
      path: '/change-phone-number',
      component: lazy(
        () =>
          import(
            './pages/common/user/account_settings/components/approve_change_phone/ApproveChangePhone.component'
          )
      ),
      children: [],
    },
    {
      isPublic: true,
      component: PublicLayout,
      children: [
        {
          isPublic: true,
          nested: true,
          path: '/signin',
          component: lazy(
            () => import('./pages/public/signin/Signin.component')
          ),
          children: [],
        },
        {
          isPublic: true,
          nested: true,
          defaultAllowed: true,
          path: '/signup',
          component: lazy(
            () => import('./pages/public/signup/Signup.component')
          ),
          children: [],
        },
        {
          isPublic: true,
          nested: true,
          children: [],
          path: '/recovery',
          component: lazy(
            () => import('./pages/public/recovery/Recovery.component')
          ),
        },
        {
          isPublic: true,
          nested: true,
          children: [],
          path: '/username-recovery',
          component: lazy(
            () =>
              import(
                './pages/public/username_recovery/UsernameRecovery.component'
              )
          ),
        },
        {
          isPublic: true,
          nested: true,
          children: [],
          path: '/verification',
          component: lazy(
            () =>
              import(
                './pages/public/email_verification/EmailVerification.component'
              )
          ),
        },
        {
          isPublic: true,
          nested: true,
          children: [],
          path: '/contact-verification',
          component: lazy(
            () =>
              import(
                './pages/public/contact_email_verified/ContactEmailVerified.component'
              )
          ),
        },
        {
          defaultAllowed: true,
          children: [],
          path: '/email-verification',
          component: lazy(
            () =>
              import(
                './pages/public/contact_verification/ContactVerification.component'
              )
          ),
        },
        {
          isPublic: true,
          nested: true,
          children: [],
          path: '/recover-password',
          component: lazy(
            () =>
              import(
                './pages/public/recover_password/RecoverPassword.component'
              )
          ),
        },
        {
          isPublic: true,
          nested: true,
          children: [],
          path: '/simulator',
          component: lazy(
            () => import('./pages/public/simulator/Simulator.component')
          ),
        },
        {
          isPublic: true,
          nested: true,
          path: '/verify',
          children: [],
          component: lazy(
            () => import('./pages/public/verify/Verify.component')
          ),
        },
      ],
    },
  ],
  private: [
    {
      path: '/admin',
      children: [
        ...AdminRoutes,
        getHowToRoute({ nested: true }),
        {
          key: 'application',
          permissionKey: permissionKey['Application.View'],
          path: '/application/:id',
          children: [
            ...AdminApplicationRoutes,
            getHowToRoute({ nested: true }),
          ],
          component: lazy(
            () => import('./pages/admin/application/Application.component')
          ),
        },
      ],
      component: lazy(() => import('./pages/admin/Admin.component')),
    },
    {
      path: '/borrower',
      children: [...borrowerRoutes, getHowToRoute({ nested: true })],
      component: lazy(() => import('./pages/borrower/Borrower.component')),
    },
    ...neosRoutes,
  ],
  common: [
    {
      path: '/',
      defaultAllowed: true,
      component: RedirectToDefaultPath,
    },
    {
      path: '*',
      defaultAllowed: true,
      component: RedirectToDefaultPath,
    },
  ],
};

export { router, AdminRoutes, AdminApplicationRoutes };
