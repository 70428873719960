import React, { lazy } from 'react';
import { permissionKey } from '../../constants/auth';

const adminSectionRoutes = {
  homeRoutes: [
    // Home section
    {
      nested: true,
      path: '/home',
      key: 'homePage',
      component: lazy(() => import('./section-Home/home/Home.component')),
    },
  ],
  paymentsRoutes: [
    // Payments and Securitization section
    {
      nested: true,
      path: '/payments',
      key: 'payments',
      component: lazy(
        () => import('./section-Payments/payments/Payments.component')
      ),
    },
    {
      nested: true,
      key: 'promisesOfPayment',
      path: '/promises-of-payment',
      permissionKey: permissionKey['PaymentPromise.View'],
      component: lazy(
        () =>
          import(
            './section-Payments/payments/components/promises_of_payment/PromisesOfPayment.component'
          )
      ),
    },
    {
      nested: true,
      key: 'sedaAlignment',
      permissionKey: permissionKey['LegalCheck.View'],
      path: '/seda-alignment',
      component: lazy(
        () =>
          import(
            './section-Payments/payments/components/seda_alignment/SedaAlignment.component'
          )
      ),
    },
    {
      nested: true,
      path: '/requested-documents-130',
      key: 'requestedDocuments130',
      component: lazy(
        () =>
          import(
            './section-Payments/requestedDocuments130/RequestedDocuments130.component'
          )
      ),
    },
    {
      nested: true,
      key: 'securitization',
      permissionKey: permissionKey['LegalCheck.View'],
      path: '/securitization',
      component: lazy(
        () =>
          import('./section-Payments/securitization/Securitization.component')
      ),
    },
    {
      nested: true,
      key: 'bos',
      permissionKey: permissionKey['LegalCheck.View'],
      path: '/bos',
      component: lazy(
        () =>
          import(
            './section-Payments/securitization_bos/SecuritizationBos.component'
          )
      ),
    },
  ],
  accountingRoutes: [
    // Accounting section
    {
      nested: true,
      path: '/accounting-records',
      permissionKey: permissionKey['Accounting.View'],
      key: 'accountingRecords',
      component: lazy(
        () =>
          import(
            './section-Accounting/accounting_records/AccountingRecords.component'
          )
      ),
    },
    {
      nested: true,
      path: '/accounting-records/:lenderId',
      key: 'accountingRecordsLenderDetail',
      permissionKey: permissionKey['Accounting.View'],
      component: lazy(
        () =>
          import(
            './section-Accounting/accounting_records/pages/lender_details/LenderDetails.component'
          )
      ),
    },
  ],
  legalCheckRoutes: [
    // Legal checks section
    {
      nested: true,
      key: 'legalCheck',
      permissionKey: permissionKey['LegalCheck.View'],
      path: '/legal-check',
      component: lazy(
        () =>
          import('./section-Legal_checks/legal_checks/LegalChecks.component')
      ),
    },
  ],
  surveyManagementRoutes: [
    // Survey management section
    {
      nested: true,
      path: '/survey-management',
      permissionKey: permissionKey['Survey.View'],
      component: lazy(
        () =>
          import(
            './section-Survey_management/survey_management/SurveyManagement.component'
          )
      ),
    },
    {
      nested: true,
      path: '/survey-management/:surveyType',
      permissionKey: permissionKey['Survey.View'],
      component: lazy(
        () =>
          import(
            './section-Survey_management/survey_management/components/Survey.component'
          )
      ),
    },
  ],
  saasConfigurationRoutes: [
    // Saas Configuration section
    {
      nested: true,
      path: '/saas-configuration',
      permissionKey: permissionKey['SaaSConfiguration.View'],
      component: lazy(
        () =>
          import(
            './section-Saas_configuration/saas_configuration/SaasConfiguration.component'
          )
      ),
    },
  ],
  contactManagementRoutes: [
    // Contact management section
    {
      nested: true,
      path: '/email-categories',
      permissionKey: permissionKey['Lookup.View'],
      component: lazy(
        () =>
          import(
            './section-Contact_management/email_categories/EmailCategories.component'
          )
      ),
    },
  ],
  documentManagementRoutes: [
    // Document management section
    {
      nested: true,
      path: '/templates-configuration',
      permissionKey: permissionKey['DocumentsConfiguration.View'],
      component: lazy(
        () =>
          import(
            './section-Document_management/templates_configuration/TemplatesConfiguration.component'
          )
      ),
    },
    {
      nested: true,
      path: '/templates-configuration/create',
      permissionKey: permissionKey['DocumentsConfiguration.View'],
      component: lazy(
        () =>
          import(
            './section-Document_management/templates_configuration/pages/Template.component'
          )
      ),
    },
    {
      nested: true,
      path: '/templates-configuration/:documentMagazineName/:id',
      permissionKey: permissionKey['DocumentsConfiguration.View'],
      component: lazy(
        () =>
          import(
            './section-Document_management/templates_configuration/pages/Template.component'
          )
      ),
    },
    {
      nested: true,
      path: '/document-magazine',
      permissionKey: permissionKey['DocumentsConfiguration.View'],
      component: lazy(
        () =>
          import(
            './section-Document_management/document_magazine/DocumentMagazine.component'
          )
      ),
    },
    {
      nested: true,
      path: '/document-configuration',
      permissionKey: permissionKey['DocumentsConfiguration.View'],
      component: lazy(
        () =>
          import(
            './section-Document_management/document_configuration/DocumentConfiguration.component'
          )
      ),
    },
    {
      nested: true,
      path: '/documents-list',
      // permissionKey: permissionKey["DocumentsConfiguration.View"],
      component: lazy(
        () =>
          import(
            './section-Document_management/documents_list/DocumentsLists.component'
          )
      ),
    },
    {
      nested: true,
      path: '/macro-group',
      permissionKey: permissionKey['DocumentsConfiguration.View'],
      component: lazy(
        () =>
          import(
            './section-Document_management/macro_group/MacroGroup.component'
          )
      ),
    },
    {
      nested: true,
      path: '/mcc-document-configuration',
      permissionKey: permissionKey['DocumentsConfiguration.View'],
      component: lazy(
        () =>
          import(
            './section-Document_management/mcc_document_configuration/MccDocumentConfiguration.component'
          )
      ),
    },
    {
      nested: true,
      path: '/document-template-variables',
      permissionKey: permissionKey['DocumentsConfiguration.View'],
      component: lazy(
        () =>
          import(
            './section-Document_management/document_variables/DocumentVariables.component'
          )
      ),
    },
  ],
  generalSettingsRoutes: [
    // General Settings section
    {
      nested: true,
      path: '/business-rules',
      key: 'businessRules',
      permissionKey: permissionKey['ScorecardConfiguration.View'],
      component: lazy(
        () =>
          import(
            './section-General_settings/business_rules/BusinessRules.component'
          )
      ),
    },
    {
      nested: true,
      path: '/legal-threshold',
      component: lazy(
        () =>
          import(
            './section-General_settings/legal_threshold/LegalThreshold.component'
          )
      ),
    },
    {
      nested: true,
      path: '/approving-authority',
      permissionKey: permissionKey['FinancialApproval.View'],
      component: lazy(
        () =>
          import(
            './section-General_settings/approving_authority/ApprovingAuthority.component'
          )
      ),
    },
    {
      nested: true,
      path: '/company-type',
      permissionKey: permissionKey['Lookup.View'],
      component: lazy(
        () =>
          import(
            './section-General_settings/company_type/CompanyType.component'
          )
      ),
    },
    {
      nested: true,
      path: '/financial-purpose',
      permissionKey: permissionKey['Lookup.View'],
      component: lazy(
        () =>
          import(
            './section-General_settings/financing_purpose/FinancingPurpose.component'
          )
      ),
    },
    {
      nested: true,
      path: '/financial-purpose-details',
      permissionKey: permissionKey['Lookup.View'],
      component: lazy(
        () =>
          import(
            './section-General_settings/financial_purpose_details/FinancialPurposeDetails.component'
          )
      ),
    },
    {
      nested: true,
      path: '/sources-of-signup',
      component: lazy(
        () =>
          import(
            './section-General_settings/sources_of_signup/SourcesOfSignup.component'
          )
      ),
    },
    {
      nested: true,
      path: '/currency',
      permissionKey: permissionKey['ProductConfiguration.View'],
      component: lazy(
        () => import('./section-General_settings/currency/Currency.component')
      ),
    },
    {
      nested: true,
      path: '/bank',
      component: lazy(
        () => import('./section-General_settings/bank/Bank.component')
      ),
    },
    {
      nested: true,
      path: '/amortizing-plan-updates',
      component: lazy(
        () =>
          import(
            './section-General_settings/amortizing_plan_updates/AmortizingPlanUpdates.component'
          )
      ),
    },
    {
      nested: true,
      path: '/ateco',
      component: lazy(
        () => import('./section-General_settings/ateco/Ateco.component')
      ),
    },
    {
      nested: true,
      path: '/relationship-types-macrogroup',
      permissionKey: permissionKey['RegistryInfomation.View'],
      component: lazy(
        () =>
          import(
            './section-General_settings/relationship_types_macrogroup/RelationshipTypesMacroGroup.component'
          )
      ),
    },
    {
      nested: true,
      path: '/relationship-types',
      permissionKey: permissionKey['RegistryInfomation.View'],
      component: lazy(
        () =>
          import(
            './section-General_settings/relationship_types/RelationshipTypes.component'
          )
      ),
    },
    {
      nested: true,
      path: '/web-categories',
      permissionKey: permissionKey['Lookup.View'],
      component: lazy(
        () =>
          import(
            './section-General_settings/web_categories/WebCategories.component'
          )
      ),
    },
    {
      nested: true,
      path: '/lender-matrix',
      permissionKey: permissionKey['LenderMatrixConfiguration.View'],
      component: lazy(
        () =>
          import(
            './section-General_settings/lender_matrix/LenderMatrix.component'
          )
      ),
    },
    {
      nested: true,
      path: '/third-party-providers',
      component: lazy(
        () =>
          import('./section-General_settings/ana_upload/AnaUpload.component')
      ),
    },
    {
      nested: true,
      path: '/email-configuration/:id',
      permissionKey: permissionKey['EmailTemplate.View'],
      component: lazy(
        () =>
          import(
            './section-General_settings/email_configuration/details/Details.component'
          )
      ),
    },
    {
      nested: true,
      path: '/email-configuration',
      permissionKey: permissionKey['EmailTemplate.View'],
      component: lazy(
        () =>
          import(
            './section-General_settings/email_configuration/EmailConfiguration.component'
          )
      ),
      children: [
        {
          path: '/configuration-template',
          permissionKey: permissionKey['EmailTemplate.Create'],
          component: lazy(
            () =>
              import(
                './section-General_settings/email_configuration/details/Details.component'
              )
          ),
        },
      ],
    },
    {
      nested: true,
      path: '/mcc-commission',
      component: lazy(
        () =>
          import(
            './section-General_settings/mcc_commission/MCC_Commission.component'
          )
      ),
    },
    {
      nested: true,
      path: '/scorecard-configuration/:id',
      permissionKey: permissionKey['ScorecardConfiguration.View'],
      component: React.lazy(
        () =>
          import(
            './section-General_settings/scorecard_configuration/components/scorecard_configuration/configuration_view/ConfigurationView.component'
          )
      ),
    },
    {
      nested: true,
      path: '/scorecard-configuration',
      permissionKey: permissionKey['ScorecardConfiguration.View'],
      component: lazy(
        () =>
          import(
            './section-General_settings/scorecard_configuration/ScoreCardConfiguration.component'
          )
      ),
    },
    {
      nested: true,
      path: '/configuration-edit_providers/:id',
      permissionKey: permissionKey['ScorecardConfiguration.View'],
      component: React.lazy(
        () =>
          import(
            './section-General_settings/scorecard_configuration/components/scorecard_configuration/configuration_edit/ConfigurationEdit.component'
          )
      ),
    },
    {
      nested: true,
      path: '/scorecard-category-management',
      permissionKey: permissionKey['ScorecardConfiguration.View'],
      component: lazy(
        () =>
          import(
            './section-General_settings/scorecard_category_management/ScorecardCategoryManagement.component'
          )
      ),
    },
    {
      nested: true,
      path: '/scorecard-category-management/:id',
      permissionKey: permissionKey['ScorecardConfiguration.View'],
      component: React.lazy(
        () =>
          import(
            './section-General_settings/scorecard_category_management/components/configuration_view/ConfigurationView.component'
          )
      ),
    },
    {
      nested: true,
      path: '/financial-document-generation',
      permissionKey: permissionKey['ScorecardConfiguration.View'],
      component: lazy(
        () =>
          import(
            './section-General_settings/financial_document_generation/FinancialDocGeneration.component'
          )
      ),
    },
    {
      nested: true,
      path: '/brokerage-fee-configuration',
      permissionKey: permissionKey['ScorecardConfiguration.View'],
      component: lazy(
        () =>
          import(
            './section-General_settings/brokerage_configuration/BrokerageFeeConfiguration.component'
          )
      ),
    },
  ],
  authorizationRoutes: [
    // Authorization section
    {
      nested: true,
      path: '/users/:id',
      permissionKey: permissionKey['Identity.View'],
      component: lazy(
        () =>
          import(
            './section-Authorization/users/pages/user_permissions/Permissions.component'
          )
      ),
    },
    {
      nested: true,
      path: '/users',
      permissionKey: permissionKey['Identity.View'],
      component: lazy(
        () => import('./section-Authorization/users/Users.component')
      ),
    },
    {
      nested: true,
      path: '/roles/:id',
      permissionKey: permissionKey['Identity.View'],
      component: lazy(
        () =>
          import(
            './section-Authorization/roles/pages/permissions/Permissions.component'
          )
      ),
    },
    {
      nested: true,
      path: '/roles',
      permissionKey: permissionKey['Identity.View'],
      component: lazy(
        () => import('./section-Authorization/roles/Roles.component')
      ),
    },
    {
      nested: true,
      path: '/tenant-configuration',
      permissionKey: permissionKey['Identity.View'],
      component: lazy(
        () =>
          import(
            './section-Authorization/tenant_configuration/TenantConfiguration.component'
          )
      ),
    },
    {
      nested: true,
      path: '/aruba-users',
      permissionKey: permissionKey['Identity.View'],
      component: lazy(
        () => import('./section-Authorization/aruba_users/ArubaUsers.component')
      ),
    },
  ],
  locationInformationRoutes: [
    // Location information section
    {
      nested: true,
      path: '/nation',
      permissionKey: permissionKey['Lookup.View'],
      component: lazy(
        () => import('./section-Location_information/nation/Nation.component')
      ),
    },
    {
      nested: true,
      path: '/municipality',
      permissionKey: permissionKey['Lookup.View'],
      component: lazy(
        () =>
          import(
            './section-Location_information/municipality/Municipality.component'
          )
      ),
    },
    {
      nested: true,
      path: '/region',
      permissionKey: permissionKey['Lookup.View'],
      component: lazy(
        () => import('./section-Location_information/region/Region.component')
      ),
    },
    {
      nested: true,
      path: '/province',
      component: lazy(
        () =>
          import('./section-Location_information/province/Province.component')
      ),
    },
  ],
  checklistRoutes: [
    // Checklist section
    {
      nested: true,
      path: '/checklist-items',
      permissionKey: permissionKey['Checklist.View'],
      component: lazy(
        () =>
          import('./section-Checklist/checklist_items/ChecklistItems.component')
      ),
    },
    {
      nested: true,
      path: '/checklist-categories',
      permissionKey: permissionKey['Checklist.View'],
      component: lazy(
        () =>
          import(
            './section-Checklist/checklist_categories/ChecklistCategories.component'
          )
      ),
    },
  ],
  activitiesManagementRoutes: [
    // Activities management section
    {
      nested: true,
      path: '/action-categories',
      permissionKey: permissionKey['ActivitiesManagementConfiguration.View'],
      component: lazy(
        () =>
          import(
            './section-Activities_management/action_category/ActionCategory.component'
          )
      ),
    },
    {
      nested: true,
      path: '/action-types',
      permissionKey: permissionKey['ActivitiesManagementConfiguration.View'],
      component: lazy(
        () =>
          import(
            './section-Activities_management/action_type/ActionType.component'
          )
      ),
    },
    {
      nested: true,
      path: '/todo-categories',
      permissionKey: permissionKey['ActivitiesManagementConfiguration.View'],
      component: lazy(
        () =>
          import(
            './section-Activities_management/todo_category/TodoCategory.component'
          )
      ),
    },
    {
      nested: true,
      path: '/todo-types',
      permissionKey: permissionKey['ActivitiesManagementConfiguration.View'],
      component: lazy(
        () =>
          import('./section-Activities_management/todo_type/TodoType.component')
      ),
    },
  ],
  workflowRoutes: [
    // Workflow section
    {
      nested: true,
      path: '/default-workflow',
      component: lazy(
        () =>
          import(
            './section-Workflow/default_workflow/DefaultWorkflow.component'
          )
      ),
    },
    {
      nested: true,
      path: '/monitoring-workflow',
      component: lazy(
        () =>
          import(
            './section-Workflow/monitoring_workflow/MonitoringWorkflow.component'
          )
      ),
    },
    {
      nested: true,
      path: '/workflow-history',
      component: lazy(
        () =>
          import(
            './section-Workflow/workflow_history/WorkflowHistory.component'
          )
      ),
    },
  ],
  centralRiskRoutes: [
    // Central Risks section
    {
      nested: true,
      path: '/centrale-rischi',
      permissionKey: permissionKey['CentraleRischi.View'],
      component: lazy(
        () =>
          import('./section-Central_risk/central_risk/CentralRisk.component')
      ),
    },
  ],
  sedaAlignment: [
    // Seda alignment
    {
      nested: true,
      path: '/file-generation',
      permissionKey: permissionKey['SedaAlignment.View'],
      component: lazy(
        () =>
          import(
            './section-Seda_alignment/file_generation/FileGeneration.component'
          )
      ),
    },
  ],
};

export const AdminRoutes = [
  ...adminSectionRoutes.homeRoutes,
  ...adminSectionRoutes.paymentsRoutes,
  ...adminSectionRoutes.accountingRoutes,
  ...adminSectionRoutes.legalCheckRoutes,
  ...adminSectionRoutes.surveyManagementRoutes,
  ...adminSectionRoutes.saasConfigurationRoutes,
  ...adminSectionRoutes.contactManagementRoutes,
  ...adminSectionRoutes.documentManagementRoutes,
  ...adminSectionRoutes.generalSettingsRoutes,
  ...adminSectionRoutes.authorizationRoutes,
  ...adminSectionRoutes.locationInformationRoutes,
  ...adminSectionRoutes.checklistRoutes,
  ...adminSectionRoutes.activitiesManagementRoutes,
  ...adminSectionRoutes.workflowRoutes,
  ...adminSectionRoutes.centralRiskRoutes,
  ...adminSectionRoutes.sedaAlignment,
];
