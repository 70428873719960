import { Checkbox as CustomCheckbox } from 'antd';
import { Typography } from '../../index';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { colorType, typographyType } from '../../../helpers';
import { getValidProps } from '../index';

interface Props {
  value?: boolean;
  label?: string;
  onChange?: (e: CheckboxChangeEvent | boolean) => void;
  required?: boolean;

  [x: string]: any;
}

/*
 * Refactor this component and check props(required,label)
 * */

const Checkbox = ({
  value,
  color,
  onChange,
  label,
  required,
  ...rest
}: Props) => {
  const params = {
    ...rest,
    checked: value,
    onChange: (e: CheckboxChangeEvent) => {
      if (onChange) return onChange(e.target.checked);
    },
  };

  return (
    <CustomCheckbox {...getValidProps(params, ['customColor'])}>
      <Typography customColor={color} variant={typographyType.span}>
        {label}
      </Typography>
      {required && (
        <Typography variant={typographyType.span} colorType={colorType.danger}>
          *
        </Typography>
      )}
    </CustomCheckbox>
  );
};

export default Checkbox;
