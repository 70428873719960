import {
  convertLowercase,
  extractResultsFromQuery,
  useClientApi,
} from '../../../../../helpers';
import { useTranslator } from '@opyn/utils';
import { Checkbox, Row, Col } from 'antd';
import Editor from '../../../../editor';
import styled from 'styled-components';

const CustomRow: any = styled(Row)`
  max-height: 260px;
  padding-top: 8px;
  margin: 5px;
`;

interface Props {
  value?: any;
  column?: any;
  optionsUrl?: string;
  optionValue?: any;
  optionLabel?: any;
  selectionType?: string;
  useInsertAllOptions?: boolean;

  [x: string]: any;
}

const Selection = (props: Props) => {
  const translator = useTranslator();

  const {
    value,
    column,
    search,
    setSearch,
    optionsUrl,
    isSearchable = false,
    optionValue = 'id',
    optionLabel = 'name',
    selectionType = 'multiple',
    ...rest
  } = props;

  const options = useClientApi({
    url: optionsUrl,
    fetchOnMount: !!optionsUrl,
    initialData: optionsUrl
      ? []
      : rest.options.map((option: any) => ({
          [optionValue]: option.id,
          [optionLabel]: translator.translate(option.translateId),
        })),
  });

  const onChange = (e: any) => {
    const current = e.target.name;

    let previousValues = value ? [...value] : [];

    if (previousValues.includes(current))
      previousValues = previousValues.filter(
        (option: any) => option !== current
      );
    else previousValues.push(current);

    if (selectionType === 'single')
      previousValues = previousValues.filter((v: any) => v === current);

    if (rest.onChange) rest.onChange(previousValues);
  };

  if (!options.data) return null;

  let results = extractResultsFromQuery(options.data);

  if (search !== '') {
    results = results.filter((record: any) =>
      convertLowercase(record[optionLabel])?.includes(convertLowercase(search))
    );
  }

  const showSearch =
    search !== '' ||
    (results.length > 7 && search === '') ||
    (isSearchable && search === '');

  let children = results;

  if (children && props?.useInsertAllOptions)
    children = [
      ...children,
      {
        [optionValue]: '',
        name: translator.translate('all.label'),
      },
    ];

  return (
    <Row gutter={[12, 12]}>
      <Col xs={24}>
        {showSearch && (
          <Editor
            type="string"
            value={search}
            onChange={({ value }: any) => setSearch(value)}
            placeholder={translator.translate('search.label')}
          />
        )}
      </Col>

      <CustomRow style={{ overflowY: children.length > 4 ? 'scroll' : '' }}>
        {children.map((option: any, index: number) => {
          const key = option[optionValue];
          const checked = Array.isArray(value)
            ? value.includes(key)
            : value === key;

          return (
            <Col key={index} xs={24}>
              <Checkbox name={key} checked={checked} onChange={onChange}>
                {option[optionLabel]}
              </Checkbox>
            </Col>
          );
        })}
      </CustomRow>
    </Row>
  );
};

export default Selection;
