const env = import.meta.env;
export const COOKIEBOT_GLOBAL_VAR = 'Cookiebot';
export const INDIGO_SCRIPT_ID = 'opyn-indigo-script';
export const INDIGO_GLOBAL_VAR = 'IndigoAIChat';

const getDomainWithoutSubdomain = (url: string) => {
  const urlParts = url.split('.');

  return urlParts
    .slice(0)
    .slice(-(urlParts.length === 4 ? 3 : 2))
    .join('.');
};

const prod = {
  apiUrl: `https://emporium-backend.${getDomainWithoutSubdomain(location.host)}/api`,
  workflowUrl: `https://emporium-workflow.${getDomainWithoutSubdomain(location.host)}`,
  indigoToken: {
    en: 'b3adebd5-dc4c-49a8-89b8-f026d88e7536',
    it: 'b6d49989-88a7-4d52-a235-757cf34d6c45',
  },
};

const dev = {
  apiUrl: `https://emporium-backend.dev.opyn.eu/api`,
  workflowUrl: `https://emporium-workflow.dev.opyn.eu`,
  indigoToken: {
    en: '3dba628c-4786-46dd-ab09-9acb0f4a6b04',
    it: 'cf3e95cb-7e8e-4074-ab5e-b5981a13a671',
  },
};

const IS_DEV_ENV = env.MODE === 'development';

const SERVER_BASE_URL = IS_DEV_ENV ? dev.apiUrl : prod.apiUrl;
const WORKFLOW_BASE_URL = IS_DEV_ENV ? dev.workflowUrl : prod.workflowUrl;

const INDIGO_TOKEN = (lang: string) => {
  const ENV = GET_ENV_NAME();
  if (ENV === 'production') {
    return lang.toLowerCase() === 'it'
      ? prod.indigoToken['it']
      : prod.indigoToken['en'];
  } else {
    return lang.toLowerCase() === 'it'
      ? dev.indigoToken['it']
      : dev.indigoToken['en'];
  }
};

if (!IS_DEV_ENV) {
  console.error = () => {};
  console.warn = () => {};
}

const IS_PRODUCTION =
  !IS_DEV_ENV &&
  !location.host.includes('.dev.') &&
  !location.host.includes('.test.');

const GET_ENV_NAME = (): string => {
  switch (true) {
    case IS_DEV_ENV:
      return 'local';
    case location.host.includes('.dev.'):
      return 'dev';
    case location.host.includes('.test.'):
      return 'test';
    default:
      return 'production';
  }
};

export {
  SERVER_BASE_URL,
  IS_DEV_ENV,
  WORKFLOW_BASE_URL,
  IS_PRODUCTION,
  GET_ENV_NAME,
  INDIGO_TOKEN,
};
//prod trigger
