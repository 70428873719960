const Facebook = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.3467 7.4267H22.8534V3.1867C21.6397 3.0605 20.4202 2.99819 19.2 3.00004C15.5734 3.00004 13.0934 5.21337 13.0934 9.2667V12.76H9V17.5068H13.0934V29.6667H18V17.5068H22.08L22.6934 12.76H18V9.73337C18 8.33337 18.3734 7.4267 20.3467 7.4267Z"
        fill="#47D7AC"
      />
    </svg>
  );
};

export default Facebook;
