const Success = () => {
  return (
    <svg
      width="51"
      height="51"
      viewBox="0 0 51 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.5 3.1875C13.1783 3.1875 3.1875 13.1783 3.1875 25.5C3.1875 37.8217 13.1783 47.8125 25.5 47.8125C37.8217 47.8125 47.8125 37.8217 47.8125 25.5C47.8125 13.1783 37.8217 3.1875 25.5 3.1875ZM25.5 44.0273C15.2701 44.0273 6.97266 35.7299 6.97266 25.5C6.97266 15.2701 15.2701 6.97266 25.5 6.97266C35.7299 6.97266 44.0273 15.2701 44.0273 25.5C44.0273 35.7299 35.7299 44.0273 25.5 44.0273Z"
        fill="#47D7AC"
      />
      <path
        opacity="0.1"
        d="M25.5 6.97266C15.2701 6.97266 6.97266 15.2701 6.97266 25.5C6.97266 35.7299 15.2701 44.0273 25.5 44.0273C35.7299 44.0273 44.0273 35.7299 44.0273 25.5C44.0273 15.2701 35.7299 6.97266 25.5 6.97266ZM35.1322 18.2136L24.6434 32.7565C24.4968 32.9612 24.3035 33.1279 24.0796 33.2429C23.8557 33.3579 23.6076 33.4179 23.3559 33.4179C23.1042 33.4179 22.8561 33.3579 22.6322 33.2429C22.4083 33.1279 22.2151 32.9612 22.0685 32.7565L15.8628 24.1503C15.6735 23.8863 15.8628 23.5178 16.1865 23.5178H18.5224C19.0354 23.5178 19.5135 23.7668 19.8123 24.1802L23.3584 29.1009L31.1877 18.2435C31.4865 17.8251 31.9696 17.5811 32.4776 17.5811H34.8135C35.1372 17.5811 35.3265 17.9496 35.1322 18.2136Z"
        fill="#47D7AC"
      />
      <path
        d="M34.8117 17.5811H32.4759C31.9679 17.5811 31.4848 17.8251 31.1859 18.2435L23.3566 29.1009L19.8105 24.1802C19.5117 23.7668 19.0336 23.5178 18.5206 23.5178H16.1847C15.861 23.5178 15.6717 23.8863 15.861 24.1503L22.0667 32.7565C22.2133 32.9612 22.4065 33.1279 22.6304 33.2429C22.8543 33.3579 23.1024 33.4179 23.3541 33.4179C23.6058 33.4179 23.8539 33.3579 24.0778 33.2429C24.3017 33.1279 24.495 32.9612 24.6416 32.7565L35.1305 18.2136C35.3247 17.9496 35.1355 17.5811 34.8117 17.5811Z"
        fill="#47D7AC"
      />
    </svg>
  );
};

export default Success;
