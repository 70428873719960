export const types = {
  string: 'string',
  number: 'number',
};

export const componentType = {
  date: 'date',
  bool: 'bool',
  radio: 'radio',
  number: 'number',
  currency: 'currency',
  string: 'string',
  selection: 'selection',
  checkbox: 'checkbox',
  addOnSelection: 'addOnSelection',
  ckEditor: 'ckEditor',
};

export const initialState: any = {
  [componentType.date]: null,
  [componentType.bool]: false,
  [componentType.number]: null,
  [componentType.string]: null,
  [componentType.selection]: null,
  [componentType.checkbox]: null,
  [componentType.addOnSelection]: null,
};
