import queryString from 'query-string';

export interface anyObject {
  [key: string]: any;

  [key: number]: any;
}

export const prepareQueryParams = (filters: anyObject) => {
  return queryString.stringify(filters, {
    skipNull: true,
  });
};

export const withQuery = (url: string, filters: anyObject) => {
  if (!filters || !Object.keys(filters).length) return url;

  let query = prepareQueryParams(filters);

  if (!url.includes('?')) query = '?' + query;

  return url + query;
};

export const withPageSize = (url: string, pageSize = 1000) => {
  return withQuery(url, {
    pageSize: pageSize,
  });
};

export const isValidUrl = (urlString: string) => {
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)?(www\\.google\\.com\\/maps\\/(?:uv|place|dir)\\/?.*|www\\.[a-z0-9\\-\\.]+\\/?.*)$',
    'i'
  );
  return urlPattern.test(urlString);
};
