import React from 'react';
import { componentType, initialState } from '../../constants/editor_types';
import { colorType, typographyType } from '../../helpers';
import CurrencyInput from '../currency_input';
import AddOnSelection from './add_on_selection';
import Selection from './selection';
import { Typography } from '../index';
import Checkbox from './checkbox';
import DateSelector from './date';
import Switch from './switch';
import Input from './input';
import Radio from './radio';
import { Row, Col } from 'antd';
import CkEditor from './ck_editor';

type Components = {
  [key in string]: React.ComponentType<any>;
};

export const getValidProps = (
  object: any,
  arrayToRemove: string[]
): string[] => {
  return Object.keys(object)?.reduce((obj: any, key) => {
    if (!arrayToRemove.includes(key)) {
      obj[key] = object[key];
    }
    return obj;
  }, []);
};

const components: Components = {
  [componentType.selection]: Selection,
  [componentType.bool]: Switch,
  [componentType.currency]: CurrencyInput,
  [componentType.number]: Input,
  [componentType.string]: Input,
  [componentType.radio]: Radio,
  [componentType.date]: DateSelector,
  [componentType.checkbox]: Checkbox,
  [componentType.addOnSelection]: AddOnSelection,
  [componentType.ckEditor]: CkEditor,
};

export interface Props {
  type?: string;
  value?: unknown;
  label?: string | JSX.Element;
  required?: boolean;
  readOnly?: boolean;
  noLabel?: boolean;
  onChange?: any;
  direction?: string;
  placeholder?: string;

  [x: string]: any;
}

const Editor = ({
  value,
  placeholder,
  label,
  required,
  direction,
  type,
  onChange,
  onBlur,
  readOnly = false,
  noLabel = false,
  ...rest
}: Props) => {
  if (!type || !Object.prototype.hasOwnProperty.call(components, type))
    return null;

  const Component: React.ComponentType<any> = components[type];

  /*
   * Make use of Frame on this component
   * */
  return (
    <Row gutter={[4, 4]}>
      {!noLabel && (
        <Col xs={24}>
          {type === 'checkbox' ? null : (
            <>
              {label && (
                <Row gutter={8}>
                  <Col>
                    <Typography
                      customColor={rest.customColor}
                      colorType={
                        rest.error ? colorType.danger : colorType.primary
                      }
                      variant={typographyType.span}
                    >
                      {label}
                    </Typography>
                  </Col>
                  <Col xs={4}>
                    {required && (
                      <Typography
                        variant={typographyType.span}
                        colorType={colorType.danger}
                      >
                        *
                      </Typography>
                    )}
                  </Col>
                </Row>
              )}
            </>
          )}
        </Col>
      )}
      <Col xs={24}>
        <Component
          {...rest}
          type={type}
          label={label}
          value={value}
          required={required}
          onBlur={() => {
            if (!onBlur) return;
            onBlur(rest);
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (!onChange || readOnly) return;
            onChange({
              ...rest,
              value: event,
            });
          }}
          color={rest.customColor}
          placeholder={placeholder}
          disabled={rest.disabled || readOnly}
          status={rest.error ? 'error' : ''}
        />
        {rest.error && (
          <Typography
            variant={typographyType.span}
            colorType={colorType.danger}
          >
            {rest.error}
          </Typography>
        )}
      </Col>
    </Row>
  );
};

Editor.type = componentType;
Editor.initialState = initialState;

export default Editor;
