import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { commonDirectReducer } from '../../constants';
import { Auth, AuthStateModel, LoginRequestBody } from './auth.types';
import { login, logout, refreshToken } from '../../services/auth.service';
import { AuthStorage } from '../../../helpers';
import { permissionsReset } from '../permissions/permissionsSlice';

const name = 'auth';
const typePrefix = 'auth';

const initialState: AuthStateModel = {
  ...commonDirectReducer,
  auth: {},
};

 
export const authLogin = createAsyncThunk<any, LoginRequestBody>(
  `${typePrefix}/login`,
  async (body: LoginRequestBody, thunkAPI) => {
    try {
      return await login(body);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

 
export const authLogout = createAsyncThunk<any>(
  `${typePrefix}/logout`,
  async (_, thunkAPI) => {
    try {
      const response = await logout();
      thunkAPI.dispatch(permissionsReset());
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

 
export const authRefreshToken = createAsyncThunk<any>(
  `${typePrefix}/refreshToken`,
  async (_, thunkAPI) => {
    try {
      return await refreshToken({
        refreshToken: AuthStorage.getRefreshToken() || null,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const authSlice = createSlice({
  name,
  initialState,
  reducers: {
    authReset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        authLogin.fulfilled,
        (state: AuthStateModel, action: PayloadAction<Auth['auth']>) => {
          state.isSuccess = true;
          state.auth = action.payload;
        }
      )
      .addCase(authLogin.rejected, (state: AuthStateModel) => {
        state.isError = true;
        state.auth = {};
      })
      .addCase(authLogout.fulfilled, (state: AuthStateModel) => {
        state.isSuccess = true;
        state.auth = {};
      })
      .addCase(authLogout.rejected, (state: AuthStateModel) => {
        state.isError = true;
        state.auth = {};
      })
      .addCase(
        authRefreshToken.fulfilled,
        (state: AuthStateModel, action: PayloadAction<{ token: string }>) => {
          AuthStorage.setAccessToken(action.payload.token);
          state.auth.refreshToken = action.payload.token;
        }
      )
      .addCase(authRefreshToken.rejected, (state: AuthStateModel) => {
        state.isError = true;
        state.auth = {};
      });
  },
});

export const { authReset } = authSlice.actions;
export default authSlice;
