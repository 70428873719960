export const TENANT_NAME = {
  VALSABBINA: 'valsabbina',
  AZIMUT: 'azimut',
};

export const extractTenantFromURL: any = () => {
  const [tenantName] = window.location.pathname
    .split('/')
    .filter((p) => p !== '')
    .map((p) => p.toLowerCase());
  return tenantName;
};

const tenantName = extractTenantFromURL();

export const isValsabbina = tenantName === TENANT_NAME.VALSABBINA;
export const isAzimut = tenantName === TENANT_NAME.AZIMUT;

export const customColor = !isValsabbina ? '#003B5C' : '';
