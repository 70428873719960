import styled from 'styled-components';
import { colorType, extractColor } from '../../helpers';

interface Props {
  $status?: string;
}

export const StyleIcon = styled.span<Props>`
  height: 10px;
  width: 10px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 3px;
  background-color: ${(props: Props) =>
    props.$status
      ? extractColor(colorType.secondary)
      : extractColor(colorType.danger)};
`;
