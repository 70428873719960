import styled from 'styled-components';
import { useTranslator } from '@opyn/utils';
import { colorType, extractColor } from '../../../../../../helpers';

interface WrapperProps {
  className: string;
}

const Wrapper = styled.div.attrs<WrapperProps>(({ className }) => ({
  className: className,
}))`
  margin: ${(props) => {
    if (props.className === '') {
      return '10px 0';
    }
  }};
  text-align: left;
  color: ${extractColor(colorType.primary, 1)};

  b {
    color: ${extractColor(colorType.primary, 3)};
  }

  span {
    color: ${extractColor(colorType.danger, 3)};
    font-weight: bold;
  }
`;

interface Props {
  disclaimer?: boolean;
  className?: string;
}

const Required = ({ disclaimer, className = '' }: Props) => {
  const translator = useTranslator();

  return (
    <Wrapper className={className}>
      {translator.translate('markedFields.label')}
      <span>*</span>
      {translator.translate('means.label')}
      {disclaimer && (
        <>
          <br />
          {translator.translate('byClicking.label')}
          <b
            onClick={() => {
              window.open(
                'https://www.opyn.eu/hubfs/Documenti/Trasparenza/Condizioni_Generali_Piattaforma_P2P.pdf'
              );
            }}
          >
            {translator.translate('termsOfUse.label')}
          </b>
          <b
            onClick={() => {
              window.open(
                'https://www.opyn.eu/hubfs/Documenti/Trasparenza/20151209_Informativa_SIC.pdf'
              );
            }}
          >
            {translator.translate('sicInformation.label')}
          </b>
        </>
      )}
    </Wrapper>
  );
};

export default Required;
