import styled from 'styled-components';
import { colorType, extractColor } from '../../../../helpers';

export const Styled = styled.div`
  .ant-layout {
    display: flex;
    min-height: 100vh;
  }

  .header {
    background: ${extractColor(colorType.light)};
    box-shadow: 0 4px 4px rgb(0 0 0 / 15%);
    display: flex;

    img {
      top: 10px;
    }
  }

  .ant-layout-content {
    flex: 1;
    display: flex;
    position: relative;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 144px);
  }

  .bottom {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .logo {
    top: 25px;
    left: 30px;
    position: absolute;
  }

  .logo-background-image {
    @keyframes rotate {
      to {
        transform: rotate(360deg);
      }
    }
    pointer-events: none;
    position: absolute;
    right: -80px;
    bottom: -280px;
    animation: rotate 50s linear infinite;
    height: 580px;
    width: 580px;
    path {
      fill: #47d7ac;
    }
  }
`;
