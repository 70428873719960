import numbro from 'numbro';
import dayjs from 'dayjs';

export function isNumber(value: unknown) {
  return value !== null && !isNaN(Number(value));
}

export function cleanUpValue(value: unknown): number | null {
  if (value === null || value === undefined) {
    return null;
  }
  const stringValue = String(value).replace(',', '.');
  const numericValue = Number(stringValue);

  if (Number.isNaN(numericValue) || !Number.isFinite(numericValue)) {
    return null;
  }
  return numericValue;
}

export function formatPercent(value: unknown, precision = 2) {
  value = cleanUpValue(value);
  return (
    isNumber(value)
      ? numbro(value).format({ output: 'percent', mantissa: precision })
      : value
  ) as string;
}

export function formatTableCellValue(
  value: number | string,
  formatter?: (value: number) => JSX.Element | string | number
) {
  if (value === null) return '-';
  else if (value === 0) return 0;
  else if (value === undefined) return 'N/A';
  else if (typeof value === 'number') {
    return formatter ? formatter(value) : value;
  } else {
    return value;
  }
}

/* Format Currencies */
export function formatCurrency(
  value: string | number,
  type = 'thousands',
  precision = 2
) {
  if (!isNumber(value)) return value;
  const format: {
    thousandSeparated?: boolean;
    mantissa: number;
    average?: boolean;
  } = { mantissa: precision };
  if (type === 'thousands') format.thousandSeparated = true;
  else if (type === 'k') format.average = true;
}

export function formatThousands(value: string | number, precision = 2) {
  return (
    isNumber(value)
      ? numbro(value).format({ thousandSeparated: true, mantissa: precision })
      : value
  ) as string;
}

export const formatThousandsLanguage = (
  value: string | number,
  language: string
) => {
  if (value) {
    return language === 'it-IT'
      ? value.toLocaleString('it-IT', {
          style: 'currency',
          currency: 'EUR',
        })
      : value.toLocaleString('en-US', {
          style: 'currency',
          currency: 'EUR',
        });
  } else return value;
};

export function formatDate(date: Date, format = 'L') {
  return date ? dayjs(date).format(format) : null;
}

export function formatDateFullYear(date: Date, format = 'L') {
  return date ? dayjs(date).format(format) : null;
}

export function formatDateLong(date: Date, format = 'LL') {
  return date ? dayjs(date).format(format) : null;
}

export function formatServerDatetime(date: Date) {
  return date ? dayjs(date).format('YYYY-MM-DD HH:mm') : date;
}

export function currencyFormat(value: number) {
  if (!value) return value;
  return value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + ' €';
}

export default {
  default: (value: unknown) => value /*All Date Formatters*/,
  date: (value: unknown) => formatDate(value as Date),
  dateLong: (value: unknown) => formatDateLong(value as Date),
  dateFullYear: (value: unknown) => formatDateFullYear(value as Date),
  currency: (value: unknown) => currencyFormat(value as number),
  thousands: (value: unknown) => formatThousands(value as number),
  percent: formatPercent,
};
