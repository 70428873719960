import styled from 'styled-components';
import { colorType, extractColor } from '../../helpers';
import { isValsabbina } from '../../helpers/tenant';

export default styled.footer<any>`
  background: ${() =>
    isValsabbina ? extractColor(colorType.gradientBlue) : '#003B5C'} !important;
  padding: 10px 20px;

  button {
    padding: 0 10px;
    background-color: ${extractColor(colorType.secondary, 3)};
    color: ${extractColor(colorType.primary, 3)};
    border: 1px solid ${extractColor(colorType.primary, 3)};
  }

  img {
    position: unset !important;
  }

  a,
  svg {
    font-size: 14px;
    color: ${extractColor(colorType.light)};
    &:hover {
      color: ${extractColor(colorType.lightGray)};
    }
    &.active {
      font-weight: 600;
    }
  }

  svg {
    font-size: 22px !important;
  }
`;
