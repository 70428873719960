import { notification } from 'antd';
import { i18n } from './translation';

export const displayError = (
  description = i18n.t('tryAgain.label'),
  message = i18n.t('error.label')
) => {
  if (description)
    notification.error({ message, description, showProgress: true });
};

export const displayWarning = (
  description: string,
  message = i18n.t('warning.label')
) => {
  notification.warning({
    message,
    description,
    duration: 4,
    showProgress: true,
  });
};

export const displaySuccess = (
  description: string,
  message = i18n.t('success.label')
) => {
  notification.success({
    message,
    description,
    duration: 5,
    showProgress: true,
  });
};
