import { useEffect, useRef, useState } from 'react';
import queryString from 'query-string';
import { displayWarning } from '../../message';
import { i18n } from '../../translation';
import { useDidUpdate } from '@opyn/utils';

interface Props {
  url?: any;
  deps?: any;
  query?: any;
  client?: any;
  baseURL?: any;
  headers?: any;
  onError?: any;
  onSuccess?: any;
  controller?: any;
  initialData?: any;
  formatQuery?: any;
  fetchOnMount?: any;
  notification?: any;
  errorMessage?: any;
  successMessage?: any;
  displayMessages?: any;
  onPrepareResponse?: any;

  [x: string]: any;
}

const createUseClientApi =
  ({ client, notification, baseURL }: Props) =>
  ({
    url,
    headers = {},
    onError,
    onSuccess,
    deps = [],
    formatQuery,
    errorMessage,
    successMessage,
    onPrepareResponse,
    query: initialQuery,
    initialData: initialData,
    fetchOnMount = true,
    displayMessages = false,
    ...rest
  }: Props) => {
    const ref = useRef({ mounted: true });
    const [query, setQuery] = useState<any>(initialQuery);
    const [data, setData] = useState<any>(initialData);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      if (fetchOnMount) getData();
      return () => {
        ref.current.mounted = false;
      };
    }, []);

    useDidUpdate(() => {
      if (!deps.length) return;

      getData();
    }, deps);

    useDidUpdate(() => {
      getData();
    }, [query]);

    const displaySuccessMessage = () => {
      if (!successMessage) return;

      notification.success(successMessage);
    };

    const displayErrorMessage = () => {
      if (!errorMessage) return;

      notification.error(errorMessage);
    };

    const getData = async (params: any = {}) => {
      let endpoint = params.url || url;

      const serverUrl = rest.baseURL || baseURL;

      if (!endpoint || !ref.current.mounted) return;

      setLoading(true);

      if (typeof endpoint === 'function') endpoint = endpoint(deps);

      if (query) {
        const urlQuery = formatQuery ? formatQuery(query) : query;
        const stringifyQuery = queryString.stringify(urlQuery);
        const appendix = endpoint.includes('?') ? '&' : '?';
        endpoint = endpoint + appendix + stringifyQuery;
      }

      const options: any = {
        method: 'get',
        url: endpoint,
        headers: {
          ...headers,
        },
      };

      if (serverUrl) options.baseURL = serverUrl;

      const errorMessagesToNotShowNotif = [
        'Exceptions.BadRequestException.CribisHasObsoleteInformation',
        'Exceptions.BadRequestException.MoreThanOnePersonalGuarantee',
        'Exceptions.BadRequestException.TooManyInArrearsInstallments',
      ];

      try {
        let response = await client(options);
        if (onPrepareResponse) response = onPrepareResponse(response);
        setData(response);
        if (displayMessages) displaySuccessMessage();
        if (onSuccess) onSuccess(response);
        return response;
      } catch (e: any) {
        if (!client.isCancel(e)) {
          if (e.response) {
            const status = e.response.status;
            if (status === 403)
              displayWarning(i18n.t('noPermissionsToView.label'));
            else {
              if (
                errorMessagesToNotShowNotif.includes(e.response.data.message)
              ) {
              } else {
                displayWarning(e.response.data.message);
              }
            }
          } else if (e.request) {
            console.log(e.request);
          } else {
            displayWarning(e.message);
          }

          if (onError) onError(e);

          if (displayMessages) displayErrorMessage();
        }

        throw e;
      } finally {
        setLoading(false);
      }
    };

    const reset = () => {
      setData(null);
      setLoading(false);
    };

    const cancel = () => {};

    const updateQuery = (updates: any) => {
      setQuery((prevState: any) => {
        return {
          ...prevState,
          ...updates,
        };
      });
    };

    const replaceQuery = (newQuery: any) => {
      setQuery(newQuery);
    };

    const value: object = {
      loading,
      data,
      setData,
      reset,
      cancel,
      query,
      setQuery,
      updateQuery,
      fetch: getData,
      replaceQuery,
    };

    return value;
  };

export default createUseClientApi;
