import React from 'react';
import { useDebounce } from '../use_debounce';
import * as Yup from 'yup';

interface FormErrors {
  [key: string]: string;
}

 
type InputValues = any;

 
interface FormValues {
  [key: string]: any;
}

interface FormChangeEvent {
  name: string;
  value: InputValues;
}

type ValidationSchema = Yup.Schema<FormValues>;

type ChangeHandler = (event: FormChangeEvent) => void;

interface SmartFormHookResult {
  values: FormValues;
  errors: FormErrors;
  handleSubmit: (event: any) => void;
  handleChange: ChangeHandler;
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  setMultiValues: (values: FormValues) => void;
  setMultiErrors: (errors: FormErrors) => void;
  resetMultiValues: (newValues: FormValues) => void;
  setValue: (name: string, value: InputValues) => void;
  getValue: (name: string) => InputValues;
  setError: (name: string, value: string) => void;
  register: (name: string) => {
    name: string;
    value: InputValues;
    error: string;
    onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
    onChange: ChangeHandler;
  };
  isSubmitting: boolean;
  isValid: boolean;
}

const serializeErrors = (
  err: Yup.ValidationError | any,
  touchedFields?: { [key: string]: boolean }
): FormErrors => {
  if (!('inner' in err)) return {};

  return err.inner.reduce((acc: FormErrors, val: Yup.ValidationError) => {
    const fieldName = val.path as string;

    if (touchedFields) {
      if (fieldName && touchedFields[fieldName]) acc[fieldName] = val.message;
    } else {
      if (fieldName) acc[fieldName] = val.message;
    }

    return acc;
  }, {});
};

const useSmartForm = ({
  initialValues,
  onFinish,
  validationSchema,
}: {
  initialValues: FormValues | (() => FormValues);
  onFinish?: any;
  validationSchema: ValidationSchema;
}): SmartFormHookResult => {
  const debounce = useDebounce();
  const [values, setValues] = React.useState<FormValues>(() => {
    if (typeof initialValues === 'function') return initialValues();
    return initialValues;
  });
  const [errors, setErrors] = React.useState<FormErrors>({});
  const [isValid, setIsValid] = React.useState(false);
  const [touched, setTouched] = React.useState<{ [key: string]: boolean }>({});
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const handleChange = (event: any) => {
    const { name, value } = event;
    setValues((prevValues) => {
      const newValues = { ...prevValues, [name]: value };
      // Keep the timeout high. This is to prevent the validation from running too often and also to solve the validation after and change (datepicker)
      debounce(() => validateField({ name: name, values: newValues }), 350);
      return newValues;
    });
  };

  const validateField = (event: any) => {
    if (!touched[event.name]) {
      setTouched((prevValues) => ({
        ...prevValues,
        [event.name]: true,
      }));
    }

    const touchedFields = {
      ...touched,
      [event.name]: true,
    };

    validationSchema
      .validate(event.values || values, { abortEarly: false })
      .then(() => {
        setErrors({});
        setIsValid(true);
      })
      .catch((err: any) => {
        const validationErrors = serializeErrors(err, touchedFields);
        setErrors(validationErrors);
        setIsValid(false);
      });
  };

  const handleBlur = (event: any) => {
    validateField(event);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      await validationSchema.validate(values, { abortEarly: false });

      setIsSubmitting(true);
      await onFinish(values);

      setIsSubmitting(false);
    } catch (err) {
      setIsSubmitting(false);

      const validationErrors = serializeErrors(err);

      setErrors(validationErrors);

      const touchedFields: { [key: string]: boolean } = {};

      Object.keys(initialValues).forEach(
        (item) => (touchedFields[item] = true)
      );

      setTouched(touchedFields);
    }
  };

  const register = (name: any) => {
    return {
      name: name,
      value: values[name],
      error: errors[name],
      onBlur: handleBlur,
      onChange: handleChange,
    };
  };

  const setMultiValues = React.useCallback((values: FormValues) => {
    setValues((prevValues) => ({
      ...prevValues,
      ...values,
    }));
  }, []);

  const resetMultiValues = React.useCallback(
    (newValues: FormValues) => {
      setValues((prevValues) => {
        const updatedValues = {
          ...prevValues,
          ...newValues,
        };

        // Reset touched fields only for the keys in newValues
        const updatedTouched = Object.keys(newValues).reduce<{
          [key: string]: boolean;
        }>((acc, key) => {
          acc[key] = false;
          return acc;
        }, {});

        setTouched((prevTouched) => ({
          ...prevTouched,
          ...updatedTouched,
        }));

        // Trigger validation with the new values
        validationSchema
          .validate(updatedValues, { abortEarly: false })
          .then(() => {
            setErrors({});
            setIsValid(true);
          })
          .catch((err) => {
            const validationErrors = serializeErrors(err, {});
            setErrors(validationErrors);
            setIsValid(false);
          });

        return updatedValues;
      });
    },
    [validationSchema]
  );

  const setMultiErrors = React.useCallback((errors: any) => {
    const touchedFields = Object.keys(errors).map((item) => ({ [item]: true }));

    setTouched((prevValues: any) => ({
      ...prevValues,
      ...touchedFields,
    }));

    setErrors((prevValues) => ({
      ...prevValues,
      ...errors,
    }));
  }, []);

  const setValue = React.useCallback((name: string, value: unknown) => {
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);

  const getValue = React.useCallback(
    (name: string) => {
      return values[name];
    },
    [values]
  );

  const setError = React.useCallback((name: string, value: string) => {
    setTouched((prevValues) => ({
      ...prevValues,
      [name]: true,
    }));

    setErrors((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);

  return {
    values,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    setMultiValues,
    setMultiErrors,
    resetMultiValues,
    setValue,
    getValue,
    setError,
    register,
    isSubmitting,
    isValid,
  };
};

export default useSmartForm;
