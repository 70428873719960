import { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { produce } from 'immer';
import { Button, Empty } from 'antd';
import Table from './styles';
import styled from 'styled-components';
import Editor from '../../editor';
import Typography from '../../typography';
import { useTranslator } from '@opyn/utils';
import { typographyType } from '../../../helpers';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

const CustomButton = styled(Button)`
  float: right;
  margin-top: 8px;
`;

interface Props {
  data: any;
  fields: any;
  language: any;
  localizationKey: any;
}

const useCrud = ({ fields, data, language, localizationKey }: Props) => {
  const [state, setState] = useState<any>([]);

  useEffect(() => {
    if (!state.length) onCreate();
  }, []);

  useEffect(() => {
    if (data) setState(data);
  }, [data]);

  const onReset = () => {
    setState([]);
  };

  const onCreate = () => {
    const record: any = {
      [localizationKey]: [],
    };

    fields.forEach((field: any) => {
      if (!field.multiLanguage) record[field.key] = null;
    });

    setState((prevState: any) => {
      return [...prevState, record];
    });
  };

  const onRemove = (index: any) => {
    setState((prevState: any) => {
      return prevState.filter((_: any, i: number) => index !== i);
    });
  };

  const onChange = (config: any) => {
    const { value, attr, index } = config;

    const field = fields.find((field: any) => field.key === attr);

    setState(
      produce((draft: any) => {
        const record = draft[index];

        if (field.multiLanguage) {
          const languages = record[localizationKey];
          const languageRecord = languages.find(
            (item: any) =>
              Object.prototype.hasOwnProperty.call(item, attr) &&
              item.cultureId === language
          );

          if (languageRecord) {
            languageRecord[field.key] = value;
          } else {
            languages.push({ [field.key]: value, cultureId: language });
          }
        } else {
          record[field.key] = value;
        }
      })
    );
  };

  return {
    state,
    setState,
    onRemove,
    onCreate,
    onChange,
    onReset,
  };
};

const fn = () => {};

const Crud = (props: any, ref: any) => {
  const translator = useTranslator();

  const {
    data,
    fields,
    language,
    readOnly = true,
    localizationKey = 'localizations',
  } = props;

  const { state, onCreate, onRemove, onChange, onReset, setState } = useCrud({
    data,
    fields,
    language,
    localizationKey,
  });

  useImperativeHandle(ref, () => ({
    getState: () => {
      return state;
    },
    resetState: () => {
      onReset();
    },
    setState: (initialState: any) => {
      setState(initialState);
    },
  }));

  useEffect(() => {
    if (props.onStateChange) {
      props.onStateChange(state);
    }
  }, [state]);

  return (
    <Table>
      <thead>
        <tr>
          {fields.map((field: any, index: number) => (
            <th key={`${field.id}-${index}`} className="text-left">
              {translator.translate(
                field.translateId || field.labelTranslateId
              )}
            </th>
          ))}
          {!readOnly && <th />}
        </tr>
      </thead>
      <tbody>
        {state.length ? (
          state.map((record: any, index: number) => (
            <tr key={`${record.id}-${index}`}>
              {fields.map((field: any) => {
                let value = field.multiLanguage
                  ? record[localizationKey]
                  : record[field.key];

                let content;
                if (readOnly) {
                  if (field.render) value = field.render(value);

                  if (value !== null) {
                    content = (
                      <Typography level={2} variant={typographyType.h3}>
                        {typeof value === 'string' ? value : String(value)}
                      </Typography>
                    );
                  }
                } else {
                  const { labelTranslateId, ...rest } = field;
                  content = (
                    <Editor
                      index={index}
                      value={value}
                      attr={field.key}
                      language={language}
                      placeholder={`${translator.translate(
                        'insert.label'
                      )} ${translator.translate(labelTranslateId)}`}
                      onChange={readOnly ? fn : onChange}
                      style={readOnly ? { pointerEvents: 'none' } : {}}
                      {...rest}
                    />
                  );
                }

                return (
                  <td
                    key={`${record.id}-${index}-${field.key}`}
                    className="text-left"
                  >
                    {content}
                  </td>
                );
              })}
              {!readOnly && (
                <td>
                  <Button
                    danger
                    icon={<DeleteOutlined />}
                    className="float-right"
                    onClick={() => onRemove(index)}
                  >
                    {translator.translate('delete.label')}
                  </Button>
                </td>
              )}
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={fields.length + 1}>
              <Empty
                description={translator.translate('noData.label')}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            </td>
          </tr>
        )}
        {!readOnly && (
          <tr>
            <td colSpan={fields.length + 1}>
              <CustomButton
                ghost
                type="primary"
                onClick={onCreate}
                icon={<PlusOutlined />}
              >
                {translator.translate('add.label')}{' '}
                {translator.translate('answerLowerCase.label')}
              </CustomButton>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default forwardRef(Crud);
