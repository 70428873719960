import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { colorType, extractColor } from '../../helpers';

interface StackDivProps {
  $color?: string;
  height?: string;
  $distance?: string;
}

const StackDiv = styled.div.attrs<StackDivProps>(({ $color, height }) => ({
  $color: $color,
  height: height || '90%',
}))`
  height: ${(props) => props.height};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const FirstStackDiv = styled.div<{ $isLast?: boolean }>`
  gap: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 2px;
    height: 100%;
    background-color: #4f6b83;
    ${({ $isLast }) =>
      $isLast &&
      `
      height: 50%;
    `}
  }
`;

const SecondStackDiv = styled.div.attrs<StackDivProps>(
  ({ $color, $distance }) => ({
    $distance: $distance || '12px',
    $color: $color || extractColor(colorType.primary, 2),
  })
)`
  flex-basis: ${(props) => props.$distance};
  border-bottom: 2px solid ${(props) => props.$color};
`;

const ThirdStackDiv = styled.div`
  width: 92.5%;
`;

interface Props {
  style?: React.CSSProperties;
  color?: string;
  height?: number;
  children?: ReactNode;
  distance?: string;
  className?: string;
}

const Stack = ({ children, style, className }: Props) => {
  return (
    <StackDiv style={{ ...style }} className={className}>
      {React.Children.toArray(children).map(
        (child: ReactNode, index: number) => (
          <React.Fragment key={index}>
            <FirstStackDiv
              $isLast={index === React.Children.toArray(children).length - 1}
            >
              <SecondStackDiv />
              <ThirdStackDiv>{child}</ThirdStackDiv>
            </FirstStackDiv>
          </React.Fragment>
        )
      )}
    </StackDiv>
  );
};

export default Stack;
