import { NumericFormat } from 'react-number-format';
import { NumberFormatValues } from 'react-number-format/types/types';
import { useLanguage, useTranslator } from '@opyn/utils';
import { cleanUpValue, LanguageType } from '../../helpers';
import { theme } from 'antd';
import { getValidProps } from '../editor';

interface CustomInputProps {
  placeholder?: string;
}

interface Props {
  value?: number;
  symbol?: string;
  decimalScale?: number;
  fixedDecimalScale?: boolean;
  displayType?: 'input' | 'text';
  formatType?: 'currency' | 'percentage';
  onChange?: (value: number | undefined) => void;
  status?: string;
  placeholder?: string;
  rest?: object;
}

const separators = {
  [LanguageType.it]: {
    decimal: ',',
    thousands: '.',
  },
  [LanguageType.us]: {
    decimal: '.',
    thousands: ',',
  },
};

const CurrencyInput = ({
  value,
  symbol = ' €',
  decimalScale = 2,
  fixedDecimalScale = false,
  displayType = 'input',
  formatType = 'currency',
  onChange,
  status,
  placeholder,
  ...rest
}: Props) => {
  const { hashId } = theme.useToken();
  const language = useLanguage();
  const translator = useTranslator();
  const separator = separators[language];

  const suffix = formatType === 'currency' ? symbol : '%';
  const decimalSeparator = separator.decimal;
  const thousandSeparator =
    formatType === 'currency' ? separator.thousands : '';

  return (
    <NumericFormat
      {...getValidProps(rest, ['customColor'])}
      value={cleanUpValue(value)}
      suffix={suffix}
      className={`${displayType === 'text' ? '' : 'ant-input ant-input-outlined '}${hashId} ${
        String(status) === 'error' ? 'ant-input-status-error' : ''
      }`}
      onValueChange={(values: NumberFormatValues) => {
        if (onChange) onChange(values.floatValue);
      }}
      decimalScale={decimalScale}
      fixedDecimalScale={fixedDecimalScale}
      decimalSeparator={decimalSeparator}
      thousandSeparator={thousandSeparator}
      displayType={displayType}
      placeholder={
        placeholder ? placeholder : translator.translate('enterValue.label')
      }
    />
  );
};

const ReadOnly = (props: CustomInputProps) => (
  <CurrencyInput {...props} displayType="text" />
);

CurrencyInput.ReadOnly = ReadOnly;

export default CurrencyInput;
