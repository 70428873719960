export const colorType = {
  link: 'link',
  blue: 'blue',
  light: 'light',
  lightGray: 'lightGray',
  primary: 'primary',
  gradientBlue: 'gradientBlue',
  darkGray: 'darkGray',
  secondary: 'secondary',
  danger: 'danger',
  warning: 'warning',
  success: 'success',
  background: 'background',
  backgroundColor: 'backgroundColor',
};

export const ColorfulTags = {
  blue: {
    border: '#4F6B83',
    background: '#F8F9FA',
    label: '#003B5C',
  },
  red: {
    border: '#FF5050',
    background: '#FFF3F3',
    label: '#F13D3E',
  },
  green: {
    border: '#40B690',
    background: '#F2FAF8',
    label: '#009E6B',
  },
  secondary: {
    border: '#A3EBD5',
    background: '#F1F9F8',
    label: '#47D7AC',
  },
  warningYellow: {
    border: '#FFCE6D',
    background: '#FFCE6D',
    label: '#003B5C',
  },
  successGreen: {
    border: '#40B690',
    background: '#40B690',
    label: '#ffffff',
  },
  defaultGrey: {
    border: '#EAEDF0',
    background: '#EAEDF0',
    label: '#003B5C',
  },
};

export const colors = {
  highlight: {
    green: 'rgba(204,248,240,0.35)',
  },
};
