import styled from 'styled-components';

export default styled.div`
  .wrapper {
    position: relative;
  }

  .file-input {
    opacity: 0;
    position: absolute;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    -khtml-opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
  }
`;
