import { permissionKey } from '../../../constants/auth';
import { lazy } from 'react';

export const AdminApplicationSectionRoutes = {
  borrowerRoutes: [
    // Borrower section
    {
      nested: true,
      key: 'borrowerProfile',
      path: '/borrower-profile',
      permissionKey: permissionKey['RegistryInfomation.View'],
      component: lazy(
        () =>
          import(
            './section-Borrower/borrower_profile/BorrowerProfile.component'
          )
      ),
    },
    {
      nested: true,
      key: 'contacts',
      path: '/contacts',
      permissionKey: permissionKey['Contact.View'],
      component: lazy(
        () => import('./section-Borrower/contacts/Contact.component')
      ),
    },
    {
      nested: true,
      key: 'network',
      path: '/network',
      permissionKey: permissionKey['RegistryInfomation.View'],
      component: lazy(
        () => import('./section-Borrower/network/Network.component')
      ),
    },
    {
      nested: true,
      key: 'documents',
      path: '/documents',
      permissionKey: permissionKey['DocumentsManagement.ViewRequested'],
      component: lazy(
        () => import('./section-Borrower/document/Document.component')
      ),
    },
    {
      nested: true,
      key: 'financialStatements',
      path: '/financial-statements',
      component: lazy(
        () =>
          import(
            './section-Borrower/financial_statement/FinancialStatements.component'
          )
      ),
    },
    {
      nested: true,
      key: 'banksStatements',
      path: '/banks-statements',
      permissionKey: permissionKey['CurrentAccount.View'],
      component: lazy(
        () =>
          import('./section-Borrower/bank_statement/BanksStatements.component')
      ),
    },
    {
      nested: true,
      key: 'creditHistory',
      permissionKey: permissionKey['CreditHistory.View'],
      path: '/credit-history',
      component: lazy(
        () =>
          import('./section-Borrower/credit_history/CreditHistory.component')
      ),
    },
    {
      nested: true,
      key: 'centraleRischi',
      permissionKey: permissionKey['CentraleRischi.View'],
      path: '/centrale-rischi',
      component: lazy(
        () => import('./section-Borrower/central_risk/CentralRisk.component')
      ),
    },
    {
      nested: true,
      key: 'indexes',
      path: '/indexes',
      component: lazy(
        () => import('./section-Borrower/indexes/Indexes.component')
      ),
    },
    {
      nested: true,
      key: 'complexIndexFdg',
      permissionKey: permissionKey['FDGGuarantee.View'],
      path: '/complex-index-fdg',
      component: lazy(
        () =>
          import(
            './section-Borrower/complex_index_fdg/ComplexIndexFDG.component'
          )
      ),
    },
    {
      nested: true,
      key: 'webReputation',
      permissionKey: permissionKey['WebReputation.View'],
      path: '/web-reputation',
      component: lazy(
        () =>
          import('./section-Borrower/web_reputation/WebReputation.component')
      ),
    },
    {
      key: 'aml',
      path: '/aml',
      permissionKey: permissionKey['AML.View'],
      nested: true,
      component: lazy(() => import('./section-Borrower/aml/Aml.component')),
    },
    {
      nested: true,
      key: 'manage-invitations',
      permissionKey: permissionKey['UserInvitation.View'],
      path: '/manage-invitations',
      component: lazy(
        () => import('../../common/manage_roles/ManageRole.component')
      ),
    },
    {
      nested: true,
      key: 'change-loan-ownership',
      path: '/change-loan-ownership',
      permissionKey: permissionKey['ChangeLoanOwnership.View'],
      component: lazy(
        () =>
          import(
            './section-Borrower/changeLoanOwnership/ChangeLoanOwnership.component'
          )
      ),
    },
    {
      nested: true,
      key: 'manageCapOpl',
      permissionKey: permissionKey['MerchantCap.View'],
      path: '/OPL/manage-cap-opl',
      component: lazy(
        () => import('./section-Borrower/opl_cap/OplCap.component')
      ),
    },
    {
      nested: true,
      key: 'activitiesManagement',
      permissionKey: permissionKey['ActivitiesManagement.View'],
      path: '/activities-management',
      component: lazy(
        () =>
          import(
            './common/activities_management/ActivitiesManagement.component'
          )
      ),
    },
  ],
  applicationRoutes: [
    // Application section
    {
      nested: true,
      key: 'checklists',
      permissionKey: permissionKey['RequestedChecklist.View'],
      path: '/checklists',
      component: lazy(
        () => import('./section-Application/checklist/Checklist.component')
      ),
    },
    {
      nested: true,
      key: 'surveys',
      path: '/surveys',
      permissionKey: permissionKey['Survey.View'],
      component: lazy(
        () =>
          import(
            './section-Application/survey_management/SurveyManagement.component'
          )
      ),
    },
    {
      nested: true,
      key: 'scorecards',
      permissionKey: permissionKey['Scorecard.View'],
      path: '/scorecards',
      component: lazy(
        () => import('./section-Application/scorecard/ScoreCard.component')
      ),
    },
    {
      nested: true,
      key: 'guarantees',
      permissionKey: permissionKey['Guarantee.View'],
      path: '/guarantees',
      component: lazy(
        () => import('./section-Application/guarantees/Guarantees.component')
      ),
    },
    {
      nested: true,
      key: 'loanCreation',
      path: '/loan-creation',
      permissionKey: permissionKey['Loan.View'],
      component: lazy(
        () =>
          import('./section-Application/loan_creation/LoanCreation.component')
      ),
    },
  ],
  loanRoutes: [
    {
      nested: true,
      key: 'amortizationPlan',
      path: '/amortization-plan',
      permissionKey: permissionKey['AmortizationPlan.View'],
      component: lazy(
        () =>
          import('./section-Loan/amortization_plan/AmortizationPlan.component')
      ),
    },
    {
      nested: true,
      key: 'dbt',
      path: '/dbt',
      permissionKey: permissionKey['AmortizationPlan.View'],
      component: lazy(() => import('./section-Loan/dbt/Dbt.component')),
    },
    {
      nested: true,
      key: 'proofOfClaim',
      path: '/proof-of-claim',
      permissionKey: permissionKey['ProofOfClaim.View'],
      component: lazy(
        () => import('./section-Loan/proof_of_claim/ProofOfClaim.component')
      ),
    },
    {
      nested: true,
      key: 'guaranteeActivation',
      path: '/guarantee-activation',
      permissionKey: permissionKey['GuaranteeActivation.View'],
      component: lazy(
        () =>
          import(
            './section-Loan/guarantee_activation/GuaranteeActivation.component'
          )
      ),
    },
    {
      nested: true,
      key: 'fdgGuarantee',
      path: '/fdg-guarantee',
      permissionKey: permissionKey['FDGLoanLifeCycle.View'],
      component: lazy(
        () => import('./section-Loan/fdg_guarantee/FdgGuarantee.component')
      ),
    },
    {
      nested: true,
      key: 'debtCollection',
      path: '/debt-collection',
      permissionKey: permissionKey['DebtCollection.View'],
      component: lazy(
        () => import('./section-Loan/debt_collection/DebtCollection.component')
      ),
    },
  ],
};

export const AdminApplicationRoutes = [
  ...AdminApplicationSectionRoutes.borrowerRoutes,
  ...AdminApplicationSectionRoutes.applicationRoutes,
  ...AdminApplicationSectionRoutes.loanRoutes,
];
