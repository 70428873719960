import React from 'react';
import { Input } from 'antd';
import styled from 'styled-components';

const CustomInput = styled(Input)`
  margin-bottom: 8px;
  display: block;
`;

interface Props {
  value: string;
  attr: string;
  onConfirm?: any;

  [x: string]: any;
}

const String = ({ value, onConfirm, ...rest }: Props) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    rest.onChange(e.target.value);
  };

  return (
    <CustomInput
      {...rest}
      value={value}
      onChange={onChange}
      onPressEnter={(e: any) => onConfirm(e.target.value)}
    />
  );
};

export default String;
