import { Row, Col } from 'antd';
import Editor from '../../../../editor';
import { useTranslator } from '@opyn/utils';

interface Props {
  value: Date[] | string;
  onChange: (item: (Date | null | string)[]) => Date;

  [x: string]: any;
}

const Date = ({ value, onChange, ...rest }: Props) => {
  const translator = useTranslator();
  const getValue = (index: number) => {
    return value ? value[index] : null;
  };

  const handleChange = (index: number) => (params: { value: Date }) => {
    const temp = value ? [...value] : [];
    temp[index] = params.value;
    onChange(temp.map((item: Date | string) => (item === '' ? null : item)));
  };

  return (
    <Row gutter={8} justify="space-between">
      <Col xs={12}>
        <Editor
          {...rest}
          type={Editor.type.date}
          value={getValue(0)}
          onChange={handleChange(0)}
          placeholder={translator.translate('selectDate.label')}
        />
      </Col>
      <Col xs={12}>
        <Editor
          {...rest}
          type={Editor.type.date}
          value={getValue(1)}
          onChange={handleChange(1)}
          placeholder={translator.translate('selectDate.label')}
        />
      </Col>
    </Row>
  );
};

export default Date;
