import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { STATUS } from '../../types/globals.types';
import { commonReducer } from '../../constants';
import { PermissionsStateModel, Permissions } from './permissions.types';
import { getPermissions } from '../../services';

const name = 'permissions';
const typePrefix = 'permissions';

const initialState: PermissionsStateModel = {
  ...commonReducer,
  permissions: [],
  roles: [],
};

 
export const permissions = createAsyncThunk<any>(
  typePrefix,
  async (_, thunkAPI) => {
    try {
      return await getPermissions();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const permissionsSlice = createSlice({
  name,
  initialState,
  reducers: {
    permissionsReset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(permissions.pending, (state: PermissionsStateModel) => {
        state.status = STATUS.LOADING;
      })
      .addCase(
        permissions.fulfilled,
        (state: PermissionsStateModel, action: PayloadAction<Permissions>) => {
          state.status = STATUS.SUCCESS;
          state.isSuccess = true;
          state.permissions = action.payload?.permissions || [];
          state.roles = action.payload?.roles || [];
        }
      )
      .addCase(permissions.rejected, (state: PermissionsStateModel) => {
        state.status = STATUS.ERROR;
        state.isError = true;
      });
  },
});

export const { permissionsReset } = permissionsSlice.actions;
export default permissionsSlice;
