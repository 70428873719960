import React, { lazy } from 'react';
import { businessProcessType } from '../../constants/journey_settings';
import { permissionKey } from '../../constants/auth';

export const borrowerRoutes = [
  {
    nested: true,
    key: 'home',
    path: '/home',
    children: [],
    component: lazy(() => import('./home/Home.component')),
  },
  {
    nested: true,
    key: 'borrower-registration',
    path: '/registration',
    params: {
      phase: 2,
      businessProcessId: businessProcessType.borrower,
    },
    component: lazy(
      () => import('./steppers/borrower_journey/BorrowerJourney.component')
    ),
  },
  {
    nested: true,
    path: '/:borrowerId',
    params: {
      phase: 3,
      businessProcessId: businessProcessType.borrower,
    },
    component: lazy(
      () => import('./steppers/borrower_journey/BorrowerJourney.component')
    ),
  },
  {
    key: 'borrower-update',
    nested: true,
    path: '/:borrowerId/application/registration',
    component: lazy(
      () =>
        import('./steppers/application_journey/ApplicationJourney.component')
    ),
    children: [],
  },
  {
    nested: true,
    path: '/:borrowerId/application/:applicationId',
    component: lazy(
      () =>
        import('./steppers/application_journey/ApplicationJourney.component')
    ),
    children: [],
  },
  {
    nested: true,
    path: '/:borrowerId/loan-summary/:loanId',
    permissionKey: permissionKey['AmortizationPlan.View'],
    component: lazy(
      () =>
        import(
          './home/components/main_content/active_borrower/applications/loan_information_summary'
        )
    ),
    children: [],
  },
  {
    nested: true,
    path: '/:borrowerId/loan/:loanId',
    component: React.lazy(
      () => import('./steppers/loan_journey/LoanJourney.component')
    ),
    children: [],
  },
  {
    key: 'search-console',
    nested: true,
    path: '/search-console',
    component: lazy(() => import('./search_console/SearchConsole.component')),
    children: [],
  },
];
