interface Props {
  client?: any;
  endAnimation?: any;
  startAnimation?: any;
}

export default ({ client, startAnimation, endAnimation }: Props) => {
  client.interceptors.request.use(
    function (config: any) {
      startAnimation();
      return config;
    },
    function (error: any) {
      endAnimation();
      return Promise.reject(error);
    }
  );

  client.interceptors.response.use(
    function (response: any) {
      endAnimation();
      return response;
    },
    function (error: any) {
      endAnimation();
      return Promise.reject(error);
    }
  );
};
