import React from 'react';
import { types } from '../../../constants/editor_types';
import { Input, InputNumber } from 'antd';
import { useTranslator } from '@opyn/utils';
import { getValidProps } from '../index';

type Params = {
  [key: string]: unknown;
  value: string | number | null | [] | any;
  autoComplete: string;
  style: React.CSSProperties & { width: string };
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

interface IItem {
  name: string;
  cultureId: number;
}

interface Props {
  value: [];
  type?: string;
  style?: React.CSSProperties;
  attr: string;
  search?: boolean;
  language: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement> | string) => void;
  textArea?: boolean;
  isPassword?: boolean;
  isEmailType?: boolean;
  multiLanguage: boolean;
  isPercentage?: boolean;
  isNumber?: boolean;
  config?: any;
  [x: string]: any;
}

const LanguageInput = ({
  attr,
  type = 'string',
  style,
  search,
  onChange,
  language,
  textArea = false,
  value = [],
  isPassword,
  isEmailType,
  multiLanguage = false,
  isPercentage,
  isNumber,
  ...rest
}: Props) => {
  const translator = useTranslator();

  const params: Params = {
    ...rest,
    value: value,
    autoComplete: 'new-password',
    style: {
      ...style,
      width: '100%',
    },
    onChange: (e: React.ChangeEvent<HTMLInputElement> | any) => {
      const value = type === 'string' ? e.target.value : e;

      onChange(value);
    },
  };

  if (!params.placeholder)
    params.placeholder = translator.translate('enterValue.label');

  if (isPercentage) {
    params.min = rest.min ? rest.min : 0;
    params.max = 100;
    params.addonAfter = '%';
    if (translator.language.includes('it')) {
      params.formatter = (value: number) => value.toString().replace('.', ',');
      params.parser = (value: string) => value!.replace(',', '.');
    } else {
      params.formatter = (value: number) => value.toString().replace(',', '.');
      params.parser = (value: string) => value!.replace(',', '.');
    }
  }

  if (isEmailType) params.type = 'email';

  if (type === 'string' && multiLanguage && value) {
    const localization: IItem[] | undefined = value.find(
      (item: IItem) => item.cultureId === language
    );

    params.value = localization ? localization[attr] : null;
  }

  if (type)
    if (!Object.prototype.hasOwnProperty.call(types, type)) {
      console.warn(`Type : ${type} is not valid in Input Component Instance `);
      type = types.string;
    }

  if (isNumber) {
    params.min = rest.min ? rest.min : 0;
    params.parser = (value: string) => {
      return parseFloat(value.replace(/[^0-9.]/g, ''));
    };
    params.formatter = (value: number | string) => {
      if (value === null || value === undefined) {
        return '';
      }
      return String(value);
    };
  }

  let Component: any;

  if (type === 'string') {
    if (search) Component = Input.Search;
    else if (textArea) Component = Input.TextArea;
    else if (isPassword) Component = Input.Password;
    else Component = Input;
  } else if (type === 'number') {
    Component = InputNumber;
  }

  return (
    <Component
      key={isPercentage ? translator.language : undefined}
      {...getValidProps(params, [
        'optionsUrl',
        'customColor',
        'parser',
        'formatter',
        'helper',
        'labelTranslateId',
        'disabledDate',
        'languageAttr',
        'editable',
      ])}
    />
  );
};

export default LanguageInput;
