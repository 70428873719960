import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from 'antd';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import { useTranslator } from '@opyn/utils';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

interface Props {
  style?: React.CSSProperties;
  range?: boolean;
  value: string | string[] | null;
  onChange?: (value: string | string[] | null) => void;
}

const DateSelector = ({
  value,
  range = false,
  onChange,
  style,
  ...rest
}: Props) => {
  const translator = useTranslator();
  const format = translator.language === 'it-IT' ? 'DD/MM/YYYY' : 'MM/DD/YYYY';

  const params: any = {
    ...rest,
    style: {
      ...style,
      width: '100%',
    },
    value: Array.isArray(value)
      ? value.map((v) => dayjs(v))
      : value
        ? dayjs(value)
        : null,
    format,
    onChange: (value: Dayjs | Dayjs[] | null) => {
      if (onChange) {
        return Array.isArray(value)
          ? onChange(value.map((v) => dayjs(v).format('MM/DD/YYYY')))
          : !value
            ? onChange(value)
            : onChange(dayjs(value).format('MM/DD/YYYY'));
      }
    },
  };

  let Component: React.ComponentType;

  if (range) Component = DatePicker.RangePicker;
  else Component = DatePicker;
  return <Component {...params} />;
};

export default DateSelector;
