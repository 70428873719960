import { Collapse } from 'antd';
import styled from 'styled-components';

interface CollapseProps {
  color?: any;
  background?: any;
}

export default styled(Collapse)<CollapseProps>`
  border-radius: 10px !important;

  .ant-collapse-item {
    border-radius: 10px !important;

    .ant-collapse-header {
      padding: 14px 24px;
      border-radius: 10px 10px 10px 10px !important;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      color: ${(props) => props.color};
      background: ${(props) => props.background};

      .ant-collapse-arrow {
        font-size: 20px !important;
      }
    }

    .ant-collapse-content {
      border-radius: 0 0 10px 10px !important;

      .ant-collapse-content-box {
        padding: 14px 24px;
      }
    }
  }

  .ant-collapse-item-active {
    .ant-collapse-header {
      border-radius: 10px 10px 0 0 !important;
    }
  }
`;
