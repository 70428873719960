import React from 'react';
import { Empty, Select } from 'antd';
import { useTranslator } from '@opyn/utils';

type TValue = number | number[] | object[] | string | boolean | null;

interface Props {
  value?: TValue;
  options?: [];
  onChange: (event: TValue) => void;
  isFixed: boolean;
}

const AddOnSelection = ({
  value,
  onChange,
  isFixed = false,
  options = [],
  ...rest
}: Props) => {
  const [createdOption, setCreatedOption] = React.useState<string | null>(null);
  const translator = useTranslator();

  React.useEffect(() => {
    if (!createdOption) return;

    onChange(createdOption);
  }, [createdOption]);

  return (
    <Select
      dropdownStyle={isFixed ? { position: 'fixed' } : undefined}
      allowClear
      showSearch
      notFoundContent={
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={translator.translate('noData.label')}
        />
      }
      {...rest}
      value={value}
      className="full-width"
      onChange={(e) => {
        if (e !== createdOption) setCreatedOption(null);
        onChange(e);
      }}
      onSearch={setCreatedOption}
      placeholder={translator.translate('selectIban.label')}
      optionFilterProp="children"
    >
      {[createdOption, ...options]
        .filter((record) => !!record)
        .map((option) => {
          return (
            <Select.Option key={option} value={option}>
              {option}
            </Select.Option>
          );
        })}
    </Select>
  );
};

export default AddOnSelection;
