import { Application, ApplicationStateModel } from '../application.types';
import { BorrowerViewModel } from './borrowerView.types';

export const mapApiResponseToState = (
  prev: ApplicationStateModel,
  apiResponse: BorrowerViewModel
): Application => {
  let application: ApplicationStateModel['application'];
  let loan: ApplicationStateModel['loan'];

  if (prev.application) {
    application = {
      ...prev.application,
      registryDataId: apiResponse.registerDataId,
      paymentAccountId: apiResponse.paymentAccountId,
      borrowerId: apiResponse.id,
      borrower: {
        ...prev.application.borrower,
        id: apiResponse.id,
        name: apiResponse.name,
        vatNumber: apiResponse.vatNumber,
        companyTypeId: apiResponse.companyTypeId,
        companyTypeName: apiResponse.companyTypeName,
        fiscalCode: apiResponse.fiscalCode,
        registerDataId: apiResponse.registerDataId,
        contacts: {
          ...prev.application.borrower.contacts,
          firstName: apiResponse.borrowerContact.firstName,
          lastName: apiResponse.borrowerContact.lastName,
          phoneNumber: apiResponse.borrowerContact.phoneNumber,
          prefix: apiResponse.borrowerContact.prefix,
        },
      },
    };
  }
  if (prev.loan) {
    loan = {
      ...prev.loan,
      registerDataId: apiResponse.registerDataId,
      paymentAccountId: apiResponse.paymentAccountId,
    };
  }

  return { application, loan };
};
