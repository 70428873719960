import ItalianLanguage from '../assets/translations/it.json';
import EnglishLanguage from '../assets/translations/en.json';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export const LanguageType = {
  it: 'it-IT',
  us: 'en-US',
};

const getBrowserLanguage = (): string => {
  const language = navigator.language;
  return language.includes('it') ? LanguageType.it : LanguageType.us;
};

const DefaultSystemLanguage =
  localStorage.getItem('language') || getBrowserLanguage();

i18n.use(initReactI18next).init({
  lng: DefaultSystemLanguage,
  fallbackLng: DefaultSystemLanguage,
  resources: {
    [LanguageType.it]: {
      translation: ItalianLanguage,
    },
    [LanguageType.us]: {
      translation: EnglishLanguage,
    },
  },
});

export { i18n, DefaultSystemLanguage };
