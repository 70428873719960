export enum STATUS {
  LOADING = 'LOADING',
  IDLE = 'IDLE',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export interface CommonReducerInterfaceAsync {
  status?: STATUS.SUCCESS | STATUS.ERROR | STATUS.IDLE | STATUS.LOADING;
  isSuccess: boolean;
  isError: boolean;
}

export enum ReducerKeys {
  Settings = 'settings',
  Auth = 'auth',
  Permissions = 'permissions',
  Application = 'application',
}
