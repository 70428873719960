import React from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
import StyledModal from '../modals/styled_modal';
import { useTranslator } from '@opyn/utils';
import { ArrowsAltOutlined } from '@ant-design/icons';
import { colorType, extractColor } from '../../helpers';

const { Paragraph } = Typography;

const ArrowsAltOutlinedIcon = styled(ArrowsAltOutlined)`
  color: ${extractColor(colorType.secondary, 3)};
`;

interface Props {
  value?: string;
  rows?: number;
  isJson?: boolean;
  object?: {
    [key: string]: any;
  };
  JsonTitle?: string;
}

const NoteViewer = ({
  value,
  rows = 1,
  isJson = false,
  object,
  JsonTitle,
}: Props) => {
  const translator = useTranslator();
  const [key, setKey] = React.useState<number>(1);
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

  return (
    <>
      <Paragraph
        key={key}
        ellipsis={{
          rows: rows,
          expandable: true,
          symbol: <ArrowsAltOutlinedIcon />,
          onExpand: () => {
            setIsExpanded(true);
            setKey((previous: number) => previous + 1);
          },
        }}
      >
        {!isJson && value}
        {isJson && JsonTitle}
      </Paragraph>
      <StyledModal
        centered
        secondary
        width={520}
        footer={null}
        destroyOnClose
        open={isExpanded}
        onCancel={() => {
          setIsExpanded(false);
          setKey((previous: number) => previous + 1);
        }}
        styles={{ body: { padding: 16 } }}
        title={translator.translate('description.label')}
      >
        {!isJson && value}
        {isJson && JSON.stringify(object)}
      </StyledModal>
    </>
  );
};

export default NoteViewer;
