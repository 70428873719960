import React from 'react';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import styled from 'styled-components';
import { colorType, extractColor } from '../../helpers';

const CheckCircleFilledIcon = styled(CheckCircleFilled)`
  color: ${(props) =>
    props.color ? props.color : extractColor(colorType.success, 2)};
  font-size: 18px;
`;

const CloseCircleFilledIcon = styled(CloseCircleFilled)`
  color: ${(props) =>
    props.color ? props.color : extractColor(colorType.warning, 2)};
  font-size: 18px;
`;

interface Props {
  color?: string;
  style?: React.CSSProperties;
  active?: boolean;
}

const StatusCheck = ({ active, style = {} }: Props) => {
  if (active) return <CheckCircleFilledIcon style={{ ...style }} />;

  return <CloseCircleFilledIcon style={{ ...style }} />;
};

export default StatusCheck;
