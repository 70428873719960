import { Col } from 'antd';
import styled from 'styled-components';
import { customColor } from '../../helpers/tenant';
import { colorType, extractColor } from '../../helpers';

interface DivProps {
  color?: any;
}

const ROW = styled.div`
  overflow: hidden;
  min-height: 100px;
  border-width: 1px;
  position: relative;
  border-radius: 10px;
  border-style: solid;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);
  border-color: ${extractColor(colorType.secondary)};
  background: ${extractColor(colorType.secondary, 0)};
`;

const ValueCustomDiv = styled.div<DivProps>`
  margin: 5px;
  font-size: 15px;
  font-weight: 600;
  text-overflow: ellipsis;
  color: ${(props) => props.color};
`;

const LabelCustomDiv = styled.div<DivProps>`
  margin: 5px;
  font-size: 10px;
  font-weight: 500;
  color: ${(props) => props.color};
`;

const IconCustomDiv = styled.div`
  right: 0;
  z-index: 0;
  top: -60px;
  opacity: 0.3;
  position: absolute;
  transform: rotate(15deg);
`;

const icons: any = {
  money: (
    <svg
      fill="none"
      width="136"
      height="111"
      viewBox="0 0 136 111"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M82.2565 47.6342C88.9972 50.1676 94.4543 55.2602 97.4315 61.7954C100.408 68.3305 100.662 75.7745 98.1361 82.495L82.2933 124.482C81.9229 125.464 81.176 126.258 80.218 126.691C79.2593 127.124 78.1672 127.161 77.1816 126.792L21.5536 105.97C20.5683 105.601 19.7706 104.857 19.3358 103.902C18.9008 102.948 18.8644 101.86 19.2348 100.879L35.0777 58.8916C37.6213 52.178 42.7344 46.7427 49.2957 43.7777C55.8571 40.8129 63.3309 40.5605 70.0789 43.076L82.2565 47.6342ZM83.8522 43.4054L71.6746 38.8472C63.8011 35.9001 55.075 36.1889 47.4155 39.6499C39.756 43.1111 33.7906 49.461 30.8318 57.3024L14.989 99.2893C14.1954 101.392 14.2732 103.723 15.2051 105.769C16.137 107.815 17.8466 109.408 19.958 110.198L75.586 131.021C77.6975 131.811 80.0378 131.733 82.0917 130.805C84.1455 129.877 85.7455 128.174 86.5391 126.071L102.382 84.0842C105.341 76.2429 105.051 67.5515 101.576 59.9227C98.101 52.2939 91.7256 46.3525 83.8522 43.4054Z"
        fill="#CFF2E8"
      />
      <path
        d="M56.6626 97.6275C55.0883 97.026 53.7268 95.9771 52.7477 94.61C51.768 93.2427 51.2146 91.6186 51.1565 89.9406L55.1368 91.4304L57.9162 86.785L58.0039 86.5527L52.4685 84.4808L52.8276 83.529L58.5702 85.6785L61.4611 80.8097L55.0233 78.4C56.0901 76.7833 57.6739 75.5719 59.518 74.9636C61.3617 74.3545 63.358 74.3836 65.1833 75.0456C68.3547 76.2327 71.7378 78.9765 72.0712 81.9216L77.6312 81.288C77.0165 75.8457 71.9242 71.6115 67.153 69.8256C60.4741 67.3256 53.0382 70.2731 49.6893 76.4124L42.9011 73.8716L40.9336 79.086L47.5887 81.577L47.2296 82.5288L42.2967 80.6824L40.3292 85.8968L45.6549 87.8903C44.8964 94.2425 48.4853 100.526 54.6921 102.85C59.4372 104.626 66.0319 104.805 70.0701 101.198L66.3402 97.0448C64.1596 98.9884 59.8232 98.8105 56.6626 97.6275Z"
        fill="#CFF2E8"
      />
      <path
        d="M110.597 21.7643L109.817 21.4723C107.036 20.4312 104.921 21.6063 103.527 22.3874C102.265 23.0914 101.795 23.2954 101.048 23.0161C100.303 22.7371 100.084 22.2749 99.5998 20.9174C99.0631 19.4256 98.255 17.1445 95.4873 16.1085C92.7196 15.0725 90.5913 16.2424 89.1975 17.0238C87.9356 17.7278 87.4646 17.9316 86.7191 17.6525C85.9735 17.3734 85.7567 16.9122 85.2727 15.5547C84.7201 14.0569 83.9121 11.7758 81.1342 10.736C78.3557 9.69597 76.2381 10.8699 74.8466 11.6521C73.5808 12.3667 73.1138 12.5599 72.3682 12.2809C71.6226 12.0018 71.4058 11.5405 70.9218 10.183C70.3799 8.68926 69.572 6.40815 66.7937 5.36819L66.048 5.08907C65.2168 4.77948 64.2962 4.81074 63.488 5.17592C62.6798 5.54113 62.0499 6.21047 61.7363 7.03738L60.4398 10.4733C59.1849 13.7971 58.5995 17.3344 58.7172 20.8835C58.8348 24.4326 59.6532 27.9239 61.1255 31.1579C62.5979 34.392 64.6953 37.3055 67.2981 39.7322C69.9009 42.1589 72.9585 44.0514 76.2955 45.3013L76.4891 45.3737C90.4323 50.4782 106.07 43.2256 111.356 29.215L112.546 26.0619C112.858 25.2345 112.829 24.3177 112.463 23.5121C112.098 22.7065 111.427 22.0779 110.597 21.7643ZM78.054 41.1304L77.8921 41.0698C72.2791 38.9688 67.7345 34.7334 65.2571 29.2948C62.78 23.8565 62.5733 17.6606 64.6827 12.0705L65.5473 9.77895C66.0239 10.081 66.2458 10.5924 66.6408 11.6964C67.1809 13.2107 67.9941 15.4697 70.7725 16.5097C73.551 17.5497 75.6686 16.3756 77.0629 15.5945C78.3248 14.8905 78.7952 14.6866 79.5385 14.9648C80.2813 15.2428 80.5032 15.7059 80.9872 17.0634C81.5318 18.5824 82.3342 20.8373 85.1127 21.8773C87.8911 22.9173 90.0087 21.7433 91.4031 20.9622C92.6649 20.2581 93.1353 20.0542 93.8815 20.3335C94.627 20.6125 94.8433 21.0735 95.3302 22.4321C95.8611 23.946 96.6771 26.206 99.4613 27.2481C102.245 28.29 104.357 27.1141 105.751 26.3328C106.81 25.7427 107.311 25.505 107.879 25.6211L107.121 27.6298C102.704 39.3205 89.6663 45.3805 78.054 41.1304Z"
        fill="#CFF2E8"
      />
      <path
        d="M57.3629 51.6427L58.9586 47.4137C61.2067 48.2552 61.9198 47.5449 63.8416 45.1725C65.4835 43.1478 67.7368 40.3627 71.9898 40.0784C71.6736 38.4406 71.7344 36.7523 72.169 35.1415C72.3775 34.2996 72.64 33.508 72.8878 32.7623C73.3241 31.4536 73.7335 30.2163 73.559 29.4664C73.354 28.5903 71.9121 27.8847 70.7391 27.4456L72.3347 23.2168C79.8979 26.0478 78.2715 30.9437 77.1942 34.1841C76.9715 34.8549 76.7392 35.5492 76.5749 36.2176C76.4776 36.6125 75.6831 40.1066 77.7867 41.0328L76.1271 45.2376C71.0265 43.5063 69.5007 45.3995 67.3804 48.0173C65.4348 50.4049 62.7846 53.6721 57.3629 51.6427Z"
        fill="#CFF2E8"
      />
      <path
        d="M76.7139 45.4519L78.044 41.1237C80.2304 41.7913 81.9361 38.6594 82.14 38.2953C82.4583 37.6845 82.7422 37.0126 83.0191 36.3591C84.3512 33.2141 86.3649 28.4631 93.9275 31.2938L92.3319 35.5226C91.1589 35.0836 89.6073 34.6687 88.8737 35.1934C88.2436 35.6423 87.7364 36.8429 87.1993 38.1138C86.8864 38.8473 86.5667 39.6054 86.1646 40.3748C84.5064 43.5487 81.0444 46.7773 76.7139 45.4519Z"
        fill="#CFF2E8"
      />
      <path
        d="M86.0069 62.3657C80.5853 60.3363 80.754 56.1342 80.878 53.0678C80.992 50.2692 81.0762 48.1199 78.2327 46.2985L75.4802 53.5932L71.2344 52.0039L75.6982 40.1738L77.8614 41.062C85.7714 44.3062 85.5544 49.6875 85.4139 53.2395C85.2912 56.2852 85.3581 57.2876 87.6056 58.1288L86.0069 62.3657Z"
        fill="#CFF2E8"
      />
    </svg>
  ),
  calendar: (
    <svg
      fill="none"
      width="136"
      height="111"
      viewBox="0 0 136 111"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M119.863 58.3094C122.543 59.9179 124.423 62.6289 125.089 65.8476C125.756 69.0662 125.156 72.53 123.419 75.4784L94.4005 124.642C92.6585 127.587 89.9239 129.773 86.7972 130.722C83.6708 131.671 80.4077 131.304 77.7235 129.702L12.1455 90.4548C9.4653 88.8464 7.58559 86.1355 6.9189 82.9169C6.25247 79.6977 6.85312 76.2342 8.5891 73.2861L37.608 24.1223C39.3503 21.1774 42.0846 18.9908 45.211 18.0423C48.3374 17.0937 51.6006 17.4606 54.2847 19.0624L119.863 58.3094ZM122.248 54.2677L56.6703 15.0208C53.0115 12.8311 48.5602 12.3279 44.2954 13.6219C40.0306 14.9159 36.3018 17.901 33.9292 21.9207L4.91035 71.0845C2.5378 75.1041 1.716 79.8286 2.62549 84.2192C3.53498 88.6098 6.10119 92.3068 9.75993 94.4965L75.338 133.743C78.9966 135.933 83.4484 136.436 87.7131 135.142C91.9778 133.848 95.7067 130.864 98.0793 126.844L127.098 77.6801C129.471 73.6605 130.292 68.9355 129.383 64.5449C128.474 60.1543 125.907 56.4574 122.248 54.2677Z"
        fill="#CFF2E8"
      />
      <path
        d="M57.7561 30.445C56.7398 29.8368 56.3238 28.6545 56.8277 27.8008L65.4425 13.2055C65.945 12.3543 67.1766 12.1547 68.1929 12.7629C69.2091 13.3711 69.6237 14.5559 69.1212 15.4071L60.5064 30.0024C60.0025 30.8561 58.7723 31.0532 57.7561 30.445Z"
        fill="#CFF2E8"
      />
      <path
        d="M108.274 60.6792C107.258 60.071 106.842 58.8889 107.346 58.0351L115.961 43.4399C116.464 42.5886 117.695 42.389 118.711 42.9972C119.727 43.6053 120.142 44.7903 119.64 45.6416L111.025 60.2368C110.521 61.0906 109.291 61.2874 108.274 60.6792Z"
        fill="#CFF2E8"
      />
      <path
        d="M123.693 83.3797L33.5361 29.4229L31.1506 33.4645L121.308 87.4213L123.693 83.3797Z"
        fill="#CFF2E8"
      />
      <path
        d="M32.4715 75.6705L30.3907 74.4252C28.7651 73.4494 27.6248 71.8052 27.2204 69.8526C26.8161 67.9 27.1803 65.7993 28.2334 64.0109L29.2607 62.2705C30.3178 60.4839 31.9762 59.1577 33.8727 58.5821C35.7693 58.0066 37.7486 58.2294 39.3768 59.2009L41.4576 60.4462C43.0834 61.4221 44.2237 63.0663 44.628 65.0189C45.0323 66.9715 44.6681 69.0722 43.6147 70.8611L42.5874 72.6015C41.5301 74.3873 39.8717 75.7128 37.9753 76.2884C36.0792 76.8635 34.0999 76.6414 32.4715 75.6705ZM36.9912 63.2426C36.3393 62.8531 35.5462 62.7642 34.7868 62.9944C34.027 63.2251 33.3627 63.7565 32.9394 64.4722L31.9122 66.2126C31.4904 66.9285 31.3444 67.77 31.5062 68.5523C31.6681 69.3346 32.125 69.993 32.7763 70.3835L34.8571 71.6289C35.5093 72.0177 36.302 72.1064 37.0613 71.8761C37.8209 71.6454 38.4851 71.1147 38.9087 70.3998L39.9359 68.6595C40.358 67.943 40.504 67.1014 40.3421 66.3192C40.18 65.5375 39.7234 64.8785 39.072 64.4879L36.9912 63.2426Z"
        fill="#CFF2E8"
      />
      <path
        d="M58.0316 90.9671L55.9624 89.7287C55.1534 89.2475 54.456 88.5961 53.909 87.8115C53.3615 87.0266 52.9761 86.1244 52.7738 85.1561C52.5715 84.1877 52.5566 83.1718 52.73 82.1673C52.9035 81.1628 53.2617 80.1894 53.7842 79.3019L54.8114 77.5616C55.8686 75.7749 57.5269 74.4486 59.4236 73.8732C61.3203 73.2977 63.2994 73.5204 64.9274 74.4918L66.9971 75.7305C68.6226 76.7062 69.7627 78.3503 70.1672 80.303C70.5717 82.2556 70.2072 84.3562 69.1539 86.1452L68.1267 87.8856C67.0718 89.667 65.4179 90.9916 63.5268 91.5689C61.6352 92.1459 59.6595 91.9297 58.0316 90.9671ZM62.5629 78.5461C62.2398 78.3491 61.878 78.2245 61.4984 78.1795C61.1185 78.1338 60.7283 78.1686 60.3496 78.2817C59.9709 78.3948 59.6118 78.5839 59.2929 78.8385C58.9738 79.0924 58.7008 79.4065 58.4902 79.7632L57.463 81.5036C57.0411 82.2195 56.8954 83.0612 57.057 83.8433C57.219 84.6257 57.6759 85.2841 58.3269 85.6744L60.3966 86.9131C61.0485 87.3018 61.8411 87.3904 62.6007 87.1604C63.3602 86.9295 64.0245 86.3989 64.4479 85.6839L65.4751 83.9435C65.8973 83.2271 66.0435 82.3857 65.8814 81.6033C65.7191 80.8215 65.2625 80.1625 64.6115 79.7721L62.5629 78.5461Z"
        fill="#CFF2E8"
      />
      <path
        d="M82.9881 105.903L80.9071 104.658C79.2819 103.681 78.1426 102.037 77.7378 100.085C77.3339 98.1324 77.6975 96.0321 78.75 94.2433L79.7773 92.503C80.8345 90.7163 82.4927 89.3901 84.3894 88.8146C86.2861 88.2391 88.2652 88.4618 89.8932 89.4332L91.9742 90.6786C93.5994 91.6549 94.7387 93.2993 95.1427 95.2516C95.5465 97.2032 95.182 99.3037 94.1291 101.092L93.1018 102.833C92.0452 104.618 90.387 105.944 88.4916 106.52C86.5954 107.096 84.6165 106.874 82.9881 105.903ZM87.5077 93.4749C86.856 93.0857 86.063 92.9967 85.3033 93.2268C84.5438 93.4577 83.8792 93.9888 83.4561 94.7046L82.4288 96.445C82.0074 97.1612 81.862 98.0023 82.0236 98.7844C82.186 99.5663 82.6421 100.225 83.2927 100.616L85.3737 101.861C86.0256 102.25 86.8182 102.339 87.5772 102.107C88.3363 101.877 89.0001 101.346 89.423 100.631L90.4502 98.8905C90.8724 98.1741 91.0182 97.3324 90.8566 96.5503C90.6942 95.7684 90.2376 95.1095 89.5861 94.7188L87.5077 93.4749Z"
        fill="#CFF2E8"
      />
    </svg>
  ),
  lend: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="141"
      height="111"
      viewBox="0 0 141 111"
      fill="none"
    >
      <g style={{ clipPath: `url(#clip0_10723_14731)` }}>
        <path
          d="M38.2808 48.1875L26.4119 79.269L8.49269 72.4263L20.3615 41.3449L38.2808 48.1875ZM42.6348 44.8952L19.3101 35.9884C18.8801 35.8242 18.4024 35.8373 17.982 36.0256C17.5619 36.2133 17.2336 36.5604 17.0693 36.9905L3.1361 73.478C2.97207 73.9076 2.98527 74.3857 3.17338 74.8057C3.36122 75.2262 3.70854 75.5544 4.13861 75.7187L27.4633 84.6255C27.8934 84.7897 28.3711 84.7766 28.7915 84.5883C29.2116 84.4005 29.5401 84.0529 29.7041 83.6234L43.6373 47.1358C43.8016 46.7057 43.7882 46.2282 43.6001 45.8082C43.4122 45.3876 43.0649 45.0595 42.6348 44.8952Z"
          fill="#CFF2E8"
        />
        <path
          d="M88.0014 88.7017C87.4283 88.4828 86.9652 88.045 86.7149 87.4843C86.4637 86.9239 86.446 86.2869 86.6649 85.7138C86.884 85.1401 87.3217 84.677 87.8824 84.4267C88.4426 84.1761 89.0796 84.1584 89.6527 84.3773L100.974 88.62C100.889 87.611 100.589 86.632 100.094 85.7488C99.5989 84.8654 98.9215 84.098 98.1053 83.4983C97.4639 82.9964 96.1968 82.4874 94.8011 82.1685C93.1235 81.7849 89.5813 80.9555 84.6962 79.7031C84.028 79.5325 83.4165 79.1874 82.9245 78.7042C82.4325 78.221 82.0772 77.6169 81.8939 76.9517C79.6623 68.7661 75.6522 63.0323 69.9753 59.9106C61.1067 55.0348 50.5892 57.8901 48.5717 58.5104C47.7087 58.7794 46.7806 58.752 45.935 58.4325L40.1646 56.229C39.5912 56.01 39.1282 55.5722 38.8775 55.0121C38.627 54.4513 38.609 53.8148 38.8281 53.2411C39.0472 52.6674 39.4848 52.2049 40.0453 51.9538C40.6056 51.7033 41.2425 51.6856 41.816 51.9046L47.3971 54.0358C50.8976 52.9789 62.1512 50.336 72.2053 55.8633C78.9206 59.558 83.6391 66.1133 86.2422 75.3293C90.8672 76.51 94.2185 77.2943 95.8274 77.6642C97.0499 77.9421 99.3396 78.5964 100.953 79.8601C104.75 82.83 106.28 87.1187 105.381 92.2627C105.321 92.6054 105.186 92.9297 104.986 93.2139C104.786 93.4976 104.525 93.7342 104.223 93.9057C103.92 94.0768 103.584 94.179 103.238 94.2053C102.891 94.2316 102.543 94.1805 102.218 94.0559L87.9981 88.7189L88.0014 88.7017Z"
          fill="#CFF2E8"
        />
        <path
          d="M67.1727 99.2761C63.143 97.7373 60.6455 95.8917 56.0715 92.5099C53.0266 90.257 48.855 87.1743 42.5195 82.9157C42.385 82.8241 42.2401 82.7489 42.0877 82.692L31.0307 78.4698C30.4573 78.2508 29.9943 77.813 29.7438 77.2523C29.4931 76.6921 29.4753 76.055 29.6942 75.4819C29.9133 74.9082 30.3511 74.4451 30.9114 74.1946C31.4717 73.9441 32.1086 73.9264 32.6821 74.1454L43.739 78.3676C44.2186 78.5501 44.6759 78.7868 45.1015 79.0735C51.5255 83.3851 55.7426 86.5097 58.8248 88.786C64.1695 92.7376 66.1659 94.2154 71.1341 95.738C76.3186 97.3273 98.0159 95.9472 109.671 95.2043C112.315 95.0372 114.596 94.893 116.284 94.8035L116.426 94.7958C116.66 94.1893 116.655 93.5161 116.411 92.9131C116.138 92.1435 115.336 90.8152 112.986 90.5307C103.683 90.0035 103.412 89.9252 103.123 89.8393C102.558 89.6749 102.076 89.3021 101.777 88.7954C101.477 88.2884 101.382 87.6871 101.51 87.113C101.638 86.5383 101.98 86.0342 102.467 85.7033C102.953 85.3724 103.548 85.2394 104.129 85.3307C104.337 85.3544 105.728 85.4827 113.315 85.9123L113.441 85.9228C116.709 86.2879 118.577 87.8839 119.559 89.1542C120.766 90.6993 121.351 92.6408 121.197 94.5954C120.938 97.5062 118.938 99.5149 116.415 99.4338C114.764 99.5218 112.539 99.6631 109.968 99.8269C92.6522 100.926 75.3063 101.859 69.7776 100.166C68.8276 99.8802 67.9696 99.5804 67.1727 99.2761Z"
          fill="#CFF2E8"
        />
        <path
          d="M97.4896 10.2251C101.46 11.7407 104.892 14.3996 107.351 17.8657C109.81 21.3319 111.186 25.4492 111.305 29.6976C111.425 33.946 110.281 38.1342 108.02 41.7326C105.758 45.3311 102.481 48.1782 98.6014 49.9141C94.7225 51.6497 90.4158 52.1959 86.2256 51.4837C82.036 50.7718 78.1516 48.8333 75.0636 45.9132C71.9755 42.9932 69.8232 39.2228 68.8777 35.0794C67.9325 30.9359 68.2373 26.6051 69.7534 22.6348C71.7919 17.3158 75.8564 13.0221 81.0562 10.6959C86.2554 8.36946 92.1655 8.20025 97.4896 10.2251ZM99.141 5.9006C94.3155 4.05793 89.052 3.68758 84.0158 4.83632C78.9802 5.98529 74.3979 8.60162 70.8489 12.3547C67.2998 16.1078 64.9434 20.829 64.0775 25.9213C63.2116 31.0136 63.8751 36.2484 65.984 40.9636C68.0932 45.6788 71.5534 49.6626 75.9267 52.4108C80.2997 55.1596 85.3898 56.5497 90.553 56.4058C95.7164 56.2614 100.721 54.589 104.934 51.6003C109.147 48.6119 112.379 44.4411 114.222 39.6156C116.693 33.1447 116.492 25.9573 113.664 19.6344C110.836 13.3117 105.611 8.37142 99.141 5.9006Z"
          fill="#CFF2E8"
        />
        <path
          d="M87.3875 40.1018C86.0844 39.599 84.9589 38.7229 84.1513 37.5833C83.3438 36.4437 82.8898 35.091 82.8475 33.695L86.1473 34.9551L88.4524 31.1002L88.5257 30.9083L83.9579 29.1641L84.2593 28.3749L89.0058 30.1874L91.4216 26.155L86.0894 24.1188C86.9782 22.7703 88.2988 21.7637 89.8343 21.2633C91.3699 20.7628 93.0298 20.7982 94.5429 21.3637C97.1617 22.3637 99.9461 24.6657 100.212 27.1086L104.817 26.6154C104.329 22.0935 100.137 18.5451 96.1942 17.0393C93.5345 16.044 90.6052 16.0421 87.9446 17.0339C85.2839 18.0257 83.0706 19.9446 81.711 22.4375L76.0922 20.2919L74.4408 24.6163L79.938 26.7155L79.6335 27.5128L75.5582 25.9566L73.9069 30.281L78.3068 31.9611C77.9738 34.5704 78.5247 37.2159 79.8712 39.4753C81.2176 41.7349 83.2824 43.4781 85.7361 44.4263C89.6552 45.9228 95.1142 46.1027 98.4723 43.1238L95.4005 39.6592C93.5745 41.2629 89.985 41.0937 87.3875 40.1018Z"
          fill="#CFF2E8"
        />
      </g>
      <defs>
        <clipPath id="clip0_10723_14731">
          <rect
            width="148.127"
            height="148.127"
            fill="white"
            transform="translate(32.209 -45.7207) rotate(20.9)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
};

const StatisticsCard = ({ value, label, type, children }: any) => {
  return (
    <ROW>
      <Col xs={24} title={value}>
        <ValueCustomDiv color={customColor}>
          {value} {children}
        </ValueCustomDiv>
      </Col>
      <Col xs={24}>
        <LabelCustomDiv color={customColor}>{label}</LabelCustomDiv>
      </Col>
      <Col xs={24}>
        <IconCustomDiv>{icons[type]}</IconCustomDiv>
      </Col>
    </ROW>
  );
};

export default StatisticsCard;
