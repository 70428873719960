//Other Libs
import axios from 'axios';

interface Props {
  baseUrl?: any;
}

const createClient = ({ baseUrl }: Props): any => {
  const client: any = axios.create({ baseURL: baseUrl });
  client.isCancel = axios.isCancel;
  client.CancelToken = axios.CancelToken;
  return client;
};

export default createClient;
