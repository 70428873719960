const Whatsapp = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.2054 5.68304C20.9174 3.33928 17.7924 2 14.4442 2C7.63616 2 2.0558 7.58035 2.0558 14.3884C2.0558 16.6205 2.66965 18.7411 3.72991 20.5826L2 27L8.52901 25.3259C10.3705 26.2746 12.3795 26.8326 14.4442 26.8326C21.308 26.8326 27 21.2523 27 14.4442C27 11.0959 25.5491 8.02678 23.2054 5.68304ZM14.4442 24.712C12.6027 24.712 10.817 24.2099 9.19865 23.2612L8.86378 23.038L4.95759 24.0982L6.01785 20.3035L5.73884 19.913C4.73438 18.2388 4.17634 16.3415 4.17634 14.3884C4.17634 8.75223 8.80797 4.12054 14.5 4.12054C17.2343 4.12054 19.8014 5.1808 21.7545 7.13393C23.7076 9.08705 24.8795 11.6541 24.8795 14.4442C24.8795 20.0804 20.1362 24.712 14.4442 24.712ZM20.1362 17.0112C19.8014 16.8438 18.2946 16.1182 18.0157 16.0068C17.7366 15.8951 17.5134 15.8393 17.2901 16.1741C17.1227 16.4531 16.5089 17.1785 16.3415 17.4018C16.1182 17.5692 15.9509 17.625 15.6719 17.4576C13.8304 16.5647 12.6585 15.8393 11.4308 13.7746C11.0959 13.2165 11.7657 13.2723 12.3236 12.1004C12.4353 11.8773 12.3795 11.7099 12.3236 11.5424C12.2678 11.375 11.5982 9.8683 11.375 9.25446C11.0959 8.64062 10.8727 8.69643 10.6496 8.69643C10.4822 8.69643 10.2589 8.69643 10.0915 8.69643C9.86824 8.69643 9.53351 8.75223 9.25446 9.08705C8.97541 9.42188 8.1942 10.1473 8.1942 11.6541C8.1942 13.2165 9.25446 14.6674 9.42189 14.8907C9.58932 15.058 11.5982 18.183 14.7232 19.5223C16.6764 20.4151 17.4576 20.4709 18.462 20.3035C19.0201 20.2477 20.2477 19.5781 20.5268 18.8527C20.8058 18.1273 20.8058 17.5134 20.6942 17.4018C20.6384 17.2343 20.4151 17.1785 20.1362 17.0112Z"
        fill="#47D7AC"
      />
    </svg>
  );
};

export default Whatsapp;
