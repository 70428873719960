import styled from 'styled-components';
import { colorType } from '../../../../helpers';

export default styled.div`
  .ant-float-btn-body {
    ${(props: any) =>
      `background-color:${props.theme.extractColor(colorType.blue)};`};
  }

  .ant-float-btn-body:hover {
    ${(props: any) =>
      `background-color:${props.theme.extractColor(colorType.blue)};`};
  }
`;
