import { SERVER_BASE_URL } from '../settings';
import NProgress from 'nprogress';
import { AuthStorage } from './storage';
import { useDidUpdate, useSettings } from '@opyn/utils';
import { setNProgressColor } from './libs';
import notificationClient from './network/notify';
import createClient from './network/create_client';
import Controller from './network/create_controller';
import createCallApi from './network/create_call_api';
import createUseClientApi from './network/create_use_api';
import createClientLoader from './network/create_global_loader';
import createClientDownload from './network/create_client_download';
import {
  assignStartInterceptor,
  assignSuccessInterceptor,
} from './interceptors';

NProgress.configure({ showSpinner: false });
NProgress.setColor = setNProgressColor;
NProgress.setColor('#003B5C');

export const client: any = createClient({
  baseUrl: SERVER_BASE_URL,
});

export const controller: Controller = new Controller(client);

export const callApi: any = createCallApi({
  client: client,
  controller: controller,
  notification: notificationClient,
});

export const downloadClientFile = createClientDownload({
  client: client,
  notification: notificationClient,
});

export const useClientApiHook: any = createUseClientApi({
  client: client,
  controller: controller,
  notification: notificationClient,
});

export const useClientApi = (params: any) => {
  const { language } = useSettings();
  const config = useClientApiHook(params);
  useDidUpdate(() => {
    config.fetch();
  }, [language]);
  return config;
};

createClientLoader({
  client: client,
  startAnimation: NProgress.start,
  endAnimation: NProgress.done,
});

assignStartInterceptor({
  client: client,
  getToken: () => {
    return AuthStorage.getAccessToken();
  },
});

assignSuccessInterceptor({
  client: client,
});
