import React from 'react';
import { routeIncludes } from '../How.config';
import { Popover } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useTranslator } from '@opyn/utils';
import Style from './CustomFloatButton.style';
import styled from 'styled-components';
import { colorType, extractColor } from '../../../../helpers';

const QuestionCircleOutlinedIcon = styled(QuestionCircleOutlined)`
  color: ${extractColor(colorType.light)};
`;

const CustomFloatButton = ({ links, isLoan = false, color }: any) => {
  const translator = useTranslator();

  return (
    <Style>
      {!routeIncludes('how') && typeof links !== 'string' ? (
        <Popover
          trigger="hover"
          content={
            <ul style={{ padding: '0 0 0 10px', margin: 0 }}>
              {links?.map((l: any) => {
                return (
                  <li
                    key={l.key}
                    style={{ cursor: 'pointer' }}
                    onClick={() => window.open(l.link)}
                  >
                    {translator.translate(l?.label)}
                  </li>
                );
              })}
            </ul>
          }
        >
          <QuestionCircleOutlinedIcon
            style={{ color: `${color ? color : '#fff'}` }}
          />
        </Popover>
      ) : (
        !routeIncludes('how') && (
          <QuestionCircleOutlinedIcon
            onClick={() => window.open(links)}
            style={{ color: `${color ? color : '#fff'}` }}
          />
        )
      )}
    </Style>
  );
};

export default CustomFloatButton;
