import Valsabbina_logo from '../../../../assets/images/icons/valsabbina_logo.svg';
import BrandGreen from '../../../../assets/images/icons/brand_green.svg';
import BrandBlue from '../../../../assets/images/icons/brand_blue.svg';
import opyn from '../../../../assets/images/tenants/opyn/opyn.svg';
import { Layout } from 'antd';
import { Styled } from './Layout.style';
import CustomFloatButton from '../../../common/how_to/components/CustomFloatButton.component';
import {
  customHowLink,
  routeIncludes,
  routeKey,
} from '../../../common/how_to/How.config';
import { useAuthentication } from '@opyn/utils';
import { Footer } from '../../../../components/footer';
import { isValsabbina } from '../../../../helpers/tenant';
import { ReactNode } from 'react';

interface Props {
  routes?: ReactNode;
}

const { Header } = Layout;

const PublicLayout = ({ routes }: Props) => {
  const authentication = useAuthentication();
  authentication.getAllowedTenants();

  return (
    <Styled>
      <Layout>
        {!isValsabbina && (
          <Header className="header">
            <img width={170} src={opyn} alt="logo" />
          </Header>
        )}
        <Layout.Content>
          {isValsabbina && (
            <img
              className="logo"
              width={170}
              src={Valsabbina_logo}
              alt="logo"
            />
          )}
          {routes}
          {isValsabbina && (
            <img className="logo-background-image" src={BrandBlue} alt="logo" />
          )}
          {!isValsabbina && (
            <img
              className="logo-background-image"
              src={BrandGreen}
              alt="logo"
            />
          )}
        </Layout.Content>
        <Footer>
          {!routeIncludes('signin') && !routeIncludes('signup') && (
            <CustomFloatButton links={customHowLink(routeKey())} />
          )}
        </Footer>
      </Layout>
    </Styled>
  );
};

export default PublicLayout;
