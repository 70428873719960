import { useState, useEffect } from 'react';
import { Empty, Select } from 'antd';
import { useDidUpdate, useSettings, useTranslator } from '@opyn/utils';
import { callApi, extractResultsFromQuery } from '../../../helpers';
import { useDebounce } from '../../../utils/hooks/use_debounce';
import { getValidProps } from '../index';

const allOptionValue = 'all';

interface OptionType {
  [key: string]: any;
}

interface Props {
  value?: any;
  options?: OptionType[];
  onChange?: (value: any) => void;
  onPrepare?: (response: any) => OptionType[];
  searchKey?: string;
  optionsUrl?: string;
  optionValue?: any;
  placeholder?: string;
  optionLabel?: any;
  selectMultiple?: { type: string } | boolean;
  useRemoteSearch?: boolean;
  defaultOptionLabel?: string | null;
  useInsertAllOptions?: boolean;
  onPrepareChildren?: (children: OptionType[]) => OptionType[];
  getInitialSearchTerm?: (rest: Props) => string;
  onClear?: () => void;
  minSearchLength?: number;
  cancelPreviousCalls?: boolean;
  [x: string]: any;
}

const Selection = ({
  value,
  options,
  onChange,
  onPrepare,
  optionsUrl,
  searchKey,
  optionValue = 'id',
  optionLabel = 'name',
  isFixed = false,
  showSearch = true,
  placeholder,
  selectMultiple,
  useRemoteSearch,
  onPrepareChildren,
  allowClear = true,
  defaultOptionLabel = null,
  getInitialSearchTerm,
  useInsertAllOptions = false,
  onClear,
  minSearchLength = 1,
  cancelPreviousCalls = true,
  ...rest
}: Props) => {
  const { language } = useSettings();
  const translator = useTranslator();
  const [state, setState] = useState<OptionType[]>([]);
  const [initialValue, setInitialValue] = useState<string | null>(null);
  const debounce = useDebounce();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (initialValue) return;

    setInitialValue(value);
  }, [initialValue, value]);

  useDidUpdate(() => {
    fetchResults();
  }, [language]);

  useEffect(() => {
    if (useRemoteSearch && getInitialSearchTerm) {
      const searchTerm = getInitialSearchTerm(rest);
      if (searchTerm) fetchResults(searchTerm);
    }
  }, []);

  useEffect(() => {
    if (options) setState(options);
  }, [options]);

  useEffect(() => {
    if (useRemoteSearch || !optionsUrl) return;

    fetchResults();
  }, []);

  const isArrayOfObjects =
    rest.isArrayOfObjects ||
    (selectMultiple &&
      typeof selectMultiple === 'object' &&
      selectMultiple.type === 'arrayOfObjects');

  const results = extractResultsFromQuery(state);

  if (isArrayOfObjects) {
    const extractOptionValue = (item: OptionType) => item[optionValue];

    if (Array.isArray(value)) value = value?.map(extractOptionValue);
    else if (value && typeof value === 'object')
      value = extractOptionValue(value);
  }

  const fetchResults = (searchTerm = '') => {
    if (!optionsUrl) {
      return;
    }
    if (useRemoteSearch && !searchTerm) {
      if (state) setState([]);
      return;
    }

    let url = optionsUrl;

    if (searchTerm) url += '&' + `${searchKey}=${searchTerm}`;

    setLoading(true);
    callApi({
      url: url,
      cancelPreviousCalls,
      onSuccess: (response: OptionType[]) => {
        if (onPrepare) response = onPrepare(response);
        setState(response);
        setLoading(false);
      },
      onFinish: () => setLoading(false),
    });
  };

  const formatSelection = (event: string): OptionType | OptionType[] | null => {
    const prepareOption = (item: string): OptionType => {
      const listOfOptions: OptionType[] = options || results;
      const option = listOfOptions.find(
        (record: OptionType) => record[optionValue] === item
      );
      return {
        ...option,
        [optionValue]: item,
      };
    };

    if (Array.isArray(event)) return event.map(prepareOption);
    else if (event) return prepareOption(event);
    else return null;
  };

  const onSelect = (event: any) => {
    let selected = isArrayOfObjects ? formatSelection(event) : event;

    if (useInsertAllOptions) {
      const lastPosition = event.length ? event.length - 1 : 0;

      if (Array.isArray(event) && event[lastPosition] === allOptionValue)
        selected = results.map((record: OptionType) => record[optionValue]);

      if (selected?.includes(allOptionValue) && selected.length > 1)
        selected = selected.filter(
          (option: OptionType | string) => option !== allOptionValue
        );
    }

    onChange?.(selected);
  };

  const onSearch = (searchTerm: string) => {
    if (!useRemoteSearch) return;
    else if (searchTerm.length >= minSearchLength) {
      debounce(() => {
        fetchResults(searchTerm);
      }, 500);
    }
  };

  let children = results || [];

  if (useInsertAllOptions) {
    children = [
      {
        [optionValue]: allOptionValue,
        [optionLabel]: translator.translate('allOptions.label'),
      },
      ...children,
    ];
    if (value && Array.isArray(value) && value.length === results.length)
      value = [allOptionValue];
  }

  const getAttributeValue = (
    option: any,
    attr: string | ((option: OptionType) => string)
  ) =>
    typeof option === 'object'
      ? typeof attr === 'function'
        ? attr(option)
        : option[attr]
      : option;

  if (initialValue && !selectMultiple) {
    const currentOption = results?.find(
      (option: OptionType) =>
        getAttributeValue(option, optionValue) === initialValue
    );

    if (!currentOption) {
      if (defaultOptionLabel) {
        children = [
          {
            disabled: true,
            [optionValue]: initialValue,
            [optionLabel]: defaultOptionLabel,
          },
          ...children,
        ];
      } else {
        value = null;
      }
    }
  }

  const mode: 'multiple' | 'tags' | undefined = selectMultiple
    ? 'multiple'
    : rest?.mode === 'tags'
      ? 'tags'
      : undefined;

  if (onPrepareChildren) children = onPrepareChildren(children);

  return (
    <Select
      onClear={onClear}
      mode={mode}
      {...getValidProps(rest, [
        'optionsUrl',
        'customColor',
        'labelTranslateId',
        'disabledDate',
        'languageAttr',
        'editable',
        'multiLanguage',
        'render',
      ])}
      onChange={onSelect}
      onSearch={onSearch}
      className="full-width"
      showSearch={showSearch}
      allowClear={allowClear}
      value={value || undefined}
      dropdownStyle={isFixed && { position: 'fixed' }}
      placeholder={
        placeholder ? placeholder : translator.translate('selectValue.label')
      }
      data-testid="selectValue"
      optionFilterProp="children"
      loading={loading}
      notFoundContent={
        loading ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_DEFAULT}
            description={translator.translate('loading.label')}
          />
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={translator.translate('noData.label')}
          />
        )
      }
      filterOption={(input: string | undefined, option?: OptionType) =>
        String(option?.children)
          ?.toLowerCase()
          ?.indexOf(String(input)?.toLowerCase()) >= 0
      }
    >
      {!loading &&
        children.map((option: OptionType, index: number) => {
          const value = getAttributeValue(option, optionValue);
          return (
            value && (
              <Select.Option
                key={index}
                disabled={!!option.disabled}
                data-testid={'select-' + index + '-option'}
                value={value}
              >
                {getAttributeValue(option, optionLabel)}
              </Select.Option>
            )
          );
        })}
    </Select>
  );
};

export default Selection;
