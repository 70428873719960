import React from 'react';
import { Button } from 'antd';
import StyledModal from '../../components/modals/styled_modal';
import { FullscreenOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const FirstCustomDiv = styled.div`
  position: relative;
`;

const SecondCustomDiv = styled.div`
  position: absolute;
  top: -32px;
  right: 0;
`;

interface Props {
  title?: string;

  [x: string]: any;
}

const withZoom =
  (Component: React.ComponentType<Props>) =>
  ({ title, ...rest }: Props) => {
    const [visible, setVisible] = React.useState(false);

    const closeModal = () => {
      setVisible(false);
    };

    const openModal = () => {
      setVisible(true);
    };

    return (
      <FirstCustomDiv>
        <Component {...rest} />
        <div>
          <SecondCustomDiv>
            <Button
              onClick={openModal}
              size="small"
              icon={<FullscreenOutlined />}
            />
          </SecondCustomDiv>
        </div>
        <StyledModal
          title={title}
          visible={visible}
          onOk={closeModal}
          onCancel={closeModal}
        >
          <Component rows={8} {...rest} />
        </StyledModal>
      </FirstCustomDiv>
    );
  };

export default withZoom;
