import { Button, Col, Row } from 'antd';
import { Typography } from '../../index';
import styled from 'styled-components';
import { DeleteOutlined, PaperClipOutlined } from '@ant-design/icons';
import { useTranslator } from '@opyn/utils';
import {
  colorType,
  extractColor,
  formatDate,
  typographyType,
} from '../../../helpers';

const PaperClipIcon = styled(PaperClipOutlined)`
  font-size: 30px;
`;

const DeleteIcon = styled(DeleteOutlined)`
  font-size: 22px;
  color: ${extractColor(colorType.danger)};
`;

interface IFile {
  id?: number;
  name?: string;
  fileName?: string;
  lastModifiedDate?: Date;
}

interface Props {
  file?: IFile;
  onRemove?: (id?: number) => void;
}

const SingleFile = ({ file, onRemove }: Props) => {
  const translator = useTranslator();

  return (
    <Row gutter={[16, 16]} justify="space-between" align="middle">
      <Col xs={4}>
        <PaperClipIcon />
      </Col>
      <Col xs={16}>
        <Typography variant={typographyType.h3}>
          {file?.name || file?.fileName}
        </Typography>
        {file?.lastModifiedDate && (
          <Typography variant={typographyType.span} level={2}>
            {translator.translate('lastEdited.label')} :{' '}
            {formatDate(file.lastModifiedDate as Date)}
          </Typography>
        )}
      </Col>
      <Col className="flex-end">
        <Button
          type="link"
          onClick={() => {
            onRemove?.(file?.id);
          }}
          icon={<DeleteIcon />}
        />
      </Col>
    </Row>
  );
};

export default SingleFile;
