import { getTenantBasedOnPath } from '@opyn/utils';
import { globalSettings } from '../constants/settings';
import { TenantModel } from '../redux-store/reducers/settings/settings.types';

export const storage = {
  get: (attr: string) => {
    try {
      return localStorage.getItem(attr);
    } catch (e) {
      console.error(`Unable to retrieve ${attr} from cache`);
      return null;
    }
  },
  set: (attr: string, value: string) => {
    try {
      localStorage.setItem(attr, value);
    } catch (e) {
      console.error(`Unable to store ${attr} on cache`);
    }
  },
  clear: (attr: string) => {
    try {
      localStorage.removeItem(attr);
    } catch (e) {
      console.error(`Unable to clear ${attr} on cache`);
    }
  },
  exist: (attr: string) => {
    return Object.prototype.hasOwnProperty.call(localStorage, attr);
  },
};

class AuthStorage {
  static getAccessToken = () => {
    const tenant = getTenantBasedOnPath();
    return storage.get(`${tenant?.id}-accessToken`);
  };

  static existsAccessToken = (tenant: TenantModel) => {
    return storage.get(`${tenant?.id}-accessToken`);
  };

  static setAccessToken = (token: string) => {
    const tenant = getTenantBasedOnPath();
    return storage.set(`${tenant?.id}-accessToken`, token);
  };

  static clearAccessToken = (tenant: TenantModel) => {
    return storage.clear(`${tenant?.id}-accessToken`);
  };

  static getRefreshToken = () => {
    return storage.get('refreshToken');
  };

  static setRefreshToken = (token: string) => {
    return storage.set('refreshToken', token);
  };

  static clearRefreshToken = () => {
    return storage.clear('refreshToken');
  };

  static clearTokens = () => {
    AuthStorage.clearRefreshToken();
    globalSettings.tenants?.forEach(AuthStorage.clearAccessToken);
  };
}

export { AuthStorage };
