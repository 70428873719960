import { AuthStorage, callApi, PUBLIC_API_PATHS, storage } from '../../helpers';
import {
  AuthModel,
  LoginRequestBody,
  RefreshTokenRequestBody,
} from '../reducers/auth/auth.types';

export const login = async (body: LoginRequestBody): Promise<AuthModel> => {
  return await callApi({ url: PUBLIC_API_PATHS.LOGIN, method: 'POST', body });
};

export const refreshToken = async (
  body: RefreshTokenRequestBody
): Promise<AuthModel> => {
  const resfresh = await callApi({
    url: PUBLIC_API_PATHS.REFRESH_TOKEN,
    method: 'POST',
    body,
  });
  if (!resfresh) {
    throw new Error(`HTTP error on refreshtoken`);
  }

  return resfresh;
};

export const logout = (): void => {
  if (storage.get('hcoupon')) {
    storage.clear('hcoupon');
  }

  storage.clear('loanSessionId');
  AuthStorage.clearTokens();
};
