import React from 'react';
import { Col, Divider, Result, Row, Skeleton } from 'antd';
import FormLayout from '../../../components/journey/form_layout/FormLayout.component';
import Editor from '../../../../../../components/editor';
import { useStepper } from '../../../../../../components/stepper';
import useSmartForm from '../../../../../../utils/hooks/use_form';
import { useDebounce } from '../../../../../../utils/hooks/use_debounce';
import {
  callApi,
  extractResultsFromQuery,
  storage,
  useClientApi,
  withQuery,
} from '../../../../../../helpers';
import {
  useDidUpdate,
  useLanguage,
  useSettings,
  useTranslator,
} from '@opyn/utils';
import { getSchema } from './ApplicationRequest.schema';
import { notification } from '../../../../../../helpers/network/config';
import { customColor, isAzimut } from '../../../../../../helpers/tenant';

interface Props {
  stepId?: any;
  borrower?: any;
  application?: any;
  isCompleted?: any;
  completeStep?: any;
  mandatoryFields?: any;
  onApplicationCreate?: any;
  isFirstBorrower?: boolean;
  azimutMarketPlaceData?: any;
}

const BrokerageSection = (props: any) => {
  const { form, schema, setDisableSubmit, azimutMarketPlaceData } = props;
  const debounce = useDebounce();
  const translator = useTranslator();
  const [fee, setFee] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const conventionCode = form.values[schema.key.conventionCode];
  const { tenant } = useSettings();

  const getBrokerageFee = () => {
    setLoading(true);
    setDisableSubmit(true);
    debounce(() => {
      callApi({
        method: 'POST',
        url: '/convention-code/validate',
        body: {
          conventionCode: conventionCode,
        },
        onSuccess: (response: any) => {
          setDisableSubmit(false);
          setFee(response.hasBrokerageFee);
          if (response.message) notification.warning(response.message);
        },
        onError: () => {
          setFee(false);
          if (conventionCode) setDisableSubmit(true);
        },
        onFinish: () => {
          setLoading(false);
        },
      });
    }, 500);
  };

  React.useEffect(() => {
    const value = conventionCode && conventionCode.trim();

    if (
      isAzimut &&
      (azimutMarketPlaceData ||
        storage.get('hcoupon') ||
        value === azimutMarketPlaceConventionCode)
    ) {
      return;
    }

    // User should not come from either Azimut marketplace signin/up to be able to have brokerage fee

    if (value) {
      getBrokerageFee();
    } else {
      form.handleChange({ value: null, name: schema.key.conventionCode });
      setDisableSubmit(false);
    }
  }, [conventionCode]);

  return (
    <>
      <Col xs={12}>
        <Editor
          disabled={
            isAzimut && (azimutMarketPlaceData || storage.get('hcoupon'))
          }
          helper
          search
          loading={loading}
          type={Editor.type.string}
          {...form.register(schema.key.conventionCode)}
          label={translator.translate('conventionCode.label')}
          placeholder={translator.translate('conventionCode.label')}
          customColor={customColor}
          data-cy="askingConventionCode"
        />
      </Col>
      {fee && (
        <Col xs={12}>
          <Editor
            isPercentage
            type={Editor.type.number}
            {...form.register(schema.key.brokerageFee)}
            label={translator.translate('brokerageFee.label')}
            placeholder={translator.translate('brokerageFee.label')}
            customColor={customColor}
            data-cy="brokerageFee"
          />
        </Col>
      )}
    </>
  );
};

const checkPrefill = (isFirstBorrower?: boolean) => {
  return !!(isFirstBorrower && isAzimut && localStorage.getItem('simulator'));
};

export const azimutMarketPlaceConventionCode = 'azmktplc';

const ApplicationRequest = ({
  stepId,
  borrower,
  isCompleted,
  application,
  completeStep,
  mandatoryFields,
  onApplicationCreate,
  isFirstBorrower,
  azimutMarketPlaceData,
}: Props) => {
  const { onNext } = useStepper();
  const translator = useTranslator();

  const products = useClientApi({
    url: withQuery('/administration/products', { IsDefault: true }),
  });

  // Initial data filled when user has used our simulator

  const emporiumSimulator: any = checkPrefill(isFirstBorrower)
    ? localStorage.getItem('simulator')
    : undefined;

  // Prefilled Convention Code when user has come from Azimut simulator, turn this into a custom hook

  React.useEffect(() => {
    if (isAzimut) {
      if (azimutMarketPlaceData) {
        form.setValue('conventionCode', azimutMarketPlaceConventionCode);
        return;
      }

      if (storage.get('hcoupon')) {
        form.setValue('conventionCode', azimutMarketPlaceConventionCode);
        form.setValue('IsFromAzimutMarketplace', true);
        return;
      }
    }
  }, [azimutMarketPlaceData]);

  const productsData = extractResultsFromQuery(products);

  const brokerageFee = useClientApi({
    url: '/administration/brokerage-fee',
  });

  const schema = getSchema(translator.translate, productsData, brokerageFee);
  const purposeDetails = useClientApi({ url: null, fetchOnMount: false });

  const [disableSubmit, setDisableSubmit] = React.useState(false);

  const form = useSmartForm({
    initialValues: {
      [schema.key.brokerageFee]:
        application[schema.key.brokerageFee] * 100 || null,
      [schema.key.requestedTenor]: emporiumSimulator
        ? JSON.parse(emporiumSimulator)?.AmortizationPeriod
        : application?.[schema.key.requestedTenor] -
            application?.[schema.key.preAmortizationPeriod] || undefined,
      [schema.key.conventionCode]: application[schema.key.conventionCode],
      [schema.key.requestedAmount]: emporiumSimulator
        ? JSON.parse(emporiumSimulator)?.RequestedAmount
        : application[schema.key.requestedAmount],
      [schema.key.financePurposeId]: application[schema.key.financePurposeId],
      [schema.key.financePurposeDetailId]:
        application[schema.key.financePurposeDetailId],
      [schema.key.financePurposeDescription]:
        application[schema.key.financePurposeDescription],
      [schema.key.IsFromAzimutMarketplace]: false,
    },
    onFinish: (values: any) => {
      if (isCompleted) {
        onNext?.();
        return;
      }

      const filters = {
        stepId: stepId,
        ...mandatoryFields,
      };

      if (mandatoryFields.businessProcess === 2) {
        delete filters.stepId;
        delete filters.entityId;
      }
      setDisableSubmit(true);
      callApi({
        method: 'POST',
        onSuccess: (response: any) => {
          if (localStorage.getItem('simulator')) {
            localStorage.removeItem('simulator');
          }

          if (storage.get('hcoupon')) {
            storage.clear('hcoupon');
          }

          if (onApplicationCreate) onApplicationCreate(response);
          completeStep();
        },
        body: getBodyParams(values),
        url: withQuery(`/borrower/${borrower.id}/applications`, filters),
        onFinish: () => {
          setDisableSubmit(false);
        },
      });
    },
    validationSchema: schema.shape,
  });

  const language = useLanguage();

  const getBodyParams = (values: any) => {
    return {
      borrowerId: borrower.id,
      productId: application.productId,
      [schema.key.requestedTenor]: values[schema.key.requestedTenor],
      [schema.key.conventionCode]: values[schema.key.conventionCode],
      [schema.key.requestedAmount]: values[schema.key.requestedAmount],
      [schema.key.financePurposeId]: values[schema.key.financePurposeId],
      [schema.key.brokerageFee]: values[schema.key.brokerageFee],
      [schema.key.financePurposeDetailId]:
        values[schema.key.financePurposeDetailId],
      [schema.key.financePurposeDescription]:
        values[schema.key.financePurposeDescription],
      [schema.key.IsFromAzimutMarketplace]:
        values[schema.key.IsFromAzimutMarketplace],
    };
  };

  useDidUpdate(() => {
    /*Reset the field only of purpose id has changed*/
    form.setValue(schema.key.financePurposeDetailId, null);
  }, [form.values[schema.key.financePurposeId]]);

  React.useEffect(() => {
    /*Fetch the action_details when selecting  purpose*/

    const selectedPurpose = form.values[schema.key.financePurposeId];

    if (selectedPurpose)
      purposeDetails.fetch({
        url: `/financing-purpose/${selectedPurpose}/financing-purpose-details`,
      });
  }, [form.values[schema.key.financePurposeId], language]);

  const handleDisableSubmit = () => {
    return !!(
      form.register(schema.key.requestedAmount).error ||
      form.register(schema.key.requestedTenor).error ||
      form.register(schema.key.financePurposeId).error ||
      form.register(schema.key.brokerageFee).error ||
      form.register(schema.key.financePurposeDetailId).error
    );
  };

  if (products.loading) {
    return <Skeleton />;
  }

  if (!products.loading && !products.data) {
    return (
      <Result
        status="500"
        title="500"
        subTitle={translator.translate('requestError.label')}
      />
    );
  }

  return (
    <FormLayout
      onSubmit={form.handleSubmit}
      disableSubmit={!isCompleted && (disableSubmit || handleDisableSubmit())}
    >
      <Row gutter={[16, 16]}>
        <Col xs={12}>
          <Editor
            required
            type={Editor.type.currency}
            {...form.register(schema.key.requestedAmount)}
            label={translator.translate('requestedAmount.label')}
            placeholder={translator.translate('requestedMoney.label')}
            customColor={customColor}
            data-cy="requestedMoney"
          />
        </Col>
        <Col xs={12}>
          <Editor
            required
            type={Editor.type.number}
            {...form.register(schema.key.requestedTenor)}
            label={translator.translate('amortizationPeriod.label')}
            placeholder={translator.translate('amortizationPeriod.label')}
            customColor={customColor}
            data-cy="requestedTenor"
          />
        </Col>
        <Col xs={12}>
          <Editor
            required
            isFixed={true}
            type={Editor.type.selection}
            optionsUrl="/financing-purpose"
            {...form.register(schema.key.financePurposeId)}
            label={translator.translate('financingPurpose.label')}
            placeholder={translator.translate('selectFinancingPurpose.label')}
            customColor={customColor}
            data-cy="askingConventionCode"
          />
        </Col>
        <Col xs={12}>
          <Editor
            required
            isFixed={true}
            type={Editor.type.selection}
            options={purposeDetails.data}
            {...form.register(schema.key.financePurposeDetailId)}
            disabled={!form.values[schema.key.financePurposeId]}
            label={translator.translate('financingPurposeDetails.label')}
            placeholder={translator.translate(
              'selectFinancingPurposeDetail.label'
            )}
            customColor={customColor}
            data-cy="financingPurposeLabel"
          />
        </Col>
        <Col xs={24}>
          <Editor
            type={Editor.type.string}
            {...form.register(schema.key.financePurposeDescription)}
            label={translator.translate('financingPurposeDescription.label')}
            placeholder={translator.translate(
              'financingPurposeDescription.label'
            )}
            customColor={customColor}
            data-cy="moreFinancingInfo"
          />
        </Col>
        <Divider />
        <BrokerageSection
          azimutMarketPlaceData={azimutMarketPlaceData}
          form={form}
          schema={schema}
          setDisableSubmit={setDisableSubmit}
        />
      </Row>
    </FormLayout>
  );
};

export default ApplicationRequest;
