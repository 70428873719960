import { ReactNode } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row } from 'antd';
import styled from 'styled-components';
import { useTranslator } from '@opyn/utils';
import { colorType, extractColor } from '../../../helpers';
import { customColor } from '../../../helpers/tenant';

const CustomCol = styled(Col)`
  font-size: 24px;
  font-weight: bold;
  color: ${customColor};
`;

type TCancelConfirm = {
  translateId: string;
};

const CloseOutlinedIcon = styled(CloseOutlined)`
  font-size: 16px;
  color: ${extractColor(colorType.secondary, 3)};
`;

interface FooterProps {
  cancel: TCancelConfirm;
  confirm: TCancelConfirm;
}

const Footer = (props: FooterProps) => {
  const translator = useTranslator();
  const { cancel, confirm } = props;

  return (
    <Row>
      <Button type="primary" ghost>
        {translator.translate(cancel.translateId)}
      </Button>
      <Button type="primary" className="ant-button-emp">
        {translator.translate(confirm.translateId)}
      </Button>
      <Button type="primary" className="ant-button-emp">
        {translator.translate(confirm.translateId)}
      </Button>
    </Row>
  );
};

interface Props {
  title?: string;
  footer?: boolean | null;
  visible?: boolean;
  primary?: boolean;
  closable?: boolean;
  children?: ReactNode;
  onCancel?: () => void;
  centered?: boolean;
  secondary?: boolean;
  destroyOnClose?: boolean;
  showModalHeader?: boolean;
  closecb?: () => void;
  [x: string]: any;
}

const StyledModal = (props: Props) => {
  const { title, closable = true, primary = true } = props;

  return (
    <Modal
      {...props}
      title={null}
      closable={false}
      className="modalStyle"
      afterClose={() => {
        if (props.closecb) {
          props.closecb();
        }
      }}
    >
      {title && (
        <Row justify="space-between">
          {title && <CustomCol xs={20}>{title}</CustomCol>}
          <Col>
            {closable && (
              <Button
                type="link"
                onClick={props.onCancel || undefined}
                icon={<CloseOutlinedIcon />}
              />
            )}
          </Col>
        </Row>
      )}
      {props.children}
    </Modal>
  );
};

StyledModal.Footer = Footer;

export default StyledModal;
