import { colorType, extractColor } from '../../../helpers';
import styled from 'styled-components';

export default styled.table`
  width: 100%;

  thead th {
    font-size: 14px;
    font-weight: 500;
    padding: 4px 4px 0px 4px;
    color: ${extractColor(colorType.primary)};
  }

  tbody td {
    padding: 4px;
  }
`;
