import React, { ReactNode } from 'react';
import { DefaultTheme, useTheme } from 'styled-components';
import { typographyStyles } from './config';
import { colorType as initialColorType, typographyType } from '../../helpers';
import { CustomTheme } from '@opyn/utils';

interface Props {
  level?: number;
  style?: object;
  variant?: any;
  customColor?: any;
  children?: ReactNode;
  colorType?: any;
  className?: string;
}

const Typography = ({
  style,
  className = '',
  level = 3,
  variant,
  children,
  colorType = initialColorType.primary,
  customColor,
}: Props) => {
  const theme: DefaultTheme = useTheme();
  let customStyle: object;

  if (Object.prototype.hasOwnProperty.call(typographyType, variant)) {
    customStyle = typographyStyles[variant];

    return React.createElement(
      typographyType[variant],
      {
        style: {
          margin: 0,
          padding: 0,
          ...customStyle,
          color: customColor
            ? customColor
            : (theme as CustomTheme)?.extractColor(colorType, 3),
          ...style,
        },
        className,
      },
      children
    );
  }

  return <div>{children}</div>;
};

export default Typography;
