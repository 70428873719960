import styled from 'styled-components';

interface SwitchProps {
  $primaryColor: any;
  $secondaryColor: any;
  $checked?: boolean;
}

export default styled.div<SwitchProps>`
  transition: background-color 1000ms linear;

  .ant-switch-checked {
    background-color: ${(props) => props.$primaryColor} !important;
  }

  .ant-switch {
    margin: 0;
    padding: 0;
    ${(props) =>
      !props.$checked && `background-color:${props.$secondaryColor};`};
  }
`;
