export const onDownload = (data: any, filename: any, mime?: any, bom?: any) => {
  const blobData = typeof bom !== 'undefined' ? [bom, data] : [data];
  const blob = new Blob(blobData, { type: mime || 'application/octet-stream' });
  // @ts-expect-error ignore
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    // @ts-expect-error ignore
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const blobURL =
      window.URL && window.URL.createObjectURL
        ? window.URL.createObjectURL(blob)
        : window.webkitURL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);

    // Safari thinks _blank anchor are popups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }

    document.body.appendChild(tempLink);
    tempLink.click();

    // Fixes "webkit blob resource error 1"
    setTimeout(function () {
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
    }, 200);
  }
};

interface Props {
  url?: any;
  link?: any;
  client?: any;
  method?: any;
  baseURL?: any;
  onError?: any;
  headers?: any;
  filename?: any;
  onFinish?: any;
  notification?: any;
  onSuccess?: any;

  [x: string]: any;
}

export default ({ client, baseURL, notification }: Props) =>
  ({
    url,
    headers,
    filename,
    onFinish,
    onSuccess,
    onError,
    method = 'GET',
    link = false,
    ...rest
  }: Props) => {
    if (link) {
      const linkRef = document.createElement('a');
      linkRef.setAttribute('download', filename);
      linkRef.href = url;
      document.body.appendChild(linkRef);
      linkRef.click();
      linkRef.remove();
    } else {
      const params = {
        ...rest,
        url: url,
        method: method,
        responseType: 'blob',
        headers: { ...headers },
      };

      if (baseURL) params.baseURL = baseURL;

      client(params)
        .then((data: any) => {
          onDownload(data, filename);
          if (onSuccess) onSuccess(data);
        })
        .catch((error: any) => {
          if (
            error.request.responseType === 'blob' &&
            error.response.data instanceof Blob &&
            error.response.data.type &&
            error.response.data.type.toLowerCase().indexOf('json') != -1
          ) {
            return new Promise((resolve, reject) => {
              const reader: any = new FileReader();
              reader.onload = () => {
                error.response.data = JSON.parse(reader?.result);
                notification.error(error?.response.data.message);
                resolve(Promise.reject(error));
              };
              reader.onerror = () => {
                reject(error);
              };
              reader.readAsText(error.response.data);
            });
          }
          // onError && onError(e)
          // notification.error(e?.message)
        })
        .finally(() => onFinish && onFinish());
    }
  };

export const downloadBase64File = (pdf: any, fileName: any) => {
  const linkSource = `data:application/pdf;base64,${pdf}`;
  const downloadLink = document.createElement('a');
  const name = fileName;
  downloadLink.href = linkSource;
  downloadLink.download = name;
  downloadLink.click();
};
