import { AxiosInstance } from 'axios';
import { withPageSize } from '../url';
import { getTenantBasedOnPath } from '@opyn/utils';
import { PUBLIC_API_PATHS } from '../apiPaths.constants';

interface CreateInterceptorParams {
  client: AxiosInstance;
  getToken: () => string | null;
}

const createInterceptor = ({ client, getToken }: CreateInterceptorParams) => {
  const attachTokenToRequest = (request: any, token: any) => {
    const publicApis = [
      PUBLIC_API_PATHS.REFRESH_TOKEN,
      withPageSize('/administration/nations/public'),
    ];

    const isPublicApi = publicApis.includes(request.url);
    if (isPublicApi) delete request.headers.authorization;
    else if (token) request.headers['Authorization'] = 'Bearer ' + token;
  };

  const attachTenantIdToRequest = (request: any) => {
    if (!Object.prototype.hasOwnProperty.call(request.headers, 'x-tenant-id')) {
      const tenant = getTenantBasedOnPath();
      request.headers['x-tenant-id'] = tenant?.id;
    }
  };

  const attachLanguageToRequest = (request: any) => {
    request.headers['Accept-Language'] = localStorage.getItem('language');
  };

  const onRequestStart = (request: any) => {
    const token = getToken();
    attachTokenToRequest(request, token);
    attachLanguageToRequest(request);
    attachTenantIdToRequest(request);
    return request;
  };

  return client.interceptors.request.use(onRequestStart);
};

export default createInterceptor;
