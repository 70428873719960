export enum permissionKey {
  'AML.Create' = 'AML.Create',
  'AML.View' = 'AML.View',
  'AML.Edit' = 'AML.Edit',
  'AMLSOS.Create' = 'AMLSOS.Create',
  'AMLSOS.View' = 'AMLSOS.View',
  'AMLSOS.Edit' = 'AMLSOS.Edit',
  'AMLSOS.Delete' = 'AMLSOS.Delete',
  'AMLSOS.SOSCreator' = 'AMLSOS.SOSCreator',
  'AMLSOS.SOSApprover' = 'AMLSOS.SOSApprover',
  'CurrentAccount.Create' = 'CurrentAccount.Create',
  'CurrentAccount.View' = 'CurrentAccount.View',
  'CurrentAccount.Edit' = 'CurrentAccount.Edit',
  'CurrentAccount.Delete' = 'CurrentAccount.Delete',
  'Product.Create' = 'Product.Create',
  'Product.View' = 'Product.View',
  'Product.Edit' = 'Product.Edit',
  'Product.Delete' = 'Product.Delete',
  'ProductConfiguration.Create' = 'ProductConfiguration.Create',
  'ProductConfiguration.View' = 'ProductConfiguration.View',
  'ProductConfiguration.Edit' = 'ProductConfiguration.Edit',
  'ProductConfiguration.Delete' = 'ProductConfiguration.Delete',
  'Application.Create' = 'Application.Create',
  'Application.View' = 'Application.View',
  'Application.Edit' = 'Application.Edit',
  'Contact.Create' = 'Contact.Create',
  'Contact.View' = 'Contact.View',
  'Contact.Edit' = 'Contact.Edit',
  'Contact.Delete' = 'Contact.Delete',
  'Lookup.Create' = 'Lookup.Create',
  'Lookup.View' = 'Lookup.View',
  'Lookup.Edit' = 'Lookup.Edit',
  'Lookup.Delete' = 'Lookup.Delete',
  'Checklist.Create' = 'Checklist.Create',
  'Checklist.View' = 'Checklist.View',
  'Checklist.Edit' = 'Checklist.Edit',
  'Checklist.Delete' = 'Checklist.Delete',
  'RequestedChecklist.View' = 'RequestedChecklist.View',
  'RequestedChecklist.FirstApprover' = 'RequestedChecklist.FirstApprover',
  'RequestedChecklist.SecondApprover' = 'RequestedChecklist.SecondApprover',
  'RegistryInfomation.Create' = 'RegistryInfomation.Create',
  'RegistryInfomation.View' = 'RegistryInfomation.View',
  'RegistryInfomation.Edit' = 'RegistryInfomation.Edit',
  'RegistryInfomation.Delete' = 'RegistryInfomation.Delete',
  'Borrower.Create' = 'Borrower.Create',
  'Borrower.View' = 'Borrower.View',
  'DocumentsConfiguration.Create' = 'DocumentsConfiguration.Create',
  'DocumentsConfiguration.View' = 'DocumentsConfiguration.View',
  'DocumentsConfiguration.Edit' = 'DocumentsConfiguration.Edit',
  'DocumentsConfiguration.Delete' = 'DocumentsConfiguration.Delete',
  'DocumentsManagement.ViewRequested' = 'DocumentsManagement.ViewRequested',
  'DocumentsManagement.ViewProviders' = 'DocumentsManagement.ViewProviders',
  'DocumentsManagement.ViewManaged' = 'DocumentsManagement.ViewManaged',
  'DocumentsManagement.ViewDocumentsForUpload' = 'DocumentsManagement.ViewDocumentsForUpload',
  'DocumentsManagement.Download' = 'DocumentsManagement.Download',
  'DocumentsManagement.Approve' = 'DocumentsManagement.Approve',
  'DocumentsManagement.Cancel' = 'DocumentsManagement.Cancel',
  'DocumentsManagement.Reject' = 'DocumentsManagement.Reject',
  'DocumentsManagement.UploadRequested' = 'DocumentsManagement.UploadRequested',
  'DocumentsManagement.UploadManaged' = 'DocumentsManagement.UploadManaged',
  'DocumentsManagement.Delete' = 'DocumentsManagement.Delete',
  'DocumentsManagement.OverrideExpiry' = 'DocumentsManagement.OverrideExpiry',
  'DocumentsManagement.OverrideUploadedDocument' = 'DocumentsManagement.OverrideUploadedDocument',
  'DocumentsManagement.ChangeDocumentReference' = 'DocumentsManagement.ChangeDocumentReference',
  'DocumentsManagement.RequestNewDocument' = 'DocumentsManagement.RequestNewDocument',
  'FinancialInformation.Create' = 'FinancialInformation.Create',
  'FinancialInformation.View' = 'FinancialInformation.View',
  'FinancialInformation.Edit' = 'FinancialInformation.Edit',
  'FinancialInformation.Delete' = 'FinancialInformation.Delete',
  'ABIBlacklist.Create' = 'ABIBlacklist.Create',
  'ABIBlacklist.View' = 'ABIBlacklist.View',
  'ABIBlacklist.Edit' = 'ABIBlacklist.Edit',
  'ABIBlacklist.Delete' = 'ABIBlacklist.Delete',
  'Loan.Create' = 'Loan.Create',
  'Loan.View' = 'Loan.View',
  'Loan.Edit' = 'Loan.Edit',
  'Lender.View' = 'Lender.View',
  'LenderMatrix.Create' = 'LenderMatrix.Create',
  'LenderMatrix.View' = 'LenderMatrix.View',
  'LenderMatrix.Edit' = 'LenderMatrix.Edit',
  'LenderMatrix.Delete' = 'LenderMatrix.Delete',
  'LenderHistory.Create' = 'LenderHistory.Create',
  'LenderHistory.View' = 'LenderHistory.View',
  'LenderHistory.Edit' = 'LenderHistory.Edit',
  'LenderHistory.Delete' = 'LenderHistory.Delete',
  'IbanInsertion.Create' = 'IbanInsertion.Create',
  'IbanInsertion.View' = 'IbanInsertion.View',
  'IbanInsertion.Edit' = 'IbanInsertion.Edit',
  'Fatca.Edit' = 'Fatca.Edit',
  'Fatca.View' = 'Fatca.View',
  'DocumentAcceptance.Create' = 'DocumentAcceptance.Create',
  'DocumentAcceptance.View' = 'DocumentAcceptance.View',
  'ContractSign.Create' = 'ContractSign.Create',
  'LoanConfirmation.Create' = 'LoanConfirmation.Create',
  'LoanConfirmation.Edit' = 'LoanConfirmation.Edit',
  'AmortizationPlan.View' = 'AmortizationPlan.View',
  'AmortizationPlan.Edit' = 'AmortizationPlan.Edit',
  'Identity.Create' = 'Identity.Create',
  'Identity.View' = 'Identity.View',
  'Identity.Edit' = 'Identity.Edit',
  'Identity.Delete' = 'Identity.Delete',
  'Survey.Create' = 'Survey.Create',
  'Survey.View' = 'Survey.View',
  'Survey.Edit' = 'Survey.Edit',
  'Survey.Delete' = 'Survey.Delete',
  'KYC.Create' = 'KYC.Create',
  'KYC.View' = 'KYC.View',
  'KYC.Edit' = 'KYC.Edit',
  'KYC.AnswerEdit' = 'KYC.AnswerEdit',
  'UserInvitation.Create' = 'UserInvitation.Create',
  'UserInvitation.View' = 'UserInvitation.View',
  'UserInvitation.Edit' = 'UserInvitation.Edit',
  'UserInvitation.Approve' = 'UserInvitation.Approve',
  'UserInvitation.Delete' = 'UserInvitation.Delete',
  'FDGCompanySize.Create' = 'FDGCompanySize.Create',
  'FDGCompanySize.View' = 'FDGCompanySize.View',
  'FDGCompanySize.Edit' = 'FDGCompanySize.Edit',
  'FDGCompanySize.Delete' = 'FDGCompanySize.Delete',
  'FDGCompanyType.Create' = 'FDGCompanyType.Create',
  'FDGCompanyType.View' = 'FDGCompanyType.View',
  'FDGCompanyType.Edit' = 'FDGCompanyType.Edit',
  'FDGCompanyType.Delete' = 'FDGCompanyType.Delete',
  'FDGGuaranteeStatus.Create' = 'FDGGuaranteeStatus.Create',
  'FDGGuaranteeStatus.View' = 'FDGGuaranteeStatus.View',
  'FDGGuaranteeStatus.Edit' = 'FDGGuaranteeStatus.Edit',
  'FDGGuaranteeStatus.Delete' = 'FDGGuaranteeStatus.Delete',
  'ProofOfClaim.Create' = 'ProofOfClaim.Create',
  'ProofOfClaim.View' = 'ProofOfClaim.View',
  'ProofOfClaim.Edit' = 'ProofOfClaim.Edit',
  'GuaranteeActivation.Create' = 'GuaranteeActivation.Create',
  'GuaranteeActivation.View' = 'GuaranteeActivation.View',
  'FDGGuarantee.Create' = 'FDGGuarantee.Create',
  'FDGGuarantee.View' = 'FDGGuarantee.View',
  'FDGGuarantee.Edit' = 'FDGGuarantee.Edit',
  'FDGGuarantee.Delete' = 'FDGGuarantee.Delete',
  'FDGGuaranteeFinancedAmount.Create' = 'FDGGuaranteeFinancedAmount.Create',
  'FDGGuaranteeFinancedAmount.View' = 'FDGGuaranteeFinancedAmount.View',
  'FDGGuaranteeFinancedAmount.Edit' = 'FDGGuaranteeFinancedAmount.Edit',
  'FDGGuaranteeFinancedAmount.Delete' = 'FDGGuaranteeFinancedAmount.Delete',
  'CreditHistory.View' = 'CreditHistory.View',
  'CreditHistory.Create' = 'CreditHistory.Create',
  'CreditHistory.Edit' = 'CreditHistory.Edit',
  'CreditHistory.Insert' = 'CreditHistory.Insert',
  'CreditHistory.Delete' = 'CreditHistory.Delete',
  'FDGIndex.Calculate' = 'FDGIndex.Calculate',
  'FDGRating.View' = 'FDGRating.View',
  'ActivitiesManagement.Create' = 'ActivitiesManagement.Create',
  'ActivitiesManagement.View' = 'ActivitiesManagement.View',
  'ActivitiesManagement.Edit' = 'ActivitiesManagement.Edit',
  'ActivitiesManagement.Delete' = 'ActivitiesManagement.Delete',
  'WebReputation.Create' = 'WebReputation.Create',
  'WebReputation.View' = 'WebReputation.View',
  'WebReputation.Edit' = 'WebReputation.Edit',
  'WebReputation.Delete' = 'WebReputation.Delete',
  'Scorecard.Create' = 'Scorecard.Create',
  'Scorecard.View' = 'Scorecard.View',
  'Scorecard.Edit' = 'Scorecard.Edit',
  'Scorecard.Delete' = 'Scorecard.Delete',
  'LegalCheck.Create' = 'LegalCheck.Create',
  'LegalCheck.View' = 'LegalCheck.View',
  'LegalCheck.Edit' = 'LegalCheck.Edit',
  'LegalCheck.Delete' = 'LegalCheck.Delete',
  'FinancialApproval.View' = 'FinancialApproval.View',
  'FinancialApproval.Edit' = 'FinancialApproval.Edit',
  'CentraleRischi.Create' = 'CentraleRischi.Create',
  'CentraleRischi.View' = 'CentraleRischi.View',
  'Guarantee.Create' = 'Guarantee.Create',
  'Guarantee.View' = 'Guarantee.View',
  'Guarantee.Edit' = 'Guarantee.Edit',
  'Guarantee.Delete' = 'Guarantee.Delete',
  'SaaSConfiguration.View' = 'SaaSConfiguration.View',
  'SaaSConfiguration.Edit' = 'SaaSConfiguration.Edit',
  'FDGLoanLifeCycle.Create' = 'FDGLoanLifeCycle.Create',
  'FDGLoanLifeCycle.View' = 'FDGLoanLifeCycle.View',
  'FDGLoanLifeCycle.Edit' = 'FDGLoanLifeCycle.Edit',
  'FDGLoanLifeCycle.Delete' = 'FDGLoanLifeCycle.Delete',
  'EmailTemplate.Create' = 'EmailTemplate.Create',
  'EmailTemplate.View' = 'EmailTemplate.View',
  'EmailTemplate.Edit' = 'EmailTemplate.Edit',
  'RequestedChecklistAML.View' = 'RequestedChecklistAML.View',
  'RequestedChecklistAML.FirstApprover' = 'RequestedChecklistAML.FirstApprover',
  'RequestedChecklistAML.SecondApprover' = 'RequestedChecklistAML.SecondApprover',
  'Monitoring.Create' = 'Monitoring.Create',
  'Monitoring.View' = 'Monitoring.View',
  'Monitoring.Edit' = 'Monitoring.Edit',
  'Monitoring.Delete' = 'Monitoring.Delete',
  'ScorecardConfiguration.Create' = 'ScorecardConfiguration.Create',
  'ScorecardConfiguration.View' = 'ScorecardConfiguration.View',
  'ScorecardConfiguration.Edit' = 'ScorecardConfiguration.Edit',
  'ScorecardConfiguration.Delete' = 'ScorecardConfiguration.Delete',
  'ActivitiesManagementConfiguration.View' = 'ActivitiesManagementConfiguration.View',
  'ActivitiesManagementConfiguration.Create' = 'ActivitiesManagementConfiguration.Create',
  'ActivitiesManagementConfiguration.Edit' = 'ActivitiesManagementConfiguration.Edit',
  'LenderMatrixConfiguration.View' = 'LenderMatrixConfiguration.View',
  'LenderMatrixConfiguration.Create' = 'LenderMatrixConfiguration.Create',
  'LenderMatrixConfiguration.Edit' = 'LenderMatrixConfiguration.Edit',
  'LenderMatrixConfiguration.Delete' = 'LenderMatrixConfiguration.Delete',
  'DebtCollection.View' = 'DebtCollection.View',
  'DebtCollection.Create' = 'DebtCollection.Create',
  'PaymentPromise.View' = 'PaymentPromise.View',
  'PaymentPromise.Create' = 'PaymentPromise.Create',
  'PaymentPromise.Edit' = 'PaymentPromise.Edit',
  'MerchantCap.View' = 'MerchantCap.View',
  'MerchantCap.Edit' = 'MerchantCap.Edit',
  'ChangeLoanOwnership.View' = 'ChangeLoanOwnership.View',
  'ChangeLoanOwnership.Edit' = 'ChangeLoanOwnership.Edit',
  'Dbt.Create' = 'Dbt.Create',
  'Dbt.View' = 'Dbt.View',
  'Dbt.Edit' = 'Dbt.Edit',
  'Dbt.Delete' = 'Dbt.Delete',
  'Accounting.Create' = 'Accounting.Create',
  'Accounting.View' = 'Accounting.View',
  'Accounting.Edit' = 'Accounting.Edit',
  'Accounting.Execute' = 'Accounting.Execute',
  'Accounting.Delete' = 'Accounting.Delete',
  'SedaAlignment.View' = 'SedaAlignment.View',
  'SedaAlignment.Edit' = 'SedaAlignment.Edit',
}
