import React, { useState } from 'react';
import { produce } from 'immer';
import { Row, Col, Button, Popover } from 'antd';
import Bool from './bool';
import Date from './date';
import String from './string';
import Number from './number';
import CheckboxSelection from './selection';
import { DefaultTheme, useTheme } from 'styled-components';
import { useTableProvider } from '../../context';
import { FilterFilled } from '@ant-design/icons';
import { colorType, extractColor } from '../../../../../helpers';
import { CustomTheme, useTranslator } from '@opyn/utils';
import { TFilters } from '../column_title';

export type TValue = string | number | string[] | number[];

const types = {
  bool: 'bool',
  date: 'date',
  string: 'string',
  number: 'number',
  selection: 'selection',
};

const components = {
  [types.bool]: Bool,
  [types.date]: Date,
  [types.number]: Number,
  [types.string]: String,
  [types.selection]: CheckboxSelection,
};

const titleStyle = {
  fontWeight: 400,
  color: `${extractColor(colorType.primary)}`,
};

interface Props {
  title?: string;
  column: Record<string, any>;
  config?: any;
  onReset: (setFilters?: TFilters) => void;
  onConfirm: (value: TValue) => void;
  clearFilters?: (setFilters?: TFilters) => void;

  [x: string]: any;
}

const FilterContent = ({
  title,
  column,
  onReset,
  onConfirm,
  clearFilters,
  config = null,
  ...rest
}: Props) => {
  const translator = useTranslator();

  const [value, setValue] = React.useState(config?.value);

  const [search, setSearch] = React.useState('');

  const filterConfiguration =
    typeof column.filterable === 'object' ? column.filterable : column;

  let type = filterConfiguration.type;

  if (!Object.prototype.hasOwnProperty.call(types, type)) type = types.string;

  let Component;

  if (Object.prototype.hasOwnProperty.call(filterConfiguration, 'renderFilter'))
    Component = filterConfiguration.renderFilter;
  else if (Object.prototype.hasOwnProperty.call(components, type))
    Component = components[type];

  return (
    <Row
      key={translator.language}
      gutter={[12, 12]}
      style={{ padding: '4px 8px 8px 8px', maxWidth: 300, minWidth: 200 }}
    >
      <Col xs={24} style={titleStyle}>
        {translator.translate('filterBy.label')}
        {title}
      </Col>
      <Col xs={24}>
        <Component
          {...rest}
          value={value}
          search={search}
          setSearch={setSearch}
          onChange={setValue}
          onConfirm={onConfirm}
          {...column.filterable}
        />
      </Col>
      <Col xs={24}>
        <Row gutter={8} justify="end">
          <Col>
            <Button
              onClick={() => {
                onReset();
                setValue(null);
                setSearch('');
              }}
              size="small"
            >
              {translator.translate('reset.label')}
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              className="ant-button-emp"
              size="small"
              onClick={() => {
                onConfirm(value);
              }}
            >
              {translator.translate('viewResult.label')}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

interface FilterProps {
  title?: string;
  column: Record<string, string | number>;
  config?: { [key: string]: string | number | number[] | string[] };
  setFilters?: ({}: TFilters) => void;
}

const Filter = ({ column, title, setFilters, config }: FilterProps) => {
  const theme: DefaultTheme = useTheme();
  const provider = useTableProvider();
  const [visible, setVisible] = useState(false);

  const onClose = () => {
    handleVisibleChange(false);
  };

  const onOpen = () => {
    handleVisibleChange(true);
  };

  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
  };

  const handleConfirm = (value: TValue) => {
    setFilters?.(
      produce((draft: Record<string, any>) => {
        draft[column.key] = {
          ...draft[column.key],
          value: value,
        };
      })
    );

    onClose();
  };

  const handleReset = () => {
    setFilters?.((previous: object) => {
      return {
        ...previous,
        [column.key]: null,
      };
    });
  };

  const active = !!config;

  const iconStyle: { color?: string } = {};

  if (active || visible)
    iconStyle.color = (theme as CustomTheme).extractColor(colorType.primary, 3);

  return (
    <Popover
      title={null}
      zIndex={10}
      trigger="click"
      placement={'bottomRight'}
      open={visible}
      onOpenChange={handleVisibleChange}
      getPopupContainer={provider.getPopupContainer}
      content={
        <FilterContent
          title={title}
          column={column}
          config={config}
          onClose={onClose}
          onReset={handleReset}
          onConfirm={handleConfirm}
        />
      }
    >
      <FilterFilled style={iconStyle} onClick={onOpen} />
    </Popover>
  );
};

Filter.types = types;

export default Filter;
