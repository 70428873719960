import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { notification } from 'antd';
import App from './App.component';
import { ConfigProvider } from 'antd';
import {
  AuthenticationProvider,
  ThemesProvider,
  SettingsProvider,
} from './utils';
import 'nprogress/nprogress.css';
import { sentryOptions } from '../sentry';
import { IS_DEV_ENV } from './settings';
import { persistor, RootState, store } from './redux-store/store';
import { Provider } from 'react-redux';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from './redux-store/hooks';
import { settings } from './redux-store/reducers';
import { globalSettings } from './constants/settings';
import { SettingsStateModel } from './redux-store/reducers/settings/settings.types';
import { PersistGate } from 'redux-persist/integration/react';

if (!IS_DEV_ENV) {
  Sentry.init(sentryOptions);
}

const AppWrapper = () => {
  const dispatch = useAppDispatch();
  const tenants = useAppSelector((state: RootState) => state.settings.tenants);
  const cultures = useAppSelector(
    (state: RootState) => state.settings.cultures
  );

  useEffect(() => {
    if (tenants.length === 0 || cultures.length === 0) {
      dispatch(settings())
        .unwrap()
        .then(({ tenants, cultures }: SettingsStateModel) => {
          globalSettings.tenants = tenants;
          globalSettings.cultures = cultures;
        })
        .catch(() => {
          console.error('Unable to boot the application. Settings are missing');
        });
    }
  }, [dispatch, tenants, cultures]);

  if (tenants.length === 0 || cultures.length === 0) return null;

  globalSettings.tenants = tenants;
  globalSettings.cultures = cultures;

  return (
    <ConfigProvider>
      <Router>
        <SettingsProvider>
          <AuthenticationProvider>
            <ThemesProvider>
              <App />
            </ThemesProvider>
          </AuthenticationProvider>
        </SettingsProvider>
      </Router>
    </ConfigProvider>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AppWrapper />
    </PersistGate>
  </Provider>
);

notification.config({
  placement: 'bottomRight',
  bottom: 70,
  rtl: false,
});
