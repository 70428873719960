import React, { ReactElement } from 'react';
import StepsContainer from './styles';
import { ArrowLeftOutlined, CheckOutlined } from '@ant-design/icons';
import { StepperProcess, useStepper } from './api';
import { useSettings, useTranslator } from '@opyn/utils';
import { customColor } from '../../helpers/tenant';
import {
  colorType,
  extractColor,
  extractResultsFromQuery,
  useClientApi,
} from '../../helpers';
import styled from 'styled-components';
import { Button } from 'antd';
import { routeIncludes } from '../../pages/common/how_to/How.config';

interface ButtonProps {
  color?: any;
}

export interface IStep {
  canBeEditable?: (params: {
    completed?: number[];
    isCompleted: boolean;
  }) => boolean;
  component: ReactElement;
  descriptionTranslationId?: string;
  id: number;
  sidebarTitleTranslationId?: string;
  titleTranslationId?: string;
}

const CustomBtn = styled(Button)<ButtonProps>`
  border-radius: 8px;
  margin: 54px 0 0 12px;
  color: ${(props) => props.color};
  border: 1px solid ${extractColor(colorType.primary)};
`;

interface Props {
  steps: IStep[];
  setStep?: (activeStep: number) => void;
  selected?: number;
  completed?: number[];
  availableSteps?: number[];
  canNavigateSteps: boolean;
  children?: React.ReactNode;
  globalConfigurations: any;
}

const Stepper = ({
  completed,
  availableSteps,
  setStep,
  selected,
  canNavigateSteps = true,
  steps,
  globalConfigurations,
}: Props) => {
  const { navigate } = useSettings();
  const translator = useTranslator();

  const borrowers = useClientApi({
    url: `/borrower/users/me/borrowers`,
  });

  // Used for checking if you are in the conditions of 1 borrower/1 application
  const canReturnToDashboardCheck = () => {
    if (routeIncludes('loan')) return true;

    if (!routeIncludes('loan')) {
      if (!borrowers?.data) return true;

      const list = extractResultsFromQuery(borrowers);

      if (list.length > 1 || !list.length) {
        return true;
      }

      return !(list.length === 1 && list[0].isInProgress);
    }
  };

  if (!steps) return null;

  const isActive = (key: number) => {
    const finished = completed && completed.includes(key);
    return finished ? 'finished-step' : '';
  };

  const onStepChange = (currentId: number) => () => {
    if (!canNavigateSteps) return;

    if (availableSteps?.includes(currentId)) setStep?.(currentId);
  };

  const handleNavigation = () => {
    if (globalConfigurations && globalConfigurations?.borrower)
      navigate(`/borrower/home?borrowerId=${globalConfigurations.borrower.id}`);
    else navigate(`/borrower/home`);
  };

  return (
    <StepsContainer>
      <div className="sidebar-steps">
        {steps.map((step: IStep, index: number) => {
          let className = 'stepper ';

          if (index === 0) className += 'first-step';

          const isSelected = step.id === selected;

          const allowRedirect =
            canNavigateSteps && availableSteps?.includes(step.id);

          const style: React.CSSProperties = {};

          if (allowRedirect) style.cursor = 'pointer';
          else style.cursor = 'not-allowed';

          return (
            <li
              style={style}
              className={isActive(step.id)}
              onClick={onStepChange(step.id)}
              key={step.id}
            >
              <div
                className={isSelected ? 'active-tab ' + className : className}
              >
                <h5>{index + 1}</h5>
                <CheckOutlined />
              </div>
              <h4 className={isSelected ? 'selected-tab' : ''}>
                {translator.translate(
                  step.sidebarTitleTranslationId || step.titleTranslationId
                )}
              </h4>
            </li>
          );
        })}
      </div>
      {canReturnToDashboardCheck() && (
        <CustomBtn
          size="middle"
          color={customColor}
          icon={<ArrowLeftOutlined />}
          onClick={() => handleNavigation()}
        >
          {translator.translate('returnToDashboard.label')}
        </CustomBtn>
      )}
    </StepsContainer>
  );
};

Stepper.Process = StepperProcess;

export { useStepper };

export default Stepper;
