const Danger = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 2.25C9.30234 2.25 2.25 9.30234 2.25 18C2.25 26.6977 9.30234 33.75 18 33.75C26.6977 33.75 33.75 26.6977 33.75 18C33.75 9.30234 26.6977 2.25 18 2.25ZM18 31.0781C10.7789 31.0781 4.92188 25.2211 4.92188 18C4.92188 10.7789 10.7789 4.92188 18 4.92188C25.2211 4.92188 31.0781 10.7789 31.0781 18C31.0781 25.2211 25.2211 31.0781 18 31.0781Z"
        fill="#F13D3E"
      />
      <path
        opacity="0.1"
        d="M18 4.92188C10.7789 4.92188 4.92188 10.7789 4.92188 18C4.92188 25.2211 10.7789 31.0781 18 31.0781C25.2211 31.0781 31.0781 25.2211 31.0781 18C31.0781 10.7789 25.2211 4.92188 18 4.92188ZM16.875 10.4062C16.875 10.2516 17.0016 10.125 17.1562 10.125H18.8438C18.9984 10.125 19.125 10.2516 19.125 10.4062V19.9688C19.125 20.1234 18.9984 20.25 18.8438 20.25H17.1562C17.0016 20.25 16.875 20.1234 16.875 19.9688V10.4062ZM18 25.875C17.5584 25.866 17.138 25.6842 16.8288 25.3687C16.5197 25.0533 16.3466 24.6292 16.3466 24.1875C16.3466 23.7458 16.5197 23.3217 16.8288 23.0063C17.138 22.6908 17.5584 22.509 18 22.5C18.4416 22.509 18.8621 22.6908 19.1712 23.0063C19.4803 23.3217 19.6534 23.7458 19.6534 24.1875C19.6534 24.6292 19.4803 25.0533 19.1712 25.3687C18.8621 25.6842 18.4416 25.866 18 25.875Z"
        fill="#F13D3E"
      />
      <path
        d="M17.1562 20.25H18.8438C18.9984 20.25 19.125 20.1234 19.125 19.9688V10.4062C19.125 10.2516 18.9984 10.125 18.8438 10.125H17.1562C17.0016 10.125 16.875 10.2516 16.875 10.4062V19.9688C16.875 20.1234 17.0016 20.25 17.1562 20.25ZM16.3125 24.1875C16.3125 24.6351 16.4903 25.0643 16.8068 25.3807C17.1232 25.6972 17.5524 25.875 18 25.875C18.4476 25.875 18.8768 25.6972 19.1932 25.3807C19.5097 25.0643 19.6875 24.6351 19.6875 24.1875C19.6875 23.7399 19.5097 23.3107 19.1932 22.9943C18.8768 22.6778 18.4476 22.5 18 22.5C17.5524 22.5 17.1232 22.6778 16.8068 22.9943C16.4903 23.3107 16.3125 23.7399 16.3125 24.1875Z"
        fill="#F13D3E"
      />
    </svg>
  );
};

export default Danger;
