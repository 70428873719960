import { typographyType } from '../../helpers';

export const typographyStyles = {
  [typographyType.h1]: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  [typographyType.h2]: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  [typographyType.h3]: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  [typographyType.span]: {
    fontSize: 14,
    fontWeight: 400,
  },
};
