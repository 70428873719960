import { displayWarning } from '../../message';
import { i18n } from '../../translation';
import axios from 'axios';

interface Props {
  url?: any;
  body?: any;
  client?: any;
  baseURL?: any;
  onError?: any;
  headers?: any;
  onFinish?: any;
  onSuccess?: any;
  method?: string;
  controller?: any;
  setLoading?: any;
  dataType?: string;
  notification?: any;
  successMessage?: any;
  errorMessage?: string;
  invalidDataMessage?: string;
  cancelPreviousCalls?: boolean;
  shouldDisplayError?: boolean;
  shouldReturnError?: boolean;
  [x: string]: any;
}

export default ({ client, controller, notification, baseURL }: Props) =>
  ({
    url,
    body,
    headers = {},
    successMessage,
    method = 'get',
    dataType = 'json',
    cancelPreviousCalls = false,
    invalidDataMessage = 'invalidData.label',
    setLoading,
    onSuccess,
    onError,
    onFinish,
    errorMessage = 'tryAgain.label',
    shouldDisplayError = true,
    shouldReturnError = false,
    ...rest
  }: Props) => {
    const baseUrl = url && url.split('?')[0];
    const serverBaseUrl = rest.baseURL || baseURL;

    if (cancelPreviousCalls) {
      controller.cancelPreviousCall(baseUrl);
      controller.removeSource(baseUrl);
      controller.createSource(baseUrl);
    }

    if (setLoading) setLoading(true);

    const options: any = {
      url: url,
      method: method,
      headers: {
        ...headers,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    const source = controller.getSource(baseUrl);

    if (dataType === 'form')
      options.headers['Content-Type'] = 'multipart/form-data';

    if (body) options.data = body;

    if (serverBaseUrl) options.baseURL = serverBaseUrl;

    if (source) options.cancelToken = source.token;

    return client(options)
      .then((response: any) => {
        if (successMessage) notification.success(successMessage);

        if (onSuccess) onSuccess(response);

        return response;
      })
      .catch((error: any) => {
        if (axios.isCancel(error)) {
          return;
        }
        if (!shouldDisplayError) {
          return;
        }
        const notifyError = () => {
          if (onError) onError(error?.response?.data);
        };

        if (error.response) {
          const { status, data } = error.response;

          if (shouldReturnError) {
            notifyError();
            return;
          }

          if (status === 404) {
            notifyError();
            if (data.message) {
              notification.warning(data.message);
              return;
            }
          } else if (status === 403) {
            displayWarning(i18n.t('noPermissionsToView.label'));
          } else if (status === 500) {
            notification.error(i18n.t(errorMessage));
          } else if (status === 400) {
            let errors: any = '';
            if (Array.isArray(data)) {
              data.forEach((message) => (errors += `${message}\n`));
            } else if (typeof data === 'object') {
              if (data.message && !data.message.includes('\\n')) {
                errors = data.message;
              } else if (data.message && data.message.includes('\\n')) {
                data.message = data.message.split('\\n');
                errors = (
                  <>
                    {data.message[0]}{' '}
                    <ul>
                      {data.message.slice(1).map((item: any, index: number) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  </>
                );
              }
            }
            if (invalidDataMessage)
              notification.warning(errors, i18n.t(invalidDataMessage));
          } else if (status.toString().startsWith('4')) {
            if (data.message) {
              notification.warning(data.message);
            }
          } else {
            notification.error(i18n.t(errorMessage));
          }

          notifyError();
        } else if (error.request) {
          // The request was made but no response was received
          notifyError();
        } else {
          // Something happened in setting up the request that triggered an Error
          notifyError();
        }
      })
      .finally(() => {
        if (setLoading) setLoading(false);

        if (onFinish) onFinish();
      });
  };
