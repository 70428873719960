import React from 'react';

export const useDidUpdate = (fn: any, inputs: any) => {
  const didMountRef = React.useRef(false);

  React.useEffect(() => {
    if (didMountRef.current) fn();
    else didMountRef.current = true;
  }, inputs);
};
