import styled from 'styled-components';
import { extractColor, colorType } from '../../helpers';
import { isValsabbina } from '../../helpers/tenant';

export default styled.ul<any>`
  margin: 0;
  padding: 0;
  width: 100%;
  display: block;
  user-select: none;
  position: relative;

  .sidebar-steps {
    min-height: 63vh;
  }

  li {
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    list-style-type: none;
    position: relative;
    display: flex;
    align-items: center;
    z-index: 2;
    padding-top: 50px;
    transition: 0.3s;

    .stepper {
      width: 32px;
      height: 32px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${extractColor(colorType.lightGray, 0)};
      border-radius: 50%;
      margin: 0 15px;

      h5 {
        font-size: 14px;
        color: ${extractColor(colorType.light)};
        margin: 0;
      }

      .anticon {
        display: none;
      }
    }

    .stepper:before {
      content: '';
      height: 40px;
      width: 2px;
      background-color: ${extractColor(colorType.primary, 0)};
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -120%);
    }

    .first-step:before {
      display: none !important;
    }

    h4 {
      color: ${extractColor(colorType.background, 3)};
      margin: 0;
      font-size: 13px;
      font-weight: 300;
    }
  }

  li:first-child {
    padding-top: 20px;
  }

  .active-tab {
    border: 3px solid ${extractColor(colorType.light)} !important;
    color: ${extractColor(colorType.light)} !important;
    background-color: ${() =>
      isValsabbina ? extractColor(colorType.blue) : '#003B5C'} !important;

    h5 {
      color: ${extractColor(colorType.light)};
    }

    h4 {
      color: ${extractColor(colorType.primary, 3)};
      font-size: 13px;
      font-weight: 300;
    }

    .stepper:before {
      background-color: ${extractColor(colorType.blue)};
    }
  }

  .finished-step {
    .stepper {
      background-color: ${() =>
        isValsabbina ? extractColor(colorType.blue) : ' #47D7AC'};
      border: 3px solid transparent;
      box-shadow: 0 0 0 1px
        ${() => (isValsabbina ? extractColor(colorType.blue) : ' #47D7AC')};
      color: ${extractColor(colorType.light)};

      h5 {
        display: none;
      }

      .anticon {
        display: inline-block !important;
      }
    }

    h4 {
      color: ${extractColor(colorType.blue, 3)};
      font-size: 13px;
      font-weight: 300;
    }

    .stepper:before {
      background-color: ${() =>
        isValsabbina ? extractColor(colorType.blue) : '#47D7AC'};
    }
  }
`;
