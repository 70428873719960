import { Radio as AntRadio, RadioChangeEvent } from 'antd';

interface Props {
  value?: boolean;
  onChange: (event: boolean) => void;
}

const Radio = ({ value, onChange, ...rest }: Props) => {
  return (
    <AntRadio
      {...rest}
      checked={!!value}
      onChange={(event: RadioChangeEvent) => onChange(event.target.checked)}
    />
  );
};

export default Radio;
