import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSettings } from './settings';

export const useLanguage = () => {
  const { language } = useSettings();
  return language;
};

interface Props {
  language: any;
  translate: any;
  changeLanguage: any;
}

export const useTranslator = () => {
  const { language, setLanguage } = useSettings();
  const { t, i18n } = useTranslation();

  const changeLanguage = (code: any) => {
    i18n
      .changeLanguage(code)
      .then(() => {
        setLanguage(code);
        localStorage.setItem('language', code);
      })
      .catch(() => {});
  };

  const translator: Props = {
    language,
    translate: t,
    changeLanguage,
  };

  return translator;
};

export const useTranslatedList = ({
  list,
  translateKey = 'translateId',
}: any) => {
  const translator = useTranslator();

  const memoisedList = useMemo(() => {
    return list.map((record: any) => ({
      ...record,
      [translateKey]: translator.translate(record[translateKey]),
    }));
  }, [translator.language]);

  return memoisedList;
};
