const Info = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 2.25C9.30234 2.25 2.25 9.30234 2.25 18C2.25 26.6977 9.30234 33.75 18 33.75C26.6977 33.75 33.75 26.6977 33.75 18C33.75 9.30234 26.6977 2.25 18 2.25ZM18 31.0781C10.7789 31.0781 4.92188 25.2211 4.92188 18C4.92188 10.7789 10.7789 4.92188 18 4.92188C25.2211 4.92188 31.0781 10.7789 31.0781 18C31.0781 25.2211 25.2211 31.0781 18 31.0781Z"
        fill="#003B5C"
      />
      <path
        opacity="0.1"
        d="M18 4.92188C10.7789 4.92188 4.92188 10.7789 4.92188 18C4.92188 25.2211 10.7789 31.0781 18 31.0781C25.2211 31.0781 31.0781 25.2211 31.0781 18C31.0781 10.7789 25.2211 4.92188 18 4.92188ZM19.125 25.5938C19.125 25.7484 18.9984 25.875 18.8438 25.875H17.1562C17.0016 25.875 16.875 25.7484 16.875 25.5938V16.0312C16.875 15.8766 17.0016 15.75 17.1562 15.75H18.8438C18.9984 15.75 19.125 15.8766 19.125 16.0312V25.5938ZM18 13.5C17.5584 13.491 17.138 13.3092 16.8288 12.9937C16.5197 12.6783 16.3466 12.2542 16.3466 11.8125C16.3466 11.3708 16.5197 10.9467 16.8288 10.6313C17.138 10.3158 17.5584 10.134 18 10.125C18.4416 10.134 18.8621 10.3158 19.1712 10.6313C19.4803 10.9467 19.6534 11.3708 19.6534 11.8125C19.6534 12.2542 19.4803 12.6783 19.1712 12.9937C18.8621 13.3092 18.4416 13.491 18 13.5Z"
        fill="#003B5C"
      />
      <path
        d="M16.3125 11.8125C16.3125 12.2601 16.4903 12.6893 16.8068 13.0057C17.1232 13.3222 17.5524 13.5 18 13.5C18.4476 13.5 18.8768 13.3222 19.1932 13.0057C19.5097 12.6893 19.6875 12.2601 19.6875 11.8125C19.6875 11.3649 19.5097 10.9357 19.1932 10.6193C18.8768 10.3028 18.4476 10.125 18 10.125C17.5524 10.125 17.1232 10.3028 16.8068 10.6193C16.4903 10.9357 16.3125 11.3649 16.3125 11.8125ZM18.8438 15.75H17.1562C17.0016 15.75 16.875 15.8766 16.875 16.0312V25.5938C16.875 25.7484 17.0016 25.875 17.1562 25.875H18.8438C18.9984 25.875 19.125 25.7484 19.125 25.5938V16.0312C19.125 15.8766 18.9984 15.75 18.8438 15.75Z"
        fill="#003B5C"
      />
    </svg>
  );
};

export default Info;
