import {
  AnyAction,
  configureStore,
  EnhancedStore,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { authSlice, permissionsSlice, settingsSlice } from './reducers';
import { ReducerKeys } from './types/globals.types';
import { Dispatch } from 'react';

 
const staticReducers = {
  [ReducerKeys.Settings]: settingsSlice.reducer,
  [ReducerKeys.Auth]: authSlice.reducer,
  [ReducerKeys.Permissions]: permissionsSlice.reducer,
};
// This array is used to specify which reducers' states should be persisted.
const persistentWhitelist = ['settings', 'auth'];
const persistConfig = {
  key: 'key',
  root: 'root',
  whitelist: persistentWhitelist,
  storage,
};

const createRootReducer = (asyncReducers = {}) =>
  combineReducers({
    ...staticReducers,
    ...asyncReducers,
  });

const persistedReducer = persistReducer(persistConfig, createRootReducer());

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  /**
   * @ts-expect-error ignore
   */
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
}) as EnhancedStore & { asyncReducers: Record<string, any> };

store.asyncReducers = {};

export const injectReducer = (key: string, asyncReducer: any) => {
  store.asyncReducers[key] = asyncReducer;
  const newRootReducer = persistReducer(
    persistConfig,
    createRootReducer(store.asyncReducers)
  );
  store.replaceReducer(newRootReducer);
};

export const removeReducer = (key: string) => {
  delete store.asyncReducers[key];
  const newRootReducer = persistReducer(
    persistConfig,
    createRootReducer(store.asyncReducers)
  );
  store.replaceReducer(newRootReducer);
};

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = ThunkDispatch<any, undefined, AnyAction> &
  Dispatch<any>;
