import { Button, Col, Modal, Row } from 'antd';
import { modalConfiguration } from './config';
import Typography from '../typography';
import { i18n, typographyType } from '../../helpers';
import Style from './style';
import styled, { ThemeProvider } from 'styled-components';

const CustomTypography = styled(Typography)<any>`
  text-align: center;
  font-size: 20px;
`;

interface Props {
  type?: string;
  content?: any;
  deleteTitle?: string;
  customTitle?: string;
  isMessageArray?: boolean;
  hasCancelButton?: boolean;

  [x: string]: any;
}

function triggerModal({
  theme,
  content,
  deleteTitle,
  customTitle,
  customCancelText,
  isMessageArray = false,
  type = 'successfully',
  hasCancelButton,
  ...rest
}: Props) {
  const { danger, icon: Icon } = modalConfiguration[type];

  const cancelText = customCancelText
    ? customCancelText
    : i18n.t('cancel.label');
  const titleText = customTitle ? customTitle : i18n.t(`${type}.label`);
  const okText = rest.okText ? rest.okText : i18n.t('okay.label');
  const confirmText = i18n.t('confirm.label');
  const deleteText = i18n.t('delete.label');

  const modal = Modal.info({
    icon: null,
    content: (
      <ThemeProvider theme={theme}>
        <Style>
          <div
            style={{
              width: '36px',
              height: '36px',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: '20px',
            }}
          >
            <Icon />
          </div>

          <div style={{ marginTop: '20px' }}>
            <CustomTypography
              colorType=""
              style={{
                color:
                  type === 'successfully'
                    ? theme['success-level-2']
                    : type === 'confirm'
                      ? theme['warning-level-3']
                      : type === 'danger'
                        ? '#F13D3E'
                        : type === 'warning'
                          ? '#FFAD0C'
                          : theme['primary-level-3'],
              }}
              variant={typographyType.h1}
            >
              {type === 'delete'
                ? deleteTitle
                : type === 'successfully'
                  ? customTitle
                  : titleText}
            </CustomTypography>
          </div>

          <div style={{ marginTop: '10px' }}>
            <Typography
              variant={typographyType.p}
              style={{ whiteSpace: `pre-line` }}
            >
              {!isMessageArray ? (
                content
              ) : (
                <ol>
                  {content.map((text: string) => {
                    return <li>{text}</li>;
                  })}
                </ol>
              )}
            </Typography>
          </div>

          <div className="trigger-modal-buttons">
            <Row gutter={12}>
              {hasCancelButton && (
                <Col>
                  <Button
                    onClick={onCancel}
                    style={{ color: `${theme['primary-level-3']}` }}
                  >
                    {cancelText}
                  </Button>
                </Col>
              )}
              <Button
                danger={danger}
                type={'primary'}
                onClick={onOk}
                data-testid="okay-delete-button"
                style={{
                  background: `${
                    type === 'delete' ? theme['danger-level-3'] : theme['blue']
                  }`,
                  color: `${theme['primary']}`,
                }}
              >
                {type === 'delete'
                  ? deleteText
                  : type === 'confirm'
                    ? confirmText
                    : okText}
              </Button>
            </Row>
          </div>
        </Style>
      </ThemeProvider>
    ),
    className: 'mainLayout',
    okButtonProps: { className: 'trigger-modal-ok-hidden' },
    styles: { body: { display: 'flex', justifyContent: 'center' } },
    centered: true,
  });

  function onCancel() {
    if (rest.onCancel) rest.onCancel();
    modal.destroy();
  }

  function onOk() {
    if (rest.onOk) rest.onOk();
    modal.destroy();
  }
}

export default triggerModal;
