import styled from 'styled-components';
import { colorType, extractColor } from '../../helpers';

export default styled.div`
  position: relative;

  .scrollable_content {
    overflow: auto;
    flex-wrap: nowrap;
    user-select: none;
  }

  .ant-btn {
    width: 50px !important;
    border-radius: 10px !important;
    border: none;
    opacity: 0.7;
    background: ${extractColor(colorType.background, 2)};
    z-index: 99;
    padding: 20px;
    height: 100%;
    position: absolute;
  }

  .left_section {
    top: 0;
    left: -4px;
  }

  .right_section {
    top: 0;
    right: -4px;
  }

  .scrollable_content::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;
