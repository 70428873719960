import { notification } from 'antd';
import { i18n } from '../../translation';

export default {
  error(
    description = i18n.t('tryAgain.label'),
    message = i18n.t('error.label')
  ) {
    notification.error({ message, description });
  },
  warning(description: any, message = i18n.t('warning.label')) {
    notification.warning({ message, description });
  },
  success(description: any, message = i18n.t('success.label')) {
    notification.success({ message, description });
  },
};
