import styled from 'styled-components';

export default styled.div`
  pointer-events: none;

  .ant-select-selector {
    border: none !important;
  }

  .ant-input {
    border: none !important;
  }

  .ant-picker {
    border: none !important;
  }
`;
