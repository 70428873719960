import styled from 'styled-components';
import { colorType, extractColor } from '../../helpers';

export default styled.div`
  input {
    width: 3rem !important;
    height: 3rem;
    margin: 0 0.5rem;
    font-size: 2rem;
    border-radius: 4px;
    border: 1px solid ${extractColor(colorType.primary)};
  }
`;
