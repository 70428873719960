import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { STATUS } from '../../types/globals.types';
import { commonReducer } from '../../constants';
import { SettingsStateModel, SettingsModel } from './settings.types';
import { getSettings } from '../../services';

const name = 'settings';
const typePrefix = 'settings';

const initialState: SettingsStateModel = {
  ...commonReducer,
  tenants: [],
  cultures: [],
};

 
export const settings = createAsyncThunk<any>(
  typePrefix,
  async (_, thunkAPI) => {
    try {
      return await getSettings();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const settingsSlice = createSlice({
  name,
  initialState,
  reducers: {
    settingsReset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(settings.pending, (state: SettingsStateModel) => {
        state.status = STATUS.LOADING;
      })
      .addCase(
        settings.fulfilled,
        (state: SettingsStateModel, action: PayloadAction<SettingsModel>) => {
          state.status = STATUS.SUCCESS;
          state.isSuccess = true;
          state.tenants = action.payload.tenants?.map((tenant) => ({
            ...tenant,
            name: tenant.name.toLowerCase(),
          }));
          state.cultures = action.payload.cultures;
        }
      )
      .addCase(settings.rejected, (state: SettingsStateModel) => {
        state.status = STATUS.ERROR;
        state.isError = true;
      });
  },
});

export const { settingsReset } = settingsSlice.actions;
export default settingsSlice;
