import React, {
  createContext,
  ReactNode,
  useLayoutEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { globalSettings } from '../../../constants/settings';
import {
  AuthStorage,
  DefaultSystemLanguage,
  displayWarning,
} from '../../../helpers';
import { Skeleton } from 'antd';
import { TenantModel } from '../../../redux-store/reducers/settings/settings.types';
import { authRefreshToken } from '../../../redux-store/reducers';
import { useAppDispatch } from '../../../redux-store/hooks';

const SettingsContext = createContext({});

export const getTenantBasedOnPath = (): TenantModel => {
  const [currentTenantName] = window.location.pathname
    .split('/')
    .filter((v: string) => !!v);
  return globalSettings?.tenants?.find(
    (record: TenantModel) =>
      record.name?.toLowerCase() == currentTenantName?.toLowerCase()
  ) as TenantModel;
};

const SettingsProvider = (props: { children: ReactNode }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [tenant, setTenant] = useState(() => getTenantBasedOnPath());
  const [language, setLanguage] = React.useState(() => DefaultSystemLanguage);

  const isSessionActive = () => !!AuthStorage.getRefreshToken();

  useLayoutEffect(() => {
    if (!localStorage.getItem('language'))
      localStorage.setItem('language', DefaultSystemLanguage);

    if (!isSessionActive()) return;

    const currentTenant = getTenantBasedOnPath();

    if (!AuthStorage.existsAccessToken(currentTenant)) {
      onTenantChange(currentTenant);
    }
  }, []);

  const onTenantChange = async (tenant: TenantModel | undefined) => {
    if (tenant) {
      const actionResult = await dispatch(authRefreshToken());
      if (authRefreshToken.fulfilled.match(actionResult)) {
        setTenant({
          ...tenant,
        });
      } else {
        displayWarning('Session expired. Please log in again !');
        setTimeout(() => {
          window.localStorage.clear();
          window.location.reload();
        });
      }
    }
  };

  const updateTenant = (id: string) => {
    const newTenant = globalSettings.tenants.find(
      (record: TenantModel) => record.id == id
    );

    if (newTenant?.id == tenant?.id) return;

    onTenantChange(newTenant);
  };
  const customNavigate = (
    url: string,
    shouldBindTenantName = true,
    rest = {
      replace: false,
    }
  ) => {
    if (shouldBindTenantName) url = bindTenantName(url);

    if (url.substring(1, (tenant?.name?.length || 0) + 1) == tenant?.name)
      navigate(url, rest);
    else window.location.assign(url);
  };

  const isTenantPresent = () => {
    const [tenantName] = window.location.pathname
      .split('/')
      .filter((p: string) => p !== '');
    return !!globalSettings.tenants?.find(
      (t: TenantModel) => t.name == tenantName.toLowerCase()
    );
  };

  const bindTenantName = (url: string) => {
    if (!tenant || url.substring(1, tenant.name.length + 1) == tenant.name)
      return url;

    return '/' + tenant.name + url;
  };

  const currentTenant = getTenantBasedOnPath();

  if (isSessionActive()) {
    if (currentTenant && !AuthStorage.existsAccessToken(currentTenant)) {
      return <Skeleton active />;
    }
  }

  return (
    <SettingsContext.Provider
      key={tenant?.id}
      value={{
        language,
        setLanguage,
        id: tenant?.id,
        navigate: customNavigate,
        updateTenant: updateTenant,
        bindTenantName: bindTenantName,
        isTenantPresent: isTenantPresent,
        tenant: tenant,
      }}
    >
      {props.children}
    </SettingsContext.Provider>
  );
};

export { SettingsProvider, SettingsContext };
