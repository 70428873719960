import { Row, Col, InputNumber } from 'antd';
import { useTranslator } from '@opyn/utils';

interface Props {
  attr: string;
  value: any;

  [x: string]: any;
}

const Number = ({ value, isInterval, ...rest }: Props) => {
  const translator = useTranslator();

  const getValue = (index: number) => {
    return isInterval ? (value ? value[index] : null) : value;
  };

  const handleChange = (index: number) => (newValue: any) => {
    let formattedValue: number[] = isInterval ? value : newValue;

    if (isInterval) {
      formattedValue = formattedValue ? [...formattedValue] : [];
      formattedValue[index] = newValue;
    }

    rest.onChange(formattedValue);
  };

  const numberFormatter = (value: any) => {
    return value ? String(value).replace(/[^0-9.]/g, '') : '';
  };

  const numberParser = (value: any) => {
    return parseFloat(value || '0');
  };

  return (
    <Row gutter={[8, 8]}>
      <Col xs={isInterval ? 12 : 24}>
        <InputNumber
          placeholder={isInterval ? translator.translate('from.label') : ''}
          {...rest}
          parser={numberParser}
          style={{ width: '100%' }}
          value={getValue(0)}
          formatter={numberFormatter}
          onChange={handleChange(0)}
        />
      </Col>
      {isInterval && (
        <Col xs={12}>
          <InputNumber
            placeholder={isInterval ? translator.translate('to.label') : ''}
            {...rest}
            parser={numberParser}
            style={{ width: '100%' }}
            value={getValue(1)}
            formatter={numberFormatter}
            onChange={handleChange(1)}
          />
        </Col>
      )}
    </Row>
  );
};

export default Number;
