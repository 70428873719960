export const PUBLIC_API_PATHS = {
  TENANTS: '/tenants',
  CULTURES: '/cultures',
  // auth
  LOGIN: '/identity/login',
  REFRESH_TOKEN: '/identity/refresh',
};

export const PRIVATE_API_PATHS = {
  PERMISSIONS: '/management/users/me/permissions',
  APPLICATION: (id: number) => `/management/applications/${id}`,
  LOAN: (id: number) => `/management/loans/${id}`,
  CHANGE_BORROWER: (id: string) =>
    `/management/change-loan-ownership/previous-borrower/${id}`,
};
