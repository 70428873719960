import { anyObject } from './url';

export const extractColorFromTheme = (theme: any, color: any, level: any) => {
  if (
    [
      'light',
      'link',
      'gradientBlue',
      'lightGray',
      'blue',
      'darkGray',
      'text',
    ].includes(color)
  )
    return theme[color];
  return theme[`${color}-level-${level}`];
};

export const extractColor: any =
  (color: anyObject, level = 3) =>
  (props: anyObject) => {
    const theme = props.theme;
    return extractColorFromTheme(theme, color, level);
  };

export const applyCss = (config: any) => {
  const { color, level, css } = config;
  return (props: any) => {
    if (!css) return '';

    return css({
      ...props,
      color: extractColorFromTheme(props.theme, color, level),
    });
  };
};
