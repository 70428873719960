export const systemUserConfig = [
  {
    key: 'home',
    compounded: true,
    links: [
      {
        key: 'home-search-console',
        iframeUrl:
          'https://scribehow.com/embed/Search_console_for_ApplicationLoan_and_ToDos__xMHIz3ysRWuiLx5YkkpWcw?as=scrollable',
        label: 'searchConsole.label',
      },
      {
        key: 'home-user-settings',
        iframeUrl:
          'https://scribehow.com/embed/User_Dashboard__gSKRDuFKRFmNOVx6vMW1ig?as=scrollable',
        label: 'accountSettings.label',
      },
    ],
  },
  {
    key: 'email-categories',
    iframeUrl:
      'https://scribehow.com/embed/Configuration_of_emails__Av5Xb7hLTcGWR31O2aqT8Q?as=scrollable',
  },
  {
    key: 'legal-check',
    iframeUrl:
      'https://scribehow.com/embed/AMLLegal_Check_Management__H2gJ4OtrR_SFO4vEsZj1VA?as=scrollable',
  },
  {
    key: 'monitoring-w',
    iframeUrl:
      'https://scribehow.com/embed/Workflow__Run_a_monitoring_Workflow__hE4iTKJ0TjG8cEZGMV-Dbg?as=scrollable',
  },
  {
    key: 'default-w',
    iframeUrl:
      'https://scribehow.com/embed/Change_Automatic_Starter_Configuration_for_Tenant_Valsa__BQeiPMGwRGWk-FGVR6xETQ?as=scrollable',
  },
  {
    key: 'mcc-commission',
    iframeUrl:
      'https://scribehow.com/embed/MCC_Missing_Commission_Upload_Fileadmin__oUsDZMCBRkG4edCnKPTqnQ?as=scrollable',
  },
  {
    key: 'third-party-providers',
    iframeUrl:
      'https://scribehow.com/embed/Third_party_providers__Upload_of_documents__jCZnW81uTSukdR-SgN1j_A?as=scrollable',
  },
  {
    key: 'business-rules',
    iframeUrl:
      'https://scribehow.com/embed/Business_Rules_Configuration__lMRpB9PCT0-cV4cTRTFVxQ?as=scrollable',
  },
  {
    key: 'scorecard-configuration',
    iframeUrl:
      'https://scribehow.com/embed/Scorecard_Configuration__u0BTQ3r5S5GHHaE97rG-wQ?as=scrollable',
  },
  {
    key: 'scorecard-category-management',
    iframeUrl:
      'https://scribehow.com/embed/Scorecard_Category_Management__zQFaDc70R3uvmphB2u32yw?as=scrollable',
  },
  {
    key: 'approving-authority',
    iframeUrl:
      'https://scribehow.com/embed/Approving_authority__R8vvuCjjRgOP96nTRyLKVw?as=scrollable',
  },
  {
    key: 'bank',
    iframeUrl:
      'https://scribehow.com/embed/Bank_Configuration__Ec5wouRQRaaIE_6dT0FE2A?as=scrollable',
  },
  {
    key: 'reference-rate',
    iframeUrl:
      'https://scribehow.com/shared/Reference_Rate_Configuration__ZsPW82oARnqhSKUX0k77yw',
  },
  {
    key: 'legal-threshold',
    iframeUrl:
      'https://scribehow.com/shared/Legal_Threshold_Configuration__qY9CT6qPSyqXHAMSDjIWtw',
  },
  {
    key: 'email-configuration',
    iframeUrl:
      'https://scribehow.com/embed/Configure_Email__lwrItvFaThqPq8i8jCFW7A?as=scrollable',
  },
  {
    key: 'relationship-types-macrogroup',
    iframeUrl:
      'https://scribehow.com/embed/Relationship_Category_configuration__osnL_d5rRzSn9n5gjrvWog?as=scrollable',
  },
  {
    key: 'relationship-types',
    iframeUrl:
      'https://scribehow.com/embed/Relationship_Type_configuration__ArK1-gz4Qb2I3pD8oYwJmA?as=scrollable',
  },
  {
    key: 'company-type',
    iframeUrl:
      'https://scribehow.com/embed/Super_Admin_Configuration__Company_Type__ruSkGi2CQe-ZXmyijx0QqA?as=scrollable',
  },
  {
    key: 'financial-purpose-details',
    iframeUrl:
      'https://scribehow.com/embed/Super_Admin_Configuration__Financial_Purpose_Details_in_Emporium-QA__45J-G_-uTn-iakY_bCHNNQ?as=scrollable',
  },
  {
    key: 'financial-purpose',
    iframeUrl:
      'https://scribehow.com/embed/Super_Admin_Configuration__Financial_Purpose__3WdNRnlCQImrp9AwDHkjdg?as=scrollable',
  },
  {
    key: 'currency',
    iframeUrl:
      'https://scribehow.com/embed/Currency_Configuration__ocR3ahCYRUKuwN9TBAIFeQ?as=scrollable',
  },
  {
    key: 'sources-of-signup',
    iframeUrl:
      'https://scribehow.com/embed/Sources_of_Signup_Configuration__cd921dVYRA6_wcGh29w9yA?as=scrollable',
  },
  {
    key: 'web-categories',
    iframeUrl:
      'https://scribehow.com/embed/Web_Categories__NgbN7IsRSnG7xoatqXwA6A?as=scrollable',
  },
  {
    key: 'lender-matrix',
    iframeUrl:
      'https://scribehow.com/embed/Lender_matrix_Configuration__00v1yTV1RnOqdubs5g6vGQ?as=scrollable',
  },
  {
    key: 'users',
    iframeUrl:
      'https://scribehow.com/embed/Manage_of_users_and_creation_of_internal_back-office_user__ui5-5_JDQpOR0wMdQXoUTw?as=scrollable',
  },
  {
    key: 'roles',
    iframeUrl:
      'https://scribehow.com/embed/Creating_a_Role_and_Assigning_Permissions__ShzIs_aOTTyJ4TIuSy2EMA?as=scrollable',
  },
  {
    key: 'action-categories',
    iframeUrl:
      'https://scribehow.com/embed/Activities_Management_Configuration__ieL6vVCeQe2clevfDqhP9A?as=scrollable',
  },
  {
    key: 'action-types',
    iframeUrl:
      'https://scribehow.com/embed/Activities_Management_Configuration__ieL6vVCeQe2clevfDqhP9A?as=scrollable',
  },
  {
    key: 'todo-categories',
    iframeUrl:
      'https://scribehow.com/embed/Activities_Management_Configuration__ieL6vVCeQe2clevfDqhP9A?as=scrollable',
  },
  {
    key: 'todo-types',
    iframeUrl:
      'https://scribehow.com/embed/Activities_Management_Configuration__ieL6vVCeQe2clevfDqhP9A?as=scrollable',
  },
  {
    key: 'checklist-items',
    iframeUrl:
      'https://scribehow.com/embed/Checklist_Items_Configuration__w9EajsE_ReiTgNlAvsGeKA?as=scrollable',
  },
  {
    key: 'checklist-categories',
    iframeUrl:
      'https://scribehow.com/embed/Checklist_Category_Configuration__NXLxmQvuTY2xrgDTdU4YQw?as=scrollable',
  },
  {
    key: 'email-categories',
    iframeUrl:
      'https://scribehow.com/embed/Configuration_of_emails__Av5Xb7hLTcGWR31O2aqT8Q?as=scrollable',
  },
  {
    key: 'templates-configuration',
    iframeUrl:
      'https://scribehow.com/embed/Configure_Templates__zv3ywyD1Rianagh9K-GUTQ?as=scrollable',
  },
  {
    key: 'documents-list',
    iframeUrl:
      'https://scribehow.com/embed/Configuration_of_a_List_of_DocumentAdmin__sYuN6rV8QJ2etI50s35zeQ?as=scrollable',
  },
  {
    key: 'document-template-variables',
    iframeUrl:
      'https://scribehow.com/embed/List_of_Document_Variables__dOdGq0kwQOemwx7-A2iRDA?as=scrollable',
  },
  {
    key: 'document-magazine',
    iframeUrl:
      'https://scribehow.com/embed/Document_magazine_configuration__wBBU3mxjQXWcSpj_xXt1Ug?as=scrollable',
  },
  {
    key: 'document-configuration',
    iframeUrl:
      'https://scribehow.com/embed/Document_Management_Configuration__2mWDwMCAQnSixcdb2Vk6IQ?as=scrollable',
  },
  {
    key: 'macro-group',
    iframeUrl:
      'https://scribehow.com/embed/Document_Macro_Group_configuration___1j0jjF5RPKQgD5CeXsdOA?as=scrollable',
  },
  {
    key: 'mcc-document-configuration',
    iframeUrl:
      'https://scribehow.com/embed/MCC_Document_Configuration__tJ2E5EUvQw-eMofglB9YOg?as=scrollable',
  },
  {
    key: 'nation',
    iframeUrl:
      'https://scribehow.com/embed/Nation_configuration__y8TiJUDuShihIlR7WnA2bA?as=scrollable',
  },
  {
    key: 'region',
    iframeUrl:
      'https://scribehow.com/embed/Region_configuration__gO-OA3FqQH2Iy8Z-NP_plA?as=scrollable',
  },
  {
    key: 'province',
    iframeUrl:
      'https://scribehow.com/embed/Province_configuration__0vcAD26eRfWffOUo5nq0aA?as=scrollable',
  },
  {
    key: 'municipality',
    iframeUrl:
      'https://scribehow.com/embed/Municipality_configuration__aKHDzE8_TF6cFL6hzL4nRA?as=scrollable',
  },
  {
    key: 'survey-management',
    iframeUrl:
      'https://scribehow.com/embed/Survey_Management_Configuration__zLv7qkaUQTKHx1vU8VYoRA?as=scrollable',
  },
  {
    key: 'saas-configuration',
    iframeUrl:
      'https://scribehow.com/embed/SaaS_Configuration_from_SuperAdmin__b0P3xtlMTzeE2jIBYNBPLw?as=scrollable',
  },
  {
    key: 'centrale-rischi',
    iframeUrl:
      'https://scribehow.com/embed/Centrale_Rischi__KYv5QVplSoWb7JbLlAPtbg?as=scrollable',
  },
  {
    key: 'amortization-plan',
    compounded: true,
    links: [
      {
        key: 'amortization-plan-main',
        iframeUrl:
          'https://scribehow.com/embed/Amortizing_plan_real__JYEIunKqRaeZv_iU1iTMug?as=scrollable',
        label: 'amortizationPlan.label',
      },
      {
        key: 'amortization-plan-rescheduling',
        iframeUrl:
          'https://scribehow.com/embed/Rescheduling__WynTATS_RcCvg7SMrPKjlw?as=scrollable',
        label: 'loanRescheduling.label',
      },
      {
        key: 'amortization-plan-repayment',
        iframeUrl:
          'https://scribehow.com/embed/Early_Repayment__q0nK2pjdTeil7jo5HLJbbQ?as=scrollable',
        label: 'earlyRepayment.label',
      },
    ],
  },
  {
    key: 'network',
    compounded: true,
    links: [
      {
        key: 'network-related',
        iframeUrl:
          'https://scribehow.com/embed/Network_Analysis__Related_Entities_Section__6F5lxrVVRmyGleGnVM_z-w?as=scrollable',
        label: 'relatedEntities.label',
      },
      {
        key: 'network-locations',
        iframeUrl:
          'https://scribehow.com/embed/Network_Analysis__Location__RMsHUHCZQOiNZGzFwMRikw?as=scrollable',
        label: 'locations.label',
      },
      {
        key: 'network-report',
        iframeUrl:
          'https://scribehow.com/embed/Network_Analysis__Network_Report__tmanPLyUR5iozLYlBeEgHQ?as=scrollable',
        label: 'networkReport.label',
      },
      {
        key: 'network-customerSupplier',
        iframeUrl:
          'https://scribehow.com/embed/Network_Analysis__CustomerSupplier__J52FE9dhQDWbD7qI_TcMIQ?as=scrollable',
        label: 'customerSupplier.label',
      },
    ],
  },
  {
    key: 'aml',
    compounded: true,
    links: [
      {
        key: 'aml-sos',
        iframeUrl:
          'https://scribehow.com/embed/AMLSOS_Management__mq7B6RcuQJuokxPJljQ4pg?as=scrollable',
        label: 'sos.label',
      },
      {
        key: 'aml-kyc',
        iframeUrl:
          'https://scribehow.com/embed/Access_the_KYC_survey_from_the_Loan_officer_Dashboard__6o7qZemnRTyqyyGfaqXQkw?as=scrollable',
        label: 'kyc.label',
      },
      {
        key: 'aml-list-screening',
        iframeUrl:
          'https://scribehow.com/embed/AML_Manage_Screening_List__nvMijBocRDiCpn_0B80qeg?as=scrollable',
        label: 'listScreening.label',
      },
      {
        key: 'aml-checklist',
        iframeUrl:
          'https://scribehow.com/embed/AMLChecklist_Management__UQnv3NUpTBec7meBLO_H-A?as=scrollable',
        label: 'amlChecklist.label',
      },
      {
        key: 'aml-legal-check',
        iframeUrl:
          'https://scribehow.com/embed/AML__Legal_Check_Borrower_level__W7iVJIoCTsSMnklWMA1udQ',
        label: 'legalCheck.label',
      },
    ],
  },
  {
    key: 'edit_providers-mode',
    iframeUrl:
      'https://scribehow.com/embed/Application_Edit_Mode__5zkYL_kLRd-eCzMePSTO9g?as=scrollable',
    label: 'edit.label',
  },
  {
    key: 'workflow',
    iframeUrl:
      'https://scribehow.com/embed/Workflow_management__uH45wACzT8u3jZAhsjcMIA?as=scrollable',
    label: 'workflow.label',
  },
  {
    key: 'manage-invitations',
    iframeUrl:
      'https://scribehow.com/embed/Assigning_new_roles__FZYSKwleTQCDlbKKtP3rtw?as=scrollable',
  },
  {
    key: 'guarantees',
    iframeUrl:
      'https://scribehow.com/embed/Guarantee__0s70LZcmRNac7v5JfdnRgQ?as=scrollable',
  },
  {
    key: 'borrower-profile',
    iframeUrl:
      'https://scribehow.com/embed/Borrower_Profile__Xim1F5vkTCWVYJ8ZsieqCw?as=scrollable',
  },
  {
    key: 'contacts',
    iframeUrl:
      'https://scribehow.com/embed/Contacts__XzeB-cCgSd-hXC70YXC-rg?as=scrollable',
  },
  {
    key: 'indexes',
    iframeUrl:
      'https://scribehow.com/embed/Indexes__RDW8jzSvRvy_Cuy7-JOeSg?as=scrollable',
  },
  {
    key: 'checklists',
    iframeUrl:
      'https://scribehow.com/embed/Requested_Checklist__yLh6eA8-QRybmQdqNE4Nkg?as=scrollable',
  },
  {
    key: 'web-reputation',
    iframeUrl:
      'https://scribehow.com/embed/Web_Reputation__3kN_TtWlTkyHaK0Hu6fyMA?as=scrollable',
  },
  {
    key: 'user-settings',
    iframeUrl:
      'https://scribehow.com/embed/User_Dashboard__gSKRDuFKRFmNOVx6vMW1ig?as=scrollable',
  },
  {
    key: 'surveys',
    iframeUrl:
      'https://scribehow.com/embed/Survey_managementWelcome_Call__3FOrGGcNQDO2HLfmN7uN_A?as=scrollable',
  },
  {
    key: 'activities-management',
    iframeUrl:
      'https://scribehow.com/embed/Activities_management__IuIrkXz3S--X0A0zaCVbcQ?as=scrollable',
  },
  {
    key: 'complex-index-fdg',
    compounded: true,
    links: [
      {
        key: 'complex-index-fdg-guarantee-scoring',
        iframeUrl:
          'https://scribehow.com/embed/Complex_Index_FDG__ZYKoAOqmRxKRVT6kRIdiWw?as=scrollable',
        label: 'guaranteeData.label',
      },
      {
        key: 'complex-index-fdg-mcc-commissions',
        iframeUrl:
          'https://scribehow.com/shared/Manage_MCC_Commission_Payments_on_Application_and_Loan__GoHGYO7GQ9W6HaylNJieIQ?as=scrollable',
        label: 'mccCommissions.label',
      },
    ],
  },
  {
    key: 'fdg-guarantee',
    compounded: true,
    links: [
      {
        key: 'fdg-guarantee-other',
        iframeUrl:
          'https://scribehow.com/embed/FDG_Guarantee__ODIq47GAT1-cyFsyt2P3tw?as=scrollable',
        label: 'fdgGuarantee.label',
      },
      {
        key: 'fdg-guarantee-mcc-commissions',
        iframeUrl:
          'https://scribehow.com/shared/Manage_MCC_Commission_Payments_on_Application_and_Loan__GoHGYO7GQ9W6HaylNJieIQ?as=scrollable',
        label: 'mccCommissions.label',
      },
    ],
  },
  {
    key: 'credit-history',
    iframeUrl:
      'https://scribehow.com/embed/Credit_History__G5317zbCST2TnamZ6HyJVg?as=scrollable',
  },
  {
    key: 'documents',
    iframeUrl:
      'https://scribehow.com/embed/Document_management__gO-s6nSsTCmiEQbSOVrZYA?as=scrollable',
  },
  {
    key: 'financial-statements',
    iframeUrl:
      'https://scribehow.com/embed/Financial_Statement___jEQbLeJQPKsBrjdGCUp3w?as=scrollable',
  },
  {
    key: 'scorecards',
    iframeUrl:
      'https://scribehow.com/embed/Scorecard_view_per_Application__FKh0QgFZQp-UCT80E3Ng3A?as=scrollable',
  },
  {
    key: 'banks-statements',
    iframeUrl:
      'https://scribehow.com/embed/Bank_Statement__uBcZYUL6TfCFsmQ30SXG9w?as=scrollable',
  },
  {
    key: 'loan-creation',
    iframeUrl:
      'https://scribehow.com/embed/Lender_Assignment__Loan_Creation__B4fXrgUNTl-R2xTeL2WRcA?as=scrollable',
  },
  {
    key: 'debt-collection',
    iframeUrl:
      'https://scribehow.com/shared/Debt_Collection_Dates__pdHGShh-QHO4tjWG5c7WtA',
  },
];

export const publicConfig = [
  {
    key: 'signin',
    iframeUrl:
      'https://scribehow.com/embed/Sign__in__VYkWng2JQi2rT21CLFhW_A?as=scrollable',
    label: 'signIn.label',
  },
  {
    key: 'signup',
    iframeUrl:
      'https://scribehow.com/embed/Sign_Up__nZ_nz5vnSOu1QNzT5mHaRA?as=scrollable',
    label: 'signUp.label',
  },
  {
    key: 'recovery',
    iframeUrl:
      'https://scribehow.com/embed/Sign__in__VYkWng2JQi2rT21CLFhW_A?as=scrollable',
    label: 'forgotPassword.label',
  },
  {
    key: 'username-recovery',
    iframeUrl:
      'https://scribehow.com/embed/Sign__in__VYkWng2JQi2rT21CLFhW_A?as=scrollable',
    label: 'forgotUsernameTitle.label',
  },
];

export const nonSystemUserConfig = [
  {
    key: 'borrower',
    compounded: true,
    links: [
      {
        key: 'borrower-documents',
        iframeUrl: [
          {
            lang: 'en-US',
            iframeUrl:
              'https://scribehow.com/embed/Document_requested_and_Document_Archive__dM6NIMJ_ROy9l81fUeakVQ?as=scrollable',
          },
          {
            lang: 'it-IT',
            iframeUrl:
              'https://scribehow.com/embed/Document_requested_and_Document_Archive_ITA__my5q0KmGTJeUxBIquWX0vA?as=scrollable',
          },
        ],
        label: 'documents.label',
      },
      {
        key: 'borrower-loan-loan-accepted',
        iframeUrl: [
          {
            lang: 'en-US',
            iframeUrl:
              'https://scribehow.com/embed/Homepage_and_Manual_Payment__MOdDJa_VTy-tkX2Rv-kUPA?as=scrollable',
          },
          {
            lang: 'it-IT',
            iframeUrl:
              'https://scribehow.com/embed/Homepage_and_Manual_Payment_ITA__PqN9_x-kRIS2ay-udctwFA?as=scrollable',
          },
        ],
        label: 'manualPayments.label',
      },
      {
        key: 'borrower-iban',
        iframeUrl: [
          {
            lang: 'en-US',
            iframeUrl:
              'https://scribehow.com/embed/Change_Disbursement_IBAN__iIpx5ACVRSybJtQrU22aUA?as=scrollable',
          },
          {
            lang: 'it-IT',
            iframeUrl:
              'https://scribehow.com/embed/Change_Disbursement_IBAN_ITA__G0pbs7vgSw-8LkRjOazCZA?as=scrollable',
          },
        ],

        label: 'iban.label',
      },
      {
        key: 'borrower-manage-roles',
        label: 'manageRoles.label',
        iframeUrl: [
          {
            lang: 'en-US',
            iframeUrl:
              'https://scribehow.com/embed/Assigning_new_roles_as_a_Front-Office_User__-S7D0BqVR02czhEfo-ddOg?as=scrollable',
          },
          {
            lang: 'it-IT',
            iframeUrl:
              'https://scribehow.com/embed/Assigning_new_roles_as_a_Front-Office_User_ITA__zvS5kn2tQL2xuDzP5XKQLA?as=scrollable',
          },
        ],
      },
      {
        key: 'borrower-kyc',
        iframeUrl: [
          {
            lang: 'en-US',
            iframeUrl:
              'https://scribehow.com/embed/Access_KYC_Survey_from_Borrower_Dashboard__fFLD8pOzQPiHOcgowPrvFQ?as=scrollable',
          },
          {
            lang: 'it-IT',
            iframeUrl:
              'https://scribehow.com/embed/Access_KYC_Survey_ITA__GBFpXwZsReeBxgbDim8M_w?as=scrollable',
          },
        ],
        label: 'kyc.label',
      },
      {
        key: 'borrower-dashboard',
        iframeUrl: [
          {
            lang: 'en-US',
            iframeUrl:
              'https://scribehow.com/embed/User_Borrower_Dashboard__-0Rmu9tLRLOQTF5udtmMzg?as=scrollable',
          },
          {
            lang: 'it-IT',
            iframeUrl:
              'https://scribehow.com/embed/User_Borrower_Dashboard_ITA__qAS-YrkMS_Kbya9JuA2EMg?as=scrollable',
          },
        ],
        label: 'dashboard.label',
      },
    ],
  },
  {
    key: 'search-console',
    iframeUrl: [
      {
        lang: 'en-US',
        iframeUrl:
          'https://scribehow.com/embed/Front_office_Search_Console__ir-68C17Si6BkxcdmKH1FA?as=scrollable',
      },
      {
        lang: 'it-IT',
        iframeUrl:
          'https://scribehow.com/embed/Front_office_Search_Console_ITA__oQVk4W3OROmw1LJHdRXeVQ?as=scrollable',
      },
    ],
    label: 'searchConsole.label',
  },
  {
    key: 'user-journey',
    iframeUrl:
      'https://scribehow.com/embed/Borrowers_Journey_and_Application_Journey__iU7D6qQTQwy2aZi0FiTrUA?as=scrollable',
  },
  {
    key: 'application-journey',
    iframeUrl:
      'https://scribehow.com/embed/Application_Journey__WTT5DJfTQ5GMHrRmeyi2aw?as=scrollable',
  },
  {
    key: 'loan-journey',
    compounded: true,
    links: [
      {
        key: 'loan-journey-Signer',
        iframeUrl:
          'https://scribehow.com/embed/Signer_Journey__eyyjFMb9SfeJHWkt9f6J4Q?as=scrollable',
        label: 'signerJourney.label',
        role: 3,
      },
      {
        key: 'loan-journey-MU',
        iframeUrl:
          'https://scribehow.com/embed/Main_User_Journey__6-1DLFBGRR6wkYrZ1vthhQ?as=scrollable',
        label: 'mainUserJourney.label',
        role: 7,
      },
      {
        key: 'loan-journey-KYC',
        iframeUrl:
          'https://scribehow.com/embed/Complete_KYC_as_Beneficial_Owner__6D_9xZAzQgucyQVWoD0nkw?as=scrollable',
        label: 'boKyc.label',
        role: 8,
      },
    ],
  },
];

export const customHowLink: any = (key: any): any => {
  const c = systemUserConfig?.find((c: any) => c.key === key);

  if (c?.compounded) {
    return c?.links?.map((link: any) => {
      return {
        key: link.key,
        link:
          window.location.pathname.substring(
            0,
            window.location.pathname.lastIndexOf('/')
          ) + `/how/${link.key}`,
        label: link?.label,
      };
    });
  }

  return (
    window.location.pathname.substring(
      0,
      window.location.pathname.lastIndexOf('/')
    ) + `/how/${key}`
  );
};

export const customBorrowerHowLink = (key: any) => {
  const c = nonSystemUserConfig?.find((c: any) => c.key === key);

  if (c?.compounded) {
    return c?.links?.map((link: any) => {
      return {
        key: link.key,
        link:
          window.location.pathname.substring(
            0,
            window.location.pathname.lastIndexOf('borrower') + 8
          ) + `/how/${link.key}`,
        label: link?.label,
      };
    });
  }

  return (
    window.location.pathname.substring(
      0,
      window.location.pathname.lastIndexOf('borrower') + 8
    ) + `/how/${key}`
  );
};

export const customLoanHowLink = (array: any) => {
  if (!array.length) return;

  return array?.map((link: any) => {
    return {
      key: link.key,
      link:
        window.location.pathname.substring(
          0,
          window.location.pathname.lastIndexOf('borrower') + 8
        ) + `/how/${link.key}`,
      label: link?.label,
    };
  });
};

export const routeIncludes = (key: string) => {
  return window.location.href.indexOf(key) > -1;
};

export const routeKey = () => {
  return window.location.pathname.split('/').pop();
};
