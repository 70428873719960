import { useTranslator } from '@opyn/utils';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
  ClassicEditor,
  Bold,
  Italic,
  List,
  Paragraph,
  Underline,
  type EditorConfig,
  Autosave,
  Essentials,
} from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';
import { EventInfo } from '@ckeditor/ckeditor5-utils';
import { useEffect, useState } from 'react';

interface IItem {
  name: string;
  cultureId: number;

  [key: string]: any;
}

interface Props {
  value: IItem[];
  attr: string;
  search?: boolean;
  language: number;
  onChange: (value: string) => void;
  isPassword?: boolean;
  isEmailType?: boolean;
  multiLanguage: boolean;
  isPercentage?: boolean;
  config?: EditorConfig;
  placeholder?: string;

  [x: string]: any;
}

const CkEditor = (props: Props) => {
  const {
    attr,
    onChange,
    language,
    value = [],
    config,
    multiLanguage,
    placeholder,
    ...rest
  } = props;

  useTranslator();
  const [localizedData, setLocalizedData] = useState<string>('');
  const editorConfig: EditorConfig = {
    toolbar: {
      items: ['bold', 'italic', 'underline', '|', 'bulletedList'],
      shouldNotGroupWhenFull: false,
    },
    plugins: [Autosave, Essentials, Bold, Italic, Underline, List, Paragraph],
    list: {
      properties: {
        styles: true,
        startIndex: true,
        reversed: true,
      },
    },
    htmlSupport: {
      allow: [
        {
          name: /^.*$/,
          styles: true,
          attributes: true,
          classes: true,
        },
      ],
    },
    placeholder,
    ...config,
  };

  const handleEditorChange = (
    event: EventInfo<string, unknown>,
    editor: ClassicEditor
  ) => {
    const data = editor.getData();
    onChange(data);
  };

  useEffect(() => {
    if (multiLanguage && value) {
      const localization = value.find(
        (item: IItem) => item.cultureId === language
      );
      setLocalizedData(localization ? localization[attr] || '' : '');
    }
  }, [language, multiLanguage, value, attr]);

  return (
    <CKEditor
      editor={ClassicEditor}
      data={localizedData}
      config={editorConfig}
      onChange={handleEditorChange}
      {...rest}
    />
  );
};

export default CkEditor;
