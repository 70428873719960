import styled from 'styled-components';

export default styled.div`
  h1,
  h2,
  p {
    text-align: center;
  }

  p {
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: rgba(0, 0, 0, 0.7);
  }

  .trigger-modal-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
`;
