import { createContext, ReactNode, useContext, useMemo, useRef } from 'react';
import { uid } from '../../../../helpers';

const Context = createContext<{}>({});

const useTableProvider = () => useContext<any>(Context);

interface Props {
  children?: ReactNode;
}

const TableProvider = ({ children }: Props) => {
  const ref = useRef({ id: uid() });

  const getPopupContainer = (): HTMLElement | null =>
    document.getElementById(ref.current.id);

  const value = useMemo(() => {
    return {
      id: ref.current.id,
      getPopupContainer,
    };
  }, [ref.current.id]);

  return (
    <Context.Provider value={value}>
      <div id={ref.current.id}>{children}</div>
    </Context.Provider>
  );
};

export { TableProvider, useTableProvider };
