import React, { ReactNode } from 'react';
import styled from 'styled-components';
import Footer from './footer/Footer.component';
import Header from './header/Header.component';
import { Card, CardProps } from 'antd';

interface ContainerProps {
  $loanStyles: boolean;
}

const ContainerCard = styled(Card)<ContainerProps & CardProps>`
  width: 86% !important;
  box-shadow: none !important;
  max-width: 1200px !important;
  background: transparent !important;

  ${(props) =>
    props.$loanStyles &&
    `
    .ant-card-actions {
      width: 62%;
      bottom: 60px;
      position: fixed;
      background-color: #FFF !important;
    }
  `}
  .ant-card-head {
    border-bottom: 0;
    margin-bottom: 18px;
    padding-left: 0 !important;
  }

  .ant-card-body {
    overflow: auto;
    max-height: 1000px;
    margin-bottom: 36px;
    padding-left: 0 !important;
  }

  .ant-card-head-title {
    white-space: pre-wrap;
    max-width: 1000px;
  }
`;

interface Props {
  loanStyles?: boolean;
  children?: ReactNode;
  renderTitle?: () => void;
  renderActions?: (rest: unknown) => ReactNode[];

  [x: string]: any;
}

const FormLayout = ({
  renderActions,
  loanStyles = false,
  renderTitle,
  children,
  ...rest
}: Props) => {
  return (
    <>
      {rest.extra && rest.extraPosition === 'top-fullscreen' && rest.extra}
      <ContainerCard
        size="small"
        $loanStyles={loanStyles}
        bordered={false}
        title={<Header {...rest} />}
        actions={renderActions ? renderActions(rest) : [<Footer {...rest} />]}
      >
        {children}
      </ContainerCard>
    </>
  );
};

export default FormLayout;
