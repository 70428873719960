import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import Typography from '../../typography';
import { colorType, extractColor, typographyType } from '../../../helpers';

const Asterisk = styled.div`
  margin-left: 5px;
  color: ${extractColor(colorType.danger)};
`;

interface Props {
  title?: string;
  required?: boolean;
  children?: ReactNode;
  customColor?: any;
}

const Frame = ({
  title,
  children,
  required,
  customColor,
}: Props): JSX.Element | null => {
  if (!title) return <>{children}</>;

  return (
    <Row gutter={[4, 4]}>
      <Col xs={24}>
        <Row>
          <Col>
            {' '}
            <Typography customColor={customColor} variant={typographyType.span}>
              {title}
            </Typography>
          </Col>
          <Col>
            <Asterisk>{required && '*'}</Asterisk>
          </Col>
        </Row>
      </Col>
      <Col xs={24}>{children}</Col>
    </Row>
  );
};

export default Frame;
