const Linkedin = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.5466 3.00019H4.96C4.70611 2.99666 4.454 3.04319 4.21809 3.1371C3.98218 3.23102 3.76707 3.37048 3.58507 3.54754C3.40306 3.72459 3.25771 3.93577 3.15732 4.169C3.05694 4.40224 3.00348 4.65296 3 4.90686V27.7602C3.00348 28.0141 3.05694 28.2648 3.15732 28.498C3.25771 28.7313 3.40306 28.9425 3.58507 29.1195C3.76707 29.2966 3.98218 29.436 4.21809 29.5299C4.454 29.6239 4.70611 29.6704 4.96 29.6669H27.5466C27.8005 29.6704 28.0526 29.6239 28.2886 29.5299C28.5245 29.436 28.7396 29.2966 28.9216 29.1195C29.1036 28.9425 29.2489 28.7313 29.3493 28.498C29.4497 28.2648 29.5032 28.0141 29.5067 27.7602V4.90686C29.5032 4.65296 29.4497 4.40224 29.3493 4.169C29.2489 3.93577 29.1036 3.72459 28.9216 3.54754C28.7396 3.37048 28.5245 3.23102 28.2886 3.1371C28.0526 3.04319 27.8005 2.99666 27.5466 3.00019ZM11.04 25.3202H7.04V13.3202H11.04V25.3202ZM9.04 11.6402C8.48835 11.6402 7.9593 11.421 7.56922 11.031C7.17914 10.6409 6.96 10.1118 6.96 9.56019C6.96 9.00854 7.17914 8.47948 7.56922 8.08941C7.9593 7.69933 8.48835 7.48019 9.04 7.48019C9.33293 7.44697 9.62957 7.476 9.9105 7.56537C10.1914 7.65474 10.4503 7.80244 10.6702 7.99881C10.8901 8.19517 11.066 8.43576 11.1865 8.70483C11.3069 8.9739 11.3692 9.26539 11.3692 9.56019C11.3692 9.855 11.3069 10.1465 11.1865 10.4155C11.066 10.6846 10.8901 10.9252 10.6702 11.1216C10.4503 11.3179 10.1914 11.4656 9.9105 11.555C9.62957 11.6444 9.33293 11.6734 9.04 11.6402ZM25.4666 25.3202H21.4666V18.8802C21.4666 17.2669 20.8933 16.2135 19.44 16.2135C18.9902 16.2168 18.5522 16.3579 18.1851 16.6178C17.818 16.8776 17.5393 17.2438 17.3867 17.6669C17.2823 17.9802 17.2371 18.3103 17.2533 18.6402V25.3069H13.2533C13.2533 25.3069 13.2533 14.4002 13.2533 13.3069H17.2533V15.0002C17.6167 14.3697 18.1452 13.8502 18.7819 13.4978C19.4186 13.1454 20.1394 12.9733 20.8666 13.0002C23.5333 13.0002 25.4666 14.7202 25.4666 18.4135V25.3202Z"
        fill="#47D7AC"
      />
    </svg>
  );
};

export default Linkedin;
