const Confirmation = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 2.25C9.30234 2.25 2.25 9.30234 2.25 18C2.25 26.6977 9.30234 33.75 18 33.75C26.6977 33.75 33.75 26.6977 33.75 18C33.75 9.30234 26.6977 2.25 18 2.25ZM18 31.0781C10.7789 31.0781 4.92188 25.2211 4.92188 18C4.92188 10.7789 10.7789 4.92188 18 4.92188C25.2211 4.92188 31.0781 10.7789 31.0781 18C31.0781 25.2211 25.2211 31.0781 18 31.0781Z"
        fill="#FFAD0C"
      />
      <path
        opacity="0.1"
        d="M18 4.92188C10.7789 4.92188 4.92188 10.7789 4.92188 18C4.92188 25.2211 10.7789 31.0781 18 31.0781C25.2211 31.0781 31.0781 25.2211 31.0781 18C31.0781 10.7789 25.2211 4.92188 18 4.92188ZM18 27.1406C17.223 27.1406 16.5938 26.5113 16.5938 25.7344C16.5938 24.9574 17.223 24.3281 18 24.3281C18.777 24.3281 19.4062 24.9574 19.4062 25.7344C19.4062 26.5113 18.777 27.1406 18 27.1406ZM20.2113 19.4238C19.8929 19.5468 19.619 19.7628 19.4252 20.0438C19.2314 20.3247 19.1268 20.6575 19.125 20.9988V21.7969C19.125 21.9516 18.9984 22.0781 18.8438 22.0781H17.1562C17.0016 22.0781 16.875 21.9516 16.875 21.7969V21.041C16.875 20.2289 17.1105 19.4273 17.5746 18.7594C18.0281 18.1055 18.6609 17.6063 19.4062 17.3215C20.6016 16.8609 21.375 15.859 21.375 14.7656C21.375 13.2152 19.8598 11.9531 18 11.9531C16.1402 11.9531 14.625 13.2152 14.625 14.7656V15.0328C14.625 15.1875 14.4984 15.3141 14.3438 15.3141H12.6562C12.5016 15.3141 12.375 15.1875 12.375 15.0328V14.7656C12.375 13.384 12.9797 12.0938 14.0766 11.134C15.1312 10.2094 16.5234 9.70312 18 9.70312C19.4766 9.70312 20.8687 10.2129 21.9234 11.134C23.0203 12.0938 23.625 13.384 23.625 14.7656C23.625 16.7977 22.2855 18.6258 20.2113 19.4238Z"
        fill="#FFAD0C"
      />
      <path
        d="M16.5938 25.7344C16.5938 26.1073 16.7419 26.465 17.0056 26.7287C17.2694 26.9925 17.627 27.1406 18 27.1406C18.373 27.1406 18.7306 26.9925 18.9944 26.7287C19.2581 26.465 19.4062 26.1073 19.4062 25.7344C19.4062 25.3614 19.2581 25.0037 18.9944 24.74C18.7306 24.4763 18.373 24.3281 18 24.3281C17.627 24.3281 17.2694 24.4763 17.0056 24.74C16.7419 25.0037 16.5938 25.3614 16.5938 25.7344ZM21.9234 11.134C20.8687 10.2129 19.4766 9.70312 18 9.70312C16.5234 9.70312 15.1312 10.2094 14.0766 11.134C12.9797 12.0938 12.375 13.384 12.375 14.7656V15.0328C12.375 15.1875 12.5016 15.3141 12.6562 15.3141H14.3438C14.4984 15.3141 14.625 15.1875 14.625 15.0328V14.7656C14.625 13.2152 16.1402 11.9531 18 11.9531C19.8598 11.9531 21.375 13.2152 21.375 14.7656C21.375 15.859 20.6016 16.8609 19.4062 17.3215C18.6609 17.6063 18.0281 18.1055 17.5746 18.7594C17.1105 19.4273 16.875 20.2289 16.875 21.041V21.7969C16.875 21.9516 17.0016 22.0781 17.1562 22.0781H18.8438C18.9984 22.0781 19.125 21.9516 19.125 21.7969V20.9988C19.1268 20.6575 19.2314 20.3247 19.4252 20.0438C19.619 19.7628 19.8929 19.5468 20.2113 19.4238C22.2855 18.6258 23.625 16.7977 23.625 14.7656C23.625 13.384 23.0203 12.0938 21.9234 11.134Z"
        fill="#FFAD0C"
      />
    </svg>
  );
};

export default Confirmation;
