import { useState, useEffect } from 'react';
import { callApi } from '../../helpers';
import { withPageSize } from '../../helpers/url';
import Editor, { Props } from '../editor';

type LocationSelectionProps = Props & {
  prepareUrl?: (id: number) => string;
  id: number;
};

interface WithLocationSelectionProps {
  id: number;
  value: string;
  onChange: (value: string) => void;
  prepareUrl?: (id: number) => string;
  [key: string]: unknown;
}

const LocationSelection: any = {};

const withLocationSelection =
  ({ prepareUrl }: { prepareUrl?: (id: number) => string }) =>
  ({ id, value, onChange, ...rest }: WithLocationSelectionProps) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
      if (!id) return;

      const callback = rest.prepareUrl || prepareUrl;

      callApi({
        url: callback?.(id),
        onSuccess: setOptions,
      });
    }, [id]);

    return (
      <Editor
        value={value}
        type="selection"
        options={options}
        onChange={onChange}
        {...rest}
      />
    );
  };

LocationSelection.Nation = (props: LocationSelectionProps) => (
  <Editor
    type="selection"
    optionsUrl={withPageSize('/administration/nations')}
    {...props}
  />
);

LocationSelection.Region = withLocationSelection({
  prepareUrl: (nationId: number): string =>
    withPageSize(`administration/nations/${nationId}/regions`),
});
LocationSelection.Province = withLocationSelection({
  prepareUrl: (regionId: number): string =>
    withPageSize(`administration/regions/${regionId}/provinces`),
});
LocationSelection.Municipality = withLocationSelection({
  prepareUrl: (provinceId: number): string =>
    withPageSize(`administration/provinces/${provinceId}/municipalities`),
});

export default LocationSelection;
