import React from 'react';
import { ConfigProvider } from 'antd';
import { DefaultTheme, ThemeProvider } from 'styled-components';
import GlobalStyles from '../../../assets/styles/globalstyles';
import { colorType, extractColorFromTheme } from '../../../helpers';
import defaultTheme from './themes/default.json';
import { useSettings } from '../../hooks/settings';
import { globalSettings } from '../../../constants/settings';
import { TenantModel } from '../../../redux-store/reducers/settings/settings.types';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/it';
import it from 'antd/locale/it_IT';
import enUS from 'antd/locale/en_US';
import { useTranslator } from '../../hooks/translation';

export interface CustomTheme extends DefaultTheme {
  extractColor: (colorType: string, number?: number) => string;
}

interface ColorTheme {
  link: string;
  light: string;
  gradientBlue: string;
  blue: string;
  darkGray: string;
  lightGray: string;
  'primary-level-0': string;
  'primary-level-1': string;
  'primary-level-2': string;
  'primary-level-3': string;
  'secondary-level-0': string;
  'secondary-level-1': string;
  'secondary-level-2': string;
  'secondary-level-3': string;
  'success-level-0': string;
  'success-level-1': string;
  'success-level-2': string;
  'success-level-3': string;
  'warning-level-0': string;
  'warning-level-1': string;
  'warning-level-2': string;
  'warning-level-3': string;
  'danger-level-0': string;
  'danger-level-1': string;
  'danger-level-2': string;
  'danger-level-3': string;
  'background-level-0': string;
  'background-level-1': string;
  'background-level-2': string;
  'background-level-3': string;
  controlOutlineWidth: number;
  fontWeightStrong: number;
  fontFamily: string;
  borderRadius: number;
  minLogoUrl: string;
  maxLogoUrl: string;
  blueShadesPalette: string[];
  bluePalette: string[];
  backgroundColor: string[];
}

const extractThemeFromTenant = (id: string) => {
  const tenant = globalSettings.tenants.find((t: TenantModel) => t.id == id);
  return tenant?.theme || defaultTheme;
};

const ThemesProvider = (props: any) => {
  const settings = useSettings();
  const translator = useTranslator();
  const [theme, setTheme] = React.useState<ColorTheme>(() => {
    return extractThemeFromTenant(settings.id);
  });
  React.useEffect(() => {
    if (!settings.id) return;
    setTheme(extractThemeFromTenant(settings.id));
  }, [settings.id]);

  const extractColor = (color: any, level = 3) => {
    return extractColorFromTheme(theme, color, level);
  };

  const themeProvider = React.useMemo(() => {
    const primaryColor = extractColor(colorType.primary);
    const secondaryColor = extractColor(colorType.secondary);
    const successColor = extractColor(colorType.success);
    const colorWarning = extractColor(colorType.warning);
    const colorError = extractColor(colorType.danger);
    return {
      token: {
        colorError: colorError,
        colorLink: primaryColor,
        colorIcon: primaryColor,
        colorText: primaryColor,
        colorSuccess: successColor,
        colorWarning: colorWarning,
        colorPrimary: primaryColor,
        colorTextBase: primaryColor,
        colorTextSecondary: secondaryColor,
        borderRadius: theme.borderRadius,
        fontWeightStrong: theme.fontWeightStrong,
        controlOutlineWidth: theme.controlOutlineWidth,
        colorPrimaryBg: extractColor(colorError.background),
        colorLinkHover: extractColor(colorType.primary, 2),
        fontFamily: theme.fontFamily,
      },
    };
  }, [settings.id, theme, extractColor]);

  const getAntdLocale = React.useMemo(() => {
    dayjs.extend(localizedFormat);
    if (translator.language === 'it-IT') {
      dayjs.locale('it');
      return it;
    }
    // Fallback or default language locale (e.g., English)
    dayjs.locale('en');
    return enUS;
  }, [translator.language]);

  return (
    <ConfigProvider theme={themeProvider} locale={getAntdLocale}>
      <ThemeProvider
        theme={{
          ...theme,
          extractColor: extractColor,
        }}
      >
        <GlobalStyles />
        {props.children}
      </ThemeProvider>
    </ConfigProvider>
  );
};

export { ThemesProvider };
