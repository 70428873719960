import React, {
  createContext,
  ReactElement,
  useContext,
  useState,
} from 'react';

export interface IStep {
  canBeEditable?: (params: {
    completed?: number[];
    isCompleted: boolean;
  }) => boolean;
  component: ReactElement;
  descriptionTranslationId?: string;
  id: number;
  sidebarTitleTranslationId?: string;
  titleTranslationId?: string;
}

export interface ICurrentStep {
  canBeEditable: (params: {
    completed?: number[];
    isCompleted: boolean;
  }) => boolean;
  canSkip?: boolean;
  component?: ReactElement;
  prepareDescription?: (description?: string) => string;
  id?: number;
}

export interface ISteps extends IStep {
  canSkip?: boolean;
  defaultEditable?: boolean;
  canBeEditable: (params: {
    completed?: number[];
    isCompleted: boolean;
  }) => boolean;
}

interface IStepperContext {
  completed?: number[];
  setCompleted?: (completed?: number[]) => void;
  currentStep?: ICurrentStep;
  count?: number;
  steps: ISteps[];
  availableSteps?: number[];
  setAvailableSteps?: (steps: number[]) => void;
  navigateToStep?: (id: number) => void;
  canNavigateSteps?: boolean;
  activeStep?: number;
  onBack?: () => void;
  setActiveStep?: (activeStep: number) => void;
  onSkip?: () => void;
  canEdit?: boolean;
  canGoBack?: boolean;
  globalConfigurations?: {
    borrowerId?: number;
    fetchLoan?: () => void;
    session?: {
      id?: string;
    };
    loan?: {
      id?: number;
      paymentAccountId?: number;
      registerDataId?: number;
    };
  };
  isCompleted: boolean;
  onNext?: () => void;
  finished?: boolean;
  completeStep?: () => void;
}

interface Props {
  steps: ISteps[];
  onStepChange?: () => void;
  filterAvailableSteps?: (
    available?: number[],
    completed?: number[]
  ) => number[];
  children?: React.ReactNode;

  [x: string]: any;
}

const Context = createContext<IStepperContext>({} as IStepperContext);

const StepperProcess = ({
  steps,
  onStepChange,
  filterAvailableSteps,
  canGoBack = true,
  ...props
}: Props) => {
  const { initialCompleted = [], initialActiveStep = null } = props;
  const [activeStep, setActiveStep] = useState(initialActiveStep);
  const [completed, setCompleted] = useState(initialCompleted);

  React.useEffect(() => {
    if (onStepChange) onStepChange();
  }, [activeStep]);

  React.useEffect(() => {
    setActiveStep(initialActiveStep);
  }, [initialActiveStep]);

  React.useEffect(() => {
    setCompleted(initialCompleted);
  }, [initialCompleted]);

  const stepIndex = steps.findIndex((v: IStep) => v.id === activeStep);

  const completeStep = () => {
    setCompleted((previous: number[]) => {
      if (previous.includes(activeStep)) return previous;
      return [...previous, activeStep];
    });
    onNext();
  };

  const onNext = () => {
    if (stepIndex + 1 < steps.length) {
      const nextStep = steps[stepIndex + 1];
      setActiveStep(nextStep.id);
    }
  };

  const onBack = () => {
    if (stepIndex > 0) {
      const step = steps[stepIndex - 1];
      setActiveStep(step.id);
    }
  };

  let finished = true;

  const requiredSteps = steps.filter((step: ISteps) => !step.canSkip);

  for (const requiredStep of requiredSteps) {
    if (!completed.includes(requiredStep.id)) {
      finished = false;
      break;
    }
  }

  const currentStep = steps.find((s: ISteps) => s.id === activeStep);

  if (!currentStep) return null;

  const isCompleted = completed.includes(activeStep);

  let availableSteps: number[] = [];

  let foundFollowingStep = false;

  const insertAvailableStep = (id: number) => {
    if (!availableSteps.includes(id)) availableSteps.push(id);
  };

  for (const step of steps) {
    if (step.id && completed.includes(step.id)) {
      insertAvailableStep(step.id);
    } else {
      if (step.id && !foundFollowingStep) {
        insertAvailableStep(step.id);
        foundFollowingStep = true;
      }
    }
  }

  let canEdit = false;

  if (!completed.includes(currentStep.id)) canEdit = true;

  if (filterAvailableSteps)
    availableSteps = filterAvailableSteps(availableSteps, completed);

  if (currentStep.defaultEditable) canEdit = true;

  return (
    <Context.Provider
      value={{
        ...props,
        completed,
        steps: steps,
        completeStep,
        setCompleted,
        onNext: onNext,
        onBack: onBack,
        onSkip: onNext,
        canEdit: canEdit,
        finished: finished,
        count: stepIndex + 1,
        activeStep: activeStep,
        currentStep: currentStep,
        isCompleted: isCompleted,
        setActiveStep: setActiveStep,
        availableSteps: availableSteps,
        canGoBack: canGoBack && stepIndex !== 0,
        globalConfigurations: props?.globalConfigurations || {},
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

const useStepper = (): IStepperContext => {
  return useContext(Context);
};

export { StepperProcess, useStepper };
