import { anyObject } from './url';

export const uid = () => {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

export function hasValue(item: any) {
  return item !== null && item !== undefined;
}

export function isEmpty(object: anyObject) {
  if (!hasValue(object)) return true;
  if (Array.isArray(object)) return object.length === 0;
  else return Object.keys(object).length === 0;
}

export function groupBy(data = [], key: any) {
  return data.reduce((acumulator: any, next) => {
    (acumulator[next[key]] = acumulator[next[key]] || []).push(next);
    return acumulator;
  }, {});
}

const registryDictionary = [
  'registerId',
  'registryId',
  'registryDataId',
  'registerDataId',
];

export const getRegistryId = (object: any) => {
  if (!object) return null;
  for (const key of registryDictionary)
    if (Object.prototype.hasOwnProperty.call(object, key)) return object[key];
  return null;
};

 
export const removeNullProperties = (obj: { [key: string]: any }) => {
  return Object.fromEntries(
     
    Object.entries(obj).filter(([_, v]) => v !== null)
  );
};
